import { FC } from 'react';
import { Warning16Regular } from '@fluentui/react-icons';
import { Textarea } from "@fluentui/react-components";
import { Alert } from '@fluentui/react-components/unstable';
import PropTypes from 'prop-types';
import './TextAreaWithCounter.scss';

export const TextAreaWithCounter: FC<any> = (props) => {
  const { characterLimit, formData, className, isAlertVisible, alertText, ...textAreaProps } = props;

  const hasMaxLengthError = formData.fieldState.error?.type === 'maxLength';

  return (
    <div className={className ?? ''}>
      <Textarea className="text-area__input" {...textAreaProps} {...formData.field} />
      <div
        className={`text-area__counter ${isAlertVisible && hasMaxLengthError ? 'text-area__error' : ''} ${
          hasMaxLengthError ? 'text-area__counter--error' : ''
        }`}
      >
        {!!isAlertVisible && hasMaxLengthError && (
          <Alert className="text-area__alert" icon={<Warning16Regular />} intent="error">
            {alertText}
          </Alert>
        )}
        <div>
          {formData.field.value.length}/{characterLimit}
        </div>
      </div>
    </div>
  );
};

TextAreaWithCounter.propTypes = {
  characterLimit: PropTypes.number.isRequired,
  formData: PropTypes.object.isRequired,
};
