import { useContext, createContext, useCallback, useEffect, useReducer, useMemo, useState } from 'react';
import { ErrorScreen, TimeoutScreen } from 'shared-fe-components/src/common/StatusScreens';
import {
  State,
  Action,
  ErrorProps,
  VerifyTaskContextType,
  VerificationVideoDetails,
} from 'shared-fe-components/src/types/VerifyTaskContext.types';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import * as api from 'shared-fe-components/src/api';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { PrivacyPolicy } from 'components/PrivacyPolicy';

const verifyTaskInitialState: State = {
  overlay: null,
  selectedProvider: null,
  errorProps: null,
  showPrivacyPolicy: false,
  loading: true,
};

const verifyTaskReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'PROVIDER_SELECTION':
      return {
        ...verifyTaskInitialState,
        loading: false,
      };
    case 'PROVIDER':
      return {
        ...state,
        selectedProvider: action.selectedProvider,
      };
    case 'ERROR':
      return {
        ...state,
        overlay: 'error',
        errorProps: action.errorProps,
      };
    case 'TIMEOUT':
      return {
        ...state,
        overlay: 'timeout',
      };
    case 'HIDE_OVERLAY':
      return {
        ...state,
        overlay: null,
        errorProps: null,
      };
    case 'LOADING_FINISHED':
      return {
        ...state,
        loading: false,
      };
    case 'SHOW_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: true,
      };
    case 'HIDE_PRIVACY_POLICY':
      return {
        ...state,
        showPrivacyPolicy: false,
      };
  }
};

const VerifyTaskContext = createContext<VerifyTaskContextType | null>(null);

export const VerifyTaskContextProvider = ({
  sessionId,
  verificationId,
  goToSuccess,
  goToRejected,
  goToIntro,
  onBack,
  onCancel,
  children,
}: any) => {
  const [verifyTaskState, verifyTaskDispatch] = useReducer(verifyTaskReducer, verifyTaskInitialState);
  const [isInitialStatusCheck, setIsInitialStatusCheck] = useState(true);
  const [verificationVideoDetails, setVerificationVideoDetails] = useState<VerificationVideoDetails>({
    skipRecording: false,
    videoParams: {},
  });

  const [privacyPolicyAcceptance, setPrivacyPolicyAcceptance] = usePersistentStorage('privacy-policy-acceptance');
  const isPrivacyPolicyVisible = useMemo(() => {
    if (!privacyPolicyAcceptance && verifyTaskState.selectedProvider !== null) {
      // user hasn't accepted privacy policy and has to do it to proceed
      return true;
    }
    if (verifyTaskState.showPrivacyPolicy) {
      // user wants to read it for some reason
      return true;
    }

    return false;
  }, [privacyPolicyAcceptance, verifyTaskState.selectedProvider, verifyTaskState.showPrivacyPolicy]);

  const acceptPrivacyPolicy = useCallback(() => {
    setPrivacyPolicyAcceptance(true);
    verifyTaskDispatch({ type: 'HIDE_PRIVACY_POLICY' });
  }, [setPrivacyPolicyAcceptance]);

  const closePrivacyPolicy = useCallback(() => {
    if (!privacyPolicyAcceptance) {
      onCancel();
    } else {
      verifyTaskDispatch({ type: 'HIDE_PRIVACY_POLICY' });
    }
  }, [privacyPolicyAcceptance, onCancel]);

  const showPrivacyPolicy = () => verifyTaskDispatch({ type: 'SHOW_PRIVACY_POLICY' });

  const overlay = useMemo(() => verifyTaskState.overlay, [verifyTaskState.overlay]);
  const selectedProvider = useMemo(() => verifyTaskState.selectedProvider, [verifyTaskState.selectedProvider]);
  const setSelectedProvider = (selectedProvider: string) => verifyTaskDispatch({ type: 'PROVIDER', selectedProvider });
  const goToProviderSelection = () => verifyTaskDispatch({ type: 'PROVIDER_SELECTION' });
  const onError = (errorProps: ErrorProps) =>
    verifyTaskDispatch({ type: 'ERROR', errorProps: { ...errorProps, processType: 'Verify' } });
  const onTimeout = () => verifyTaskDispatch({ type: 'TIMEOUT' });
  const hideOverlay = () => verifyTaskDispatch({ type: 'HIDE_OVERLAY' });

  // FIXME: Copied from SigningContext, should be changed during Signing and Verify Context refactor
  const onErrorRedirect = () => {
    goToIntro();
    goToProviderSelection();
  };

  const verify = useCallback(
    async ({ additionalParameters = {} }) => {
      try {
        const response = await api.verification.verify({
          sessionId,
          verificationId,
          verificationProviderType: selectedProvider?.split(':')[0],
          additionalParameters: {
            ...additionalParameters,
            ...verificationVideoDetails.videoParams,
          },
        });
        return response;
      } catch (error) {
        // @ts-ignore
        const errorResponse = await error.response.json();
        const respError = errorResponse.error;
        if (respError) {
          onError({ errorCode: respError });
        } else {
          onError({});
        }
      }
    },
    [selectedProvider, sessionId, verificationId]
  );

  const getVerificationStatus = async ({ onProviderPending }: any) => {
    let data, error;
    try {
      data = await api.verification.getVerificationStatus(sessionId, verificationId);
      if (data.status === 'Rejected') goToRejected();
    } catch (err) {
      error = err;
    }

    const desc = api.verification.describeVerificationFromGetVerificationStatus({ data, error });

    if (!(isInitialStatusCheck && (desc.cancelled || !desc.success))) {
      if (desc.success) {
        goToSuccess();
      } else if (desc.providerPending) {
        onProviderPending?.();
      } else if (desc.timeout) {
        onTimeout();
      } else if (desc.errorCode !== null) {
        onError({ errorCode: desc.errorCode });
      } else if (desc.failed) {
        onError({});
      }
    }

    setIsInitialStatusCheck(false);

    return { data, error };
  };

  useEffect(() => {
    (async () => {
      await getVerificationStatus({
        onProviderPending: () => verifyTaskDispatch({ type: 'PROVIDER', selectedProvider: '_dummy:ProviderPending' }),
      });
      verifyTaskDispatch({ type: 'LOADING_FINISHED' });
    })();
  }, [sessionId, verificationId]);

  const providerValues = {
    sessionId,
    verificationId,
    verificationVideoDetails,
    setVerificationVideoDetails,
    goToSuccess,
    selectedProvider,
    overlay,
    onBack,
    onCancel,
    setSelectedProvider,
    goToProviderSelection,
    verify,
    getVerificationStatus,
    onError,
    onTimeout,
    showPrivacyPolicy,
  };

  return (
    <VerifyTaskContext.Provider value={providerValues}>
      <div className="layered-container">
        {children}
        {(verifyTaskState.overlay !== null || verifyTaskState.loading) && <div className="background-task"></div>}
        {verifyTaskState.overlay === 'error' && (
          <ErrorScreen
            {...verifyTaskState.errorProps}
            onCancel={onErrorRedirect}
            onRetry={hideOverlay}
            processType="Verify"
          />
        )}
        {verifyTaskState.overlay === 'timeout' && <TimeoutScreen onCancel={onCancel} onRetry={hideOverlay} />}
        {verifyTaskState.loading && <LoadingScreen />}
        {isPrivacyPolicyVisible && (
          <>
            <div className="background-task"></div>
            <PrivacyPolicy
              onAccept={acceptPrivacyPolicy}
              onClose={closePrivacyPolicy}
              required={!privacyPolicyAcceptance}
            />
          </>
        )}
      </div>
    </VerifyTaskContext.Provider>
  );
};

export const useVerifyTaskContext = () => {
  const ctx = useContext(VerifyTaskContext);
  if (!ctx) {
    throw new Error('Lack of VerifyTaskContextProvider in components tree');
  }
  return ctx;
};
