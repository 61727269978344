import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import idCardLayer from './static/idCardLayer.png';
import overlay from './static/overlay.svg';
import { EdoIntroProps } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './EdoDocInfo.scss';

export function EdoDocInfo({ onBack }: Pick<EdoIntroProps, 'onBack'>) {
  return (
    <WizardBox>
      <div>
        <Text as="h1" weight="bold">{t({ id: 'Providers.Edo.Info.Header' })}</Text>
      </div>
      <div>
        <Text>{t({ id: 'Providers.Edo.Info.CheckIf' })}</Text>

        <div className="edo-doc-info">
          <div className="edo-doc-info__image">
            <img src={idCardLayer} alt={t({ id: 'Providers.Edo.Info.IdCardAlt' })} />
          </div>
          <div className="edo-doc-info__image">
            <img src={overlay} alt={t({ id: 'Providers.Edo.Info.IdCardAlt' })} />
          </div>
          <div className="edo-doc-info__biometric">
            <Text>{t({ id: 'Providers.Edo.Info.BiometricDataSign' })}</Text>
          </div>
          <div className="edo-doc-info__can-number">
            <Text>{t({ id: 'Providers.Edo.Info.CanNumber' })}</Text>
          </div>
        </div>

        <Text>{t({ id: 'Providers.Edo.Info.PinInfo' })}</Text>
      </div>
      <div>
        <ButtonBack onClick={onBack} />
      </div>
    </WizardBox>
  );
}
