import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Text, Input, Link, Label } from '@fluentui/react-components';
import { getPhonePrefixes } from 'shared-fe-components/src/api/notifications';
import { PhoneNumberWithCode } from '../../common/PhoneNumberWithCode';
import { InputFormWithError } from 'shared-fe-components/src/common/WithError';
import { t } from '@lingui/macro';
import { WizardBox } from '../../common/WizardBox';
import { ButtonBack } from '../../common/Buttons';

export const PhoneVerificationForm = ({ onNext, onBack, onCancel, signatureType, knownPhoneNumber }) => {
  const [phoneCodes, setPhoneCodes] = useState([]);

  const moduleName = 'phone-number-verification';
  const requiresPhoneNumberInput = signatureType === 'Advanced';

  const phoneNumberPrefix = knownPhoneNumber ? `${knownPhoneNumber.countryCode}-${knownPhoneNumber.prefix}` : null;
  const phoneNumber = knownPhoneNumber?.number ?? '';

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      'full-name': '',
      [moduleName]: [
        {
          'mobilephoneCode': phoneNumberPrefix,
          'mobilephoneNumber': phoneNumber,
        }
      ],
    },
  });

  const {
    handleSubmit,
    trigger,
    getValues,
    formState: { errors }
  } = formMethods;

  const onSubmit = async () => {
    const fieldsToValidate = ['full-name'];
    if (requiresPhoneNumberInput) {
      fieldsToValidate.push(`${moduleName}.0.mobilephoneNumber`);
    }

    const isValid = await trigger(fieldsToValidate, { shouldFocus: true });
    if (!isValid) {
      return;
    }

    const signerData = {
      fullName: getValues('full-name'),
      phoneNumber: null,
    };

    if (requiresPhoneNumberInput) {
      const phoneNumberWithoutPrefix = getValues(moduleName)[0].mobilephoneNumber;
      const phoneNumberPrefix = getValues(moduleName)[0].mobilephoneCode.split('-')[1];

      const phoneNumber = `${phoneNumberPrefix}${phoneNumberWithoutPrefix}`;
      signerData.phoneNumber = phoneNumber;
    }

    onNext(signerData);
  };

  // fetch acceptable phone codes for phonenumber dropdown
  useEffect(() => {
    (async () => {
      const countryCodesPrefixes = await getPhonePrefixes();
      const countryIsoCodes = countryCodesPrefixes.map(({ countryCode }) => countryCode);
      setPhoneCodes(countryIsoCodes);
    })();
  }, []);

  const fullNameInputProps = {
    control: formMethods.control,
    fieldName: 'full-name',
    disabled: false,
    placeholder: t({ id: 'Form.Inputs.FullName.Placeholder' }),
    errorMessage: errors?.['full-name']?.message,
    appearance: 'filled-darker',
    rules: {
      required: { value: true, message: t({ id: 'Common.Validation.Required' }) },
    }
  };

  const phoneNumberInputProps = {
    moduleName: moduleName,
    index: 0,
    isDisabled: knownPhoneNumber != null,
    additionalRules: {
      required: { value: true, message: t({ id: 'Common.Validation.Required' }) },
    }
  };

  return (
    <FormProvider {...formMethods}>
      <WizardBox className="native-sign">
        <div>
          <Text as="h1" weight="bold">
            {t({ id: 'Providers.Native.SignerData.Header' })}
          </Text>
        </div>
        <div className="native-sign-form">
          <div className="native-sign-form__container">
            <Text className="native-sign-form__label">
              {t({ id: 'Form.Inputs.FullName' })}
            </Text>
            <div className="native-sign-form__input">
              <InputFormWithError flex {...fullNameInputProps} />
            </div>
          </div>
          {requiresPhoneNumberInput && (
            <div className="native-sign-form__container">
              <Text className="native-sign-form__label">
                {t({ id: 'Form.Inputs.PhoneNumber' })}
              </Text>
              <div className="native-sign-form__input">
                <div className="native-sign-form__phone-number">
                  <PhoneNumberWithCode phoneCodes={phoneCodes} {...phoneNumberInputProps} />
                </div>
              </div>
              {phoneNumber && <Text>{t({ id: 'Providers.Native.SignerData.PhoneNumberProvidedBySender' })}</Text>}
            </div>
          )}
        </div>
        <div>
          <div>{onBack && <ButtonBack onClick={onBack} />}</div>
          <div>
            {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
            <Button appearance="primary" onClick={onSubmit}>{t({ id: 'Common.Proceed' })}</Button>
          </div>
        </div>
      </WizardBox>
    </FormProvider>
  );
};
