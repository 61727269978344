import * as client from '../client';

export const getCertificateStatus = async (sessionId: any, certificateId: any) => {
    return await client.get(`certificates/sessions/${sessionId}/certificates/${certificateId}`, {});
  };

export const getCertificateSession = async (sessionId: any) => {
    return await client.get(`certificates/sessions/${sessionId}`, {});
}

export const generateCertificate = async (sessionId: any, certificateId: any) => {
  let data = 
  {
    sessionId: sessionId,
    certificateId: certificateId
  }

  return await client.post("certificates/generate-certificate", data);
}