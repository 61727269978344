import { useState } from 'react';
import { PhoneVerificationForm } from './PhoneVerificationForm';
import CodeVerificationForm from './CodeVerificationForm';
import './SignerDataForm.scss'

export const SignerDataForm = ({ onBack, onCancel, onSignerDataConfirmed, knownPhoneNumber, signatureType }) => {
  const [step, setStep] = useState(1);
  const [signerData, setSignerData] = useState(null);

  const phoneNumberVerificationRequired = signatureType === 'Advanced';

  const onSignerDataProvided = (signerData) => {
    setSignerData(signerData);

    if (phoneNumberVerificationRequired) {
      setStep(2);
    } else {
      onSignerDataConfirmed(signerData);
    }
  };

  const onPhoneNumberValidated = (phoneNumberToken: string) => {
    const updatedSignerData = { ...signerData, phoneNumberToken };
    setSignerData(updatedSignerData);

    onSignerDataConfirmed(updatedSignerData);
  };

  return (
    <div>
      {step === 1 && (
        <PhoneVerificationForm signatureType={signatureType} knownPhoneNumber={knownPhoneNumber}
          onNext={onSignerDataProvided} onBack={onBack} onCancel={onCancel} />
      )}
      {step === 2 && (
        <CodeVerificationForm phoneNumber={signerData?.phoneNumber}
          onBack={() => setStep(1)} onCancel={onCancel} onPhoneNumberValidated={onPhoneNumberValidated}
        />
      )}
    </div>
  );
};
