import { ActionableSection } from 'shared-fe-components/src/common/ActionableSection';
import { t } from '@lingui/macro';
import { isAcceptableFileSize } from './models';
import {
  EdoAppFingerprintIcon,
  IdNowIcon,
  MszafirIcon,
  SimplySignMobileIcon,
  InperlyBlueOrWhiteIcon,
} from 'shared-fe-components/src/common/Icons';
import { Text, Spinner } from '@fluentui/react-components';
import { useProviderSelectionContext } from '.';
import { EidEasyService } from './EidEasyService';

const EdoApp = () => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.providerLimits.edo)
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  return (
    <ActionableSection
      icon={<EdoAppFingerprintIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('Edo')}
      error={error}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.Edo.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.Edo.Description' })}
      </Text>
    </ActionableSection>
  );
};

const Mszafir = () => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.providerLimits.mszafir)
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  return (
    <ActionableSection
      icon={<MszafirIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('Mszafir')}
      error={error}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.Mszafir.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.Mszafir.Description' })}
      </Text>
    </ActionableSection>
  );
};

const IdNow = () => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.providerLimits.idNow)
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  return (
    <ActionableSection
      icon={<IdNowIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('IdNow')}
      error={error}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.IdNow.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.IdNow.Description' })}
      </Text>
    </ActionableSection>
  );
};

const SimplySign = () => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.providerLimits.simplySign)
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  return (
    <ActionableSection
      icon={<SimplySignMobileIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('SimplySign')}
      error={error}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.SimplySign.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.SimplySign.Description' })}
      </Text>
    </ActionableSection>
  );
};

const Native = () => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.providerLimits.native)
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  return (
    <ActionableSection
      icon={<InperlyBlueOrWhiteIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('Native')}
      error={error}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.Native.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.Native.Description' })}
      </Text>
    </ActionableSection>
  );
};

interface EidEasyProviderProps {
  provider: string;
}

export const EidEasyProvider = ({ provider }: EidEasyProviderProps) => {
  const { file, onSelection, signing } = useProviderSelectionContext();
  const error = !isAcceptableFileSize(file, signing.maxFileSize) // FIXME maybe we'll know it someday
    ? t({ id: 'ProviderSelection.Signing.Error.FileSize' })
    : null;

  const { name, label, image } = EidEasyService(provider);

  return (
    <ActionableSection
      icon={image ?? <Spinner />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection(provider)}
      error={error}
    >
      <Text block weight="semibold">
        {name}
      </Text>
      <Text block weight="regular">
        {label}
      </Text>
    </ActionableSection>
  );
};

export const Providers: { [key: string]: () => JSX.Element } = {
  IdNow: IdNow,
  SimplySign: SimplySign,
  Edo: EdoApp,
  Mszafir: Mszafir,
  Native: Native,
};
