import * as client from '../client';
import {
  PhoneNumberVerificationRequest, PhoneNumberVerificationRequestResponse,
  VerifyPhoneNumberRequest, VerifyPhoneNumberRequestResponse
} from './models';

export const requestPhoneNumberVerification = async ({
  phoneNumber,
  prefix
}: PhoneNumberVerificationRequest): Promise<PhoneNumberVerificationRequestResponse> =>
  await client.post(`user-data-verification/phone-number`, {
    phoneNumber,
    prefix
  });

export const requestPhoneNumberVerificationOtp = async (verificationId: string) => {
  const response = await client.postRaw(`user-data-verification/phone-number/${verificationId}/request-otp`);
};

export const verifyPhoneNumber = async ({ verificationId, verificationCode }: VerifyPhoneNumberRequest): Promise<VerifyPhoneNumberRequestResponse> =>
  await client.post(`user-data-verification/phone-number/${verificationId}/verify`, undefined, {
    additionalHeaders: { 'Inperly-Protection-Password': verificationCode },
  });
