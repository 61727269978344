import { useMemo } from 'react';
import { useThemeContext } from 'contexts';

interface Colors {
  [key: string]: string;
}

interface Props {
  themeColors: Colors;
  customColors: Colors;
}

const formatAsCSS = (themeColors: Colors, customColors: Colors) => {
  const themeColorsVariables = Object.entries(themeColors)
    .map(([name, color]) =>`--colorScheme-${name}: ${color};`);

  const customColorsVariables = Object.entries(customColors)
    .map(([name, color]) => `--customColors-${name}: ${color};`);

  return `:root {\n${[...themeColorsVariables, ...customColorsVariables].join('\n')}\n}`;
};
export const ColorSchemeVariables = ({ themeColors, customColors }: Props) => {
  const rules = useMemo(() => formatAsCSS(themeColors, customColors), [themeColors, customColors]);

  return <style>{rules}</style>;
};
