import * as client from "./client";

export const getFeatures = async () =>
  await client.get("configuration/web-app/features", {
    retry: {
      limit: 10,
    },
  });

export const getConfiguration = async () =>
  await client.get("configuration/web-app", {
    retry: {
      limit: 10,
    },
  });
