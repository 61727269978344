import { createContext, useContext, useState, Dispatch, SetStateAction, FC, PropsWithChildren } from 'react';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';

const normalizeLang = (lang: string) => {
  return new Intl.Locale(lang).language;
};

type SetLanguage = (language: string, shouldOmitUrlLangAfterReload?: boolean) => void;

interface LanguageContextType {
  language: string;
  setLanguage: SetLanguage;
  canChangeLanguage: boolean;
  setCanChangeLanguage: Dispatch<SetStateAction<boolean>>;
}
export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

export const OMIT_URL_LANG_STORAGE_KEY = 'omit-url-lang';

const defaultValue = normalizeLang(window.navigator.language);

export const LanguageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [value, setAndStoreValue] = usePersistentStorage('language');
  // Rerendering after changing language conflicts with using language from url, so we keep this in local storage to know when to ignore using language from the url
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setShouldOmitUrlLang] = usePersistentStorage(OMIT_URL_LANG_STORAGE_KEY);
  const [language, setLanguage] = useState(value ?? defaultValue);
  const [canChangeLanguage, setCanChangeLanguage] = useState(true);

  const selectLanguage: SetLanguage = (language: string, shouldOmitUrlLangAfterReload = false) => {
    setLanguage(language);
    setAndStoreValue(language);
    setShouldOmitUrlLang(shouldOmitUrlLangAfterReload);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: selectLanguage, canChangeLanguage, setCanChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
