export interface RequestSignatureBody {
  recipients: {
    type: string;
    email: string;
    culture: string;
    phoneNumber?: string;
    protectionType?: string;
  }[];
  carbonCopyRecipients?: {
    email: string;
    culture: string;
  }[];
  message?: string;
  expirationTime: Date | string;
  sources?: {
    teamsFileSource: {
      driveId: string;
      fileId: string;
      folderId: string;
    };
  }[];
  signatureType?: string;
  meetingId?: string;
  name?: string;
  culture?: string;
  reminderFrequency?: number;
}

export interface SignParameters {
  sessionId: string;
  signatureId: string;
  documentIds: string[];
  signatureProviderType: string | null;
  additionalParameters: { code?: string; additionalHeaders?: { [key: string]: unknown } };
}

export type UpdateSigningSessionBody = Omit<
  RequestSignatureBody,
  'sources' | 'signatureType' | 'meetingId' | 'culture'
>;

export enum XadesFormat {
  Enveloped = "Enveloped",
  Enveloping = "Enveloping",
  Detached = "Detached"
}
