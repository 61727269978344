import { useLocalization } from '../../contexts/LocalizationContext';
import countries from 'i18n-iso-countries';
import { useEffect, useState } from 'react';
import { countryCallingCodes } from './countryCallingCodes';
import { Dropdown } from './Dropdown';
import { CountryFlag } from '../CountryFlags/CountryFlag';
import { CountryCallingCodeDropdownProps } from './models';
import './CountryCallingCodeDropdown.scss';

export const CountryCallingCodeDropdown = (props: CountryCallingCodeDropdownProps) => {
  const { whiteList, isDisabled, ...rest } = props;
  const { locale = 'en-en'  } = useLocalization() ?? 'en-en';
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const createOption = ({ iso2Code, name, flagElement, callingCode }: any) => ({
    value: `${iso2Code}-${callingCode}`,
    labelForDisplaying: (
      <div>
        <span className="hide-in-control">
          {flagElement} {name}
          {' ('}
        </span>
        <span>+{callingCode}</span>
        <span className="hide-in-control">{')'}</span>
      </div>
    ),
    label: `${name} (+${callingCode})`,
  });

  useEffect(() => {
    const generalLocale = locale.split('-')[0];
    countries.registerLocale(require(`i18n-iso-countries/langs/${generalLocale}.json`));
    const countryNames = countries.getNames(generalLocale, { select: 'official' });
    const countryCodesList = Object.keys(countryNames);
    const filteredCountryCodesList = whiteList
      ? countryCodesList.filter((iso2Code) => whiteList.includes(iso2Code))
      : countryCodesList;
    const newCountryData = filteredCountryCodesList
      .map((iso2Code) => {
        const countryWithoutCallingCode = {
          iso2Code,
          name: countryNames?.[iso2Code],
          flagElement: <CountryFlag iso2Code={iso2Code} />,
        };
        return (
          countryCallingCodes?.[iso2Code]?.map((callingCode: any) => ({
            ...countryWithoutCallingCode,
            callingCode,
          })) ?? [{ ...countryWithoutCallingCode, callingCode: null }]
        );
      })
      .flat()
      .sort((a, b) => a.callingCode - b.callingCode)
      .sort((a, b) => a.name.localeCompare(b.name));
    setOptions(newCountryData.map(createOption));
    setIsLoading(false);
  }, [locale, whiteList]);

  const dropdownProps = {
    isLoading,
    options,
    filterOption: (option: any, inputValue: any) => option.data.label.toLowerCase().includes(inputValue.toLowerCase()),
    getOptionLabel: (option: any) => option.labelForDisplaying,
    isDisabled: !!isDisabled,
    ...rest,
  };

  return <Dropdown {...dropdownProps} />;
};
