import { Text } from '@fluentui/react-components';
import { Button } from '../../common/FluentMigration';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { WizardBox } from '../../common/WizardBox';
import { LinkToPrivacyPolicy } from '../../common/PrivacyPolicy';
import { Anchor } from '../../common/Anchor';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './EidEasy.scss';

interface Props {
  onBack: () => void;
  onCancel: () => void;
  onContinue: () => void;
  onShowPrivacyPolicy: () => void;
}

export const EidEasyIntro = ({ onBack, onCancel, onContinue, onShowPrivacyPolicy }: Props) => {
  const linkComponents = [
    <Anchor onClick={() => window.open('https://eideasy.com/tcbillennium/')} />,
    <Anchor onClick={() => window.open('https://eideasy.com/privacy-policy-for-billennium-customers/')} />,
  ];

  return (
    <WizardBox growContent={false}>
      <div></div>
      <div className="eideasy-intro__contents">
        <div>
          <Text size="large" weight="bold" block>
            {t({ id: 'Providers.EidEasy.Intro.Title' })}
          </Text>
          <Text block>
            <Trans id="Providers.EidEasy.Intro.Text" components={linkComponents} />
          </Text>
        </div>
      </div>
      <div>
        <LinkToPrivacyPolicy onShowPrivacyPolicy={onShowPrivacyPolicy} />
      </div>
      <div>
        <div>
          <ButtonBack onClick={onBack} />
        </div>
        <div>
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
          <Button primary onClick={onContinue}>
            {t({ id: 'Common.Proceed' })}
          </Button>
        </div>
      </div>
    </WizardBox>
  );
};
