import { useState } from 'react';
import { Button, Checkbox, Text } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { t } from '@lingui/macro';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { Warning24Regular } from '@fluentui/react-icons';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import './Intro.scss';
import { Trans } from '@lingui/react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';

interface Props {
  onBack: () => void;
  onContinue: () => void;
  videoDuration: number;
  administrator: string;
}

export const Intro = ({ onBack, onContinue, videoDuration, administrator }: Props) => {
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);

  const handleStartRecordingButtonClick = () => {
    if (checkboxValue) {
      onContinue();
    } else {
      setErrorVisible(true);
    }
  };

  const checkboxLabel = (
    <Trans
      id="RecordVideo.Intro.PrivacyPolicy"
      values={{ administrator }}
      components={[<Anchor href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" />]}
    />
  );

  return (
    <WizardBox>
      <div></div>
      <div>
        <div className="record-video-intro">
          <Text as="h1" block align="center">
            {t({ id: 'RecordVideo.Intro.Header', values: { videoDuration } })}
          </Text>
          <div className="record-video-intro__button-wrapper">
            <Button appearance="primary" onClick={handleStartRecordingButtonClick}>
              {t({ id: 'RecordVideo.StartRecordingButton' })}
            </Button>
          </div>
          <Checkbox checked={checkboxValue} onClick={() => setCheckboxValue((v) => !v)} label={checkboxLabel} />
          {errorVisible && (
            <Alert intent="error" icon={<Warning24Regular />}>
              {t({ id: 'RecordVideo.Intro.Alert' })}
            </Alert>
          )}
        </div>
      </div>
      <div>
        <ButtonBack onClick={onBack} />
      </div>
    </WizardBox>
  );
};
