interface Language {
  key: string;
  value: string;
}

export const LANGUAGES: ReadonlyArray<Language> = [
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'pl',
    value: 'Polski',
  },
  {
    key: 'es',
    value: 'Español',
  },
  {
    key: 'de',
    value: 'Deutsch',
  },
  {
    key: 'pt-br',
    value: 'Português (Brasil)',
  }
] as const;

export const DEFAULT_LANGUAGE = {
  key: 'en',
  value: 'English',
};
