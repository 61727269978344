import { XadesFormat } from "../signing/models";
import { z } from "zod";

export const ConfigurationApp = z.object({
  features: z.record(z.boolean()),
  signing: z.object({
    maxFileSize: z.number(),
    providerLimits: z.record(z.number()),
    providerBatchSizes: z.record(z.number()),
    xmlSupport: z.record(
      z.object({
        supportsXml: z.boolean(),
        supportedFormats: z.array(z.nativeEnum(XadesFormat)).nullable()
      })  
    ),
    supportedFileTypes: z.array(z.object({
      mimeType: z.string(),
      extension: z.string(),
      bareExtension: z.string(),
    })).optional(),
    maxSessionLength: z.number(),
  }),
  providers: z.object({
    mszafir: z.object({
      authorizationEndpoint: z.string(),
    }),
    simplySign: z.object({
      authorizationEndpoint: z.string(),
    }),
    eidEasy: z.object({
      clientId: z.string(),
      baseUrl: z.string(),
    }),
    faceTec: z.object({
      sdkKey: z.string(),
    }).optional(),
  }),
  webAppUrl: z.string().optional(),
  appId: z.string().optional(),
});

export type ConfigurationAppType = z.infer<typeof ConfigurationApp>;
