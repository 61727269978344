import { useRef, useCallback } from 'react';

export const useLatestCallable = (value: any) => {
  const ref = useRef<any>();
  ref.current = value;

  const callback = useCallback((...args: any) => ref.current(...args), []);

  return callback;
};
