import { useEffect } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { WizardBox } from '../../common/WizardBox';
import { ActionableSection } from '../../common/ActionableSection';
import { LinkToPrivacyPolicy } from '../../common/PrivacyPolicy';
import { ButtonBack } from '../../common/Buttons';
import { InperlyIcon } from '../../common/Icons';

export const NativeIntro = ({ onBack, onCancel, onShowPrivacyPolicy, onSelectSignatureType, recipientPhoneNumber }) => {
  useEffect(() => {
    if (recipientPhoneNumber) {
      onSelectSignatureType('Advanced');
    }
  }, [recipientPhoneNumber]);

  const selections = [
    ['NativeSimpleSignature', 'Simple', 'Providers.Native.Signature.Simple'],
    ['NativeAdvancedSignature', 'Advanced', 'Providers.Native.Signature.Advanced']
  ]
    //.filter((selection) => features[selection[0]])
    .map(selection => (
      <ActionableSection key={selection[1]} icon={<InperlyIcon />} action={t({ id: 'Common.Proceed' })} onClick={() => onSelectSignatureType(selection[1])}>
        <Text weight="medium" size={400}>{t({ id: selection[2] })}</Text>
        <br />
        <Text weight="regular" size={300}>
          {t({ id: `${selection[2]}.Description` })}
        </Text>
      </ActionableSection>
    ));

  return (
    <WizardBox className='native-intro'>
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Providers.Native.SignatureTypeSelection.Header' })}
        </Text>
      </div>
      <div>
        <div className="simply-sign__tiles">
          {selections}
        </div>
        <div>
          <LinkToPrivacyPolicy onShowPrivacyPolicy={onShowPrivacyPolicy} />
        </div>
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
        </div>
      </div>
    </WizardBox>
  );
};
