var FaceTecStrings = {
  "FaceTec_action_ok": "ЖАРАЙДЫ МА",
  "FaceTec_action_im_ready": "МЕН ДАЙЫНМЫН",
  "FaceTec_action_try_again": "ҚАЙТАДАН БАЙҚАП КӨРІҢІЗ",
  "FaceTec_action_continue": "ЖАЛҒАСТЫРУ",
  "FaceTec_action_take_photo": "СУРЕТКЕ ТҮСІРУ",
  "FaceTec_action_accept_photo": "ҚАБЫЛДАУ",
  "FaceTec_action_retake_photo": "ҚАЙТА АЛУ",
  "FaceTec_action_confirm": "АҚПАРАТТЫ РАСТАУ",

  "FaceTec_accessibility_cancel_button": "Болдырмау",
  "FaceTec_accessibility_tap_guidance": "Бетіңізді туралау туралы нұсқаулар алу үшін экранның кез келген жерін екі рет түртіңіз.",
  "FaceTec_accessibility_key_down_guidance": "Бетті туралау бойынша нұсқаулық үшін Enter немесе Бос орын пернесін басыңыз",

  "FaceTec_accessibility_feedback_move_phone_away": "Бет тым жақын",
  "FaceTec_accessibility_feedback_move_phone_closer": "Тым алыс бет",
  "FaceTec_accessibility_feedback_face_too_far_left": "Бет тым алыс солға",
  "FaceTec_accessibility_feedback_face_too_far_right": "Бет тым алыс оңға",
  "FaceTec_accessibility_feedback_face_too_low": "Бет тым төмен",
  "FaceTec_accessibility_feedback_face_too_high": "Бет тым жоғары",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Бет тым алыс солға бұрылды",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Бет тым алыс оңға бұрылды",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Тым алыс солға бағытталған бет",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Тым алыс оңға бағытталған бет",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Бет жақтауда немесе тым алыс емес",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Құрылғыны көз деңгейінде ұстаңыз",
  "FaceTec_accessibility_feedback_move_away_web": "Камера тым жақын",
  "FaceTec_accessibility_feedback_move_closer_web": "Камера тым алыс",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Камераны көз деңгейіне жылжытыңыз",

  "FaceTec_presession_frame_your_face": "Бетіңізді сопақша жақтаңыз",
  "FaceTec_presession_look_straight_ahead": "Алға қара",
  "FaceTec_presession_hold_steady3": "Тұрақты ұстаңыз: 3",
  "FaceTec_presession_hold_steady2": "Тұрақты ұстаңыз: 2",
  "FaceTec_presession_hold_steady1": "Тұрақты ұстаңыз: 1",
  "FaceTec_presession_eyes_straight_ahead": "Алға қара",
  "FaceTec_presession_remove_dark_glasses": "Қара көзілдірікті алып тастаңыз",
  "FaceTec_presession_neutral_expression": "Бейтарап өрнек, күлімсіреу",
  "FaceTec_presession_conditions_too_bright": "Шарттар тым ашық",
  "FaceTec_presession_brighten_your_environment": "Қоршаған ортаңызды нұрландырыңыз",

  "FaceTec_feedback_center_face": "Бетіңізді ортаға қойыңыз",
  "FaceTec_feedback_face_not_found": "Бетіңізді жақтау",
  "FaceTec_feedback_move_phone_away": "Алысқа көшіңіз",
  "FaceTec_feedback_move_away_web": "Алысқа көшіңіз",
  "FaceTec_feedback_move_phone_closer": "Жақынырақ жылжытыңыз",
  "FaceTec_feedback_move_web_closer": "Бетіңізді жақындатыңыз және сопақшаны толтырыңыз",
  "FaceTec_feedback_move_web_even_closer": "Одан Да Жақын",
  "FaceTec_feedback_move_phone_to_eye_level": "Камераны көз деңгейіне жылжытыңыз",
  "FaceTec_feedback_move_to_eye_level_web": "Тікелей Камераға Қараңыз",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Алға қара",
  "FaceTec_feedback_face_not_upright": "Басыңызды тік ұстаңыз",
  "FaceTec_feedback_face_not_upright_mobile": "Басыңызды тік ұстаңыз",
  "FaceTec_feedback_hold_steady": "Тұрақты ұстаңыз",
  "FaceTec_feedback_use_even_lighting": "Бет біркелкі жарық",

  "FaceTec_instructions_header_ready_desktop": "Дайындалыңыз Сіздің бейнеңіз",
  "FaceTec_instructions_header_ready_1_mobile": "Дайындалыңыз",
  "FaceTec_instructions_header_ready_2_mobile": "Сіздің бейнеңіз",
  "FaceTec_instructions_message_ready_desktop": "Сіздің бетіңізді сопақша жақтаңыз, Менің дайынмын және жақындастырыңыз",
  "FaceTec_instructions_message_ready_1_mobile": "Сіздің бетіңізді сопақша жақтаңыз",
  "FaceTec_instructions_message_ready_2_mobile": "Менің дайынмын және жақындастырыңыз",

  "FaceTec_retry_header": "Мұны тағы да байқап көрейік",
  "FaceTec_retry_subheader_message": "Бізге нақты бейне-селфи керек",
  "FaceTec_retry_your_image_label": "Сенің сенсің",
  "FaceTec_retry_ideal_image_label": "Идеалды поза",
  "FaceTec_retry_instruction_message_1": "Поза мен жарықтандыруды жақсарту",
  "FaceTec_retry_instruction_message_2": "Бейтарап өрнек, күлімсіреу жоқ",
  "FaceTec_retry_instruction_message_3": "Тым бұлыңғыр, таза камера",

  "FaceTec_camera_feed_issue_header": "Камера арнасының қауіпсіздігін қамтамасыз ету мәселесі",
  "FaceTec_camera_feed_issue_header_mobile": "Қауіпсіздік мәселесі <br/>камераны қосу",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Бұл қолданба күдікті веб-камера параметрлерін блоктайды.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Толығырақ мына жерден біліңіз.</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Бұл жүйені келесіге байланысты тексеру мүмкін емес:",
  "FaceTec_camera_feed_issue_table_header_1": "Мүмкін Мәселе",
  "FaceTec_camera_feed_issue_table_header_2": "Түзету",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Firefox-та камера рұқсаттары есте сақталмайды.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Рұқсаттарды есте сақтау құсбелгісін қойыңыз.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Камераны басқа қолданба пайдаланып жатыр.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Басқа қолданбаны жабыңыз.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "3rd-party қосымшасы бейнені өзгертеді.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Басқа қолданбаны жабыңыз / жойыңыз.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Қорғалмайтын жабдық.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Басқа құрылғыны пайдаланыңыз",
  "FaceTec_camera_feed_issue_subtable_message": "Бұл қолданба күдікті веб-камера параметрлерін блоктайды. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Толығырақ мына жерден біліңіз.</a>.",
  "FaceTec_camera_feed_issue_action": "ҚАЙТАДАН БАЙҚАП КӨРІҢІЗ",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "ЖАРАЙДЫ МА",

  "FaceTec_camera_permission_header": "Камераны қосыңыз",
  "FaceTec_camera_permission_message": "Камера рұқсаттары өшірілген.<br/>амалдық жүйенің және шолғыштың параметрлерін тексеріңіз",

  "FaceTec_enter_fullscreen_header": "Толық экранды селфи режимі",
  "FaceTec_enter_fullscreen_message": "Бастамас бұрын толық экран режимін ашу үшін төмендегі түймені басыңыз.",
  "FaceTec_enter_fullscreen_action": "БҮКІЛ ЭКРАНДЫ АШЫҢЫЗ",

  "FaceTec_initializing_camera": "Камераны қамтамасыз ету",

  "FaceTec_idscan_type_selection_header": "Сканерлеуге дайындалыңыз<br/>сіздің сәйкестендіруіңіз",
  "FaceTec_idscan_capture_id_front_instruction_message": "Жеке куәліктің алдыңғы жағы",
  "FaceTec_idscan_capture_id_back_instruction_message": "Жеке куәліктің сыртқы жағы",
  "FaceTec_idscan_review_id_front_instruction_message": "Түсінікті және түсінікті ме?",
  "FaceTec_idscan_review_id_back_instruction_message": "Түсінікті және түсінікті ме?",
  "FaceTec_idscan_additional_review_message": "Қосымша растау<br/>қажет",
  "FaceTec_idscan_ocr_confirmation_main_header": "Шолу және растау",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Төмен қарай жылжытыңыз",
  "FaceTec_idscan_feedback_flip_id_to_back_message": "Жеке куәліктің сыртқы жағы",

  "FaceTec_result_success_message": "Дайын",
  "FaceTec_result_facescan_upload_message": "Жүктеу<br/>3D бетті сканерлеу<br/>шифрланған",
  "FaceTec_result_idscan_upload_message": "Жүктеу<br/>сәйкестендіруді сканерлеу<br/>шифрланбаған",
  "FaceTec_result_idscan_unsuccess_message": "Жеке куәлік суреті<br/>немесе<br/>пайдаланушының бетіне сәйкес келеді",
  "FaceTec_result_idscan_success_front_side_message": "ID сканерлеу аяқталды",
  "FaceTec_result_idscan_success_front_side_back_next_message": "ID алдыңғы<br/>сканерленді",
  "FaceTec_result_idscan_success_back_side_message": "ID сканерлеу аяқталды",
  "FaceTec_result_idscan_success_passport_message": "Паспортты сканерлеу аяқталды",
  "FaceTec_result_idscan_success_user_confirmation_message": "Фотосурет идентификаторын сканерлеу<br/>аяқталды",
  "FaceTec_result_idscan_success_additional_review_message": "Жеке куәлікті суретке түсіру<br/>аяқталды",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Бет<br/>сәйкес келмейді",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Идентификатор<br/>толық көрінбейді",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Сәйкестендіру мәтіні оқылмайды",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Идентификатордың бұл түріне қолдау көрсетілмейді<br/>Басқа идентификаторды пайдаланыңыз",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Штрих-кодты сканерлеу мүмкін болмады<br/>Қайталап көріңіз",
};

module.exports = FaceTecStrings;
