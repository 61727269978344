import { useEffect, useRef, useState } from 'react';
import { FaceTecIntro } from 'shared-fe-components/src/providers/FaceTec';
import { FaceTecVerify } from './FaceTecVerify';
import { useVerifyTaskContext } from '../VerifyTaskContext';

export const FaceTecFlow = () => {
  const [step, setStep] = useState('intro');

  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useVerifyTaskContext();

  const goToIntro = () => {
    setStep('intro');
  };

  const goToVerify = () => {
    setStep('verify');
  };

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  const additionalParameters = useRef({
    faceTecParameters: {
      verificationMode: 'MatchFaceMapToIdScan',
    },
  });

  return (
    <>
      {step === 'intro' && (
        <FaceTecIntro
          onCancel={onCancel}
          onBack={goToProviderSelection}
          onContinue={goToVerify}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'verify' && (
        <FaceTecVerify
          additionalParameters={additionalParameters.current}
          onBack={goToIntro}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
