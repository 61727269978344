import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FluentProvider, teamsLightTheme, teamsDarkTheme, Theme } from '@fluentui/react-components';
import { ColorSchemeVariables } from 'shared-fe-components/src/common/ColorSchemeVariables';
import { useCookies } from 'shared-fe-components/src/hooks';
import { ThemeObject, darkThemeCustomColors, defaultCustomColors } from './models';

const ThemeContext = createContext<ThemeObject | null>(null);

const getCustomColors = (themeName: string) => {
  return themeName === 'default' ? defaultCustomColors : { ...defaultCustomColors, ...darkThemeCustomColors };
};

export const ThemeContextProvider = ({ children }: any) => {
  const [themeName, setThemeName] = useState('default');
  const [themeForProvider, setThemeForProvider] = useState<Theme>(teamsLightTheme);
  const { getCookie, setCookie } = useCookies();

  useEffect(() => {
    const pieceOfTheme = getCookie('theme');

    if (pieceOfTheme) {
      setTheme(pieceOfTheme);
      return;
    }

    if (window.matchMedia) {
      const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

      const updateTheme = (_: unknown) => {
        setTheme(matchMedia.matches ? 'dark' : 'default');
      };

      updateTheme(undefined);
      matchMedia.addEventListener('change', updateTheme);

      return () => matchMedia.removeEventListener('change', updateTheme);
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute('app-theme', themeName);
  }, [themeName]);

  const setTheme = (theme: string) => {
    const strategy: Record<string, { value: string; theme: Theme }> = {
      dark: {
        value: 'dark',
        theme: teamsDarkTheme,
      },
      default: {
        value: 'default',
        theme: teamsLightTheme,
      },
    };

    const handle = strategy[theme];

    if (handle) {
      const { value, theme } = handle;
      setThemeName(value);
      setThemeForProvider(theme);
      setCookie({ key: 'theme', value: value });
    }
  };

  const customColors: Record<string, string> = useMemo(() => getCustomColors(themeName), [themeName]);

  return (
    <FluentProvider theme={themeForProvider}>
      <ThemeContext.Provider
        value={{
          theme: themeForProvider,
          customColors,
          themeName,
          setTheme: setTheme,
        }}
      >
        <ColorSchemeVariables themeColors={themeForProvider as any} customColors={customColors} />
        {children}
      </ThemeContext.Provider>
    </FluentProvider>
  );
};

export const useTheme = () => {
  const ctx = useContext(ThemeContext);

  if (!ctx) {
    throw new Error('Lack of ThemeProvider in components tree');
  }

  return ctx;
};
