import {
  GooglePlayIcon,
  AppStoreIcon,
  AppGalleryIcon,
  GooglePlayTitle,
  AppStoreTitle,
  AppGalleryTitle,
} from '../Icons';
import { QRCanvas } from '../QRCanvas';
import { t } from '@lingui/macro';
import './AppDownloads.scss';

interface DownloadButtonProps {
  store?: string,
  url?: string
}

interface AppDownloadsProps {
  googlePlay?: string,
  appStore?: string,
  appGallery?: string
}
const DownloadButton = ({ store = '', url = '' }: DownloadButtonProps) => {
  const superscript = t({ id: `AppDownloadButton.${store}.CTA` });
  const alt = t({ id: `AppDownloadButton.${store}.Name` });
  const icon = (() => {
    if (store === 'GooglePlay') return <GooglePlayIcon alt={alt} />; //TODO: Overlooked error, that alt was not used inside Icon.
    if (store === 'AppStore') return <AppStoreIcon alt={alt} />; //TODO: Overlooked error, that alt was not used inside Icon.
    if (store === 'AppGallery') return <AppGalleryIcon alt={alt} />; //TODO: Overlooked error, that alt was not used inside Icon.
  })();
  const title = (() => {
    if (store === 'GooglePlay') return <GooglePlayTitle alt={alt} />;
    if (store === 'AppStore') return <AppStoreTitle alt={alt} />;
    if (store === 'AppGallery') return <AppGalleryTitle alt={alt} />;
  })();
  const openStore = () => window.open(url, '_blank');

  if (!['GooglePlay', 'AppStore', 'AppGallery'].includes(store)) return <></>;
  return (
    <>
      <QRCanvas code={url} width={138} height={138} className="app-downloads__qr-code" onClick={openStore} />

      <button type="button" className="app-downloads__button" onClick={openStore}>
        <span className="app-downloads__button-icon">{icon}</span>
        <span className="app-downloads__button-superscript">{superscript}</span>
        <span className="app-downloads__button-title">{title}</span>
      </button>
    </>
  );
};

export const AppDownloads = ({ googlePlay, appStore, appGallery }: AppDownloadsProps) => {
  return (
    <div className="app-downloads">
      {googlePlay && <DownloadButton store="GooglePlay" url={googlePlay} />}
      {appStore && <DownloadButton store="AppStore" url={appStore} />}
      {appGallery && <DownloadButton store="AppGallery" url={appGallery} />}
    </div>
  );
};
