import { t } from '@lingui/macro';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from '../WithError';

import './Dropdown.scss';

interface DropdownProps {
  fieldName: string;
  options: {
    label: string;
    value: string;
    labelForDisplaying?: {};
  }[];
  rules?: {};
  className?: string;
  isDisabled?: boolean;
  rest?: any;
  errorMessage?: string;
}
export const Dropdown = ({
  fieldName,
  options,
  rules = {},
  className: additionalClassName,
  isDisabled = false,
  errorMessage,
  ...rest
}: DropdownProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, name, value: rhfValue, ref },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  const selectProps = {
    name,
    onBlur,
    options,
    ref,
    value: options.find((op: any) => op.value === rhfValue),
    className: `react-select ${additionalClassName ? additionalClassName : ''}`,
    classNamePrefix: 'react-select',
    onChange: (newValue: any) => {
      onChange(newValue.value);
    },
    placeholder: t({ id: 'Dropdown.Select' }),
    noOptionsMessage: (inputValue: any) => t({ id: 'Dropdown.NoOptions' }),
    isDisabled,
    ...rest,
  };

  return (
    <div className={`dropdown-with-error ${errorMessage ? 'validation-error' : ''}`}>
      <Select {...selectProps} />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};
