import { useLanguage } from 'contexts';
import { LANGUAGES, DEFAULT_LANGUAGE } from 'shared-fe-components/src/common/Constants';
import { Dropdown, DropdownProps, Option } from '@fluentui/react-components';
import { t } from '@lingui/macro';

export const LanguageDropdown = () => {
  const { setLanguage, canChangeLanguage, language } = useLanguage();

  const handleLanguageChange: Partial<DropdownProps>['onOptionSelect'] = (_, data) => {
    const selectedLanguage = LANGUAGES.find((language) => language.key === data.selectedOptions.toString());
    const selectedLanguageKey = selectedLanguage ? selectedLanguage.key : DEFAULT_LANGUAGE.key;
    setLanguage(selectedLanguageKey, true);
  };

  const dropdownLanguage = LANGUAGES.find((languageItem) => languageItem.key === language);

  if (!dropdownLanguage) {
    console.warn(`Selected language ${language} does not have display value. Selected value is incorrect or the value should be added`);
  }

  return canChangeLanguage ? (
    <Dropdown
      id="dropdown"
      className="dashboard__dropdown"
      placeholder={t({ id: 'CurrentLanguage' })}
      defaultValue={dropdownLanguage?.value}
      defaultSelectedOptions={dropdownLanguage ? [dropdownLanguage?.key] : undefined}
      onOptionSelect={handleLanguageChange}
    >
      {LANGUAGES.map((option) => (
        <Option key={option.key} value={option.key}>
          {option.value}
        </Option>
      ))}
    </Dropdown>
  ) : (
    <></>
  );
};
