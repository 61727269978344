var FaceTecStrings = {
  "FaceTec_action_ok": "ΟΚ",
  "FaceTec_action_im_ready": "ΕΙΜΑΙ ΕΤΟΙΜΟΣ",
  "FaceTec_action_try_again": "ΠΡΟΣΠΑΘΗΣΤΕ ΞΑΝΑ",
  "FaceTec_action_continue": "ΣΥΝΕΧΕΙΑ",
  "FaceTec_action_take_photo": "ΛΗΨΗ ΦΩΤΟΓΡΑΦΙΑΣ",
  "FaceTec_action_accept_photo": "ΑΠΟΔΟΧΗ",
  "FaceTec_action_retake_photo": "ΛΗΨΗ ΞΑΝΑ",
  "FaceTec_action_confirm": "ΕΠΙΒΕΒΑΙΩΣΗ ΠΛΗΡΟΦΟΡΙΩΝ",

  "FaceTec_accessibility_cancel_button": "Ακύρωση",
  "FaceTec_accessibility_tap_guidance": "Πατήστε δύο φορές οπουδήποτε στην οθόνη για καθοδήγηση ευθυγράμμισης προσώπου.",
  "FaceTec_accessibility_key_down_guidance": "Πατήστε Enter ή Spacebar για καθοδήγηση ευθυγράμμισης προσώπου",

  "FaceTec_accessibility_feedback_move_phone_away": "Πρόσωπο πολύ κοντά",
  "FaceTec_accessibility_feedback_move_phone_closer": "Πρόσωπο πολύ μακριά",
  "FaceTec_accessibility_feedback_face_too_far_left": "Πρόσωπο πολύ αριστερά",
  "FaceTec_accessibility_feedback_face_too_far_right": "Πρόσωπο πολύ δεξιά",
  "FaceTec_accessibility_feedback_face_too_low": "Πρόσωπο πολύ χαμηλά",
  "FaceTec_accessibility_feedback_face_too_high": "Πρόσωπο πολύ ψηλά",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Το πρόσωπο περιστράφηκε πολύ αριστερά",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Το πρόσωπο περιστράφηκε πολύ δεξιά",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Το πρόσωπο κοιτάζει πολύ αριστερά",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Το πρόσωπο κοιτάζει πολύ δεξιά",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Πρόσωπο όχι στην κάμερα ή πολύ μακριά",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Κρατήστε τη συσκευή στο ύψος των ματιών",
  "FaceTec_accessibility_feedback_move_away_web": "Κάμερα πολύ κοντά",
  "FaceTec_accessibility_feedback_move_closer_web": "Κάμερα πολύ μακριά",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Μετακινήστε την κάμερα στο επίπεδο των ματιών",

  "FaceTec_presession_frame_your_face": "Πλαισιώστε το πρόσωπό σας στο οβάλ",
  "FaceTec_presession_look_straight_ahead": "Κοιτάξτε ευθεία μπροστά",
  "FaceTec_presession_hold_steady3": "Κρατήστε σταθερά για: 3",
  "FaceTec_presession_hold_steady2": "Κρατήστε σταθερά για: 2",
  "FaceTec_presession_hold_steady1": "Κρατήστε σταθερά για: 1",
  "FaceTec_presession_eyes_straight_ahead": "Κοίταξε ευθεία μπροστά",
  "FaceTec_presession_remove_dark_glasses": "Αφαιρέστε τα σκούρα γυαλιά",
  "FaceTec_presession_neutral_expression": "Ουδέτερη έκφραση, χωρίς χαμόγελο",
  "FaceTec_presession_conditions_too_bright": "Πολύ φωτεινές συνθήκες",
  "FaceTec_presession_brighten_your_environment": "Φωτίστε το περιβάλλον σας",

  "FaceTec_feedback_center_face": "Κεντράρετε το πρόσωπό σας",
  "FaceTec_feedback_face_not_found": "Πλαισιώστε το πρόσωπό σας",
  "FaceTec_feedback_move_phone_away": "Απομακρυνθείτε",
  "FaceTec_feedback_move_away_web": "Απομακρυνθείτε",
  "FaceTec_feedback_move_phone_closer": "Πλησιάστε",
  "FaceTec_feedback_move_web_closer": "Φέρτε το πρόσωπο πιο κοντά και συμπληρώστε το οβάλ",
  "FaceTec_feedback_move_web_even_closer": "Ακόμα Πιο Κοντά",
  "FaceTec_feedback_move_phone_to_eye_level": "Μετακινήστε την κάμερα στο επίπεδο των ματιών",
  "FaceTec_feedback_move_to_eye_level_web": "Κοιτάξτε Απευθείας Την Κάμερα",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Κοίταξε ευθεία μπροστά",
  "FaceTec_feedback_face_not_upright": "Κρατήστε το κεφάλι σας ίσια",
  "FaceTec_feedback_face_not_upright_mobile": "Κρατήστε το κεφάλι σας ίσια",
  "FaceTec_feedback_hold_steady": "Κρατήστε σταθερά",
  "FaceTec_feedback_use_even_lighting": "Φωτίστε το πρόσωπο πιο ομοιόμορφα",

  "FaceTec_instructions_header_ready_desktop": "Ετοιμαστείτε για το βίντεο-σέλφι σας",
  "FaceTec_instructions_header_ready_1_mobile": "Ετοιμαστείτε για",
  "FaceTec_instructions_header_ready_2_mobile": "το βίντεο-σέλφι σας",
  "FaceTec_instructions_message_ready_desktop": "Προσαρμόστε το πρόσωπό σας στο οβάλ, Πιέστε ΕΙΜΑΙ ΕΤΟΙΜΟΣ και πλησιάστε",
  "FaceTec_instructions_message_ready_1_mobile": "Προσαρμόστε το πρόσωπό σας στο οβάλ",
  "FaceTec_instructions_message_ready_2_mobile": "Πιέστε ΕΙΜΑΙ ΕΤΟΙΜΟΣ και πλησιάστε",

  "FaceTec_retry_header": "Ας το δοκιμάσουμε ξανά",
  "FaceTec_retry_subheader_message": "Χρειαζόμαστε ένα πιο καθαρό βίντεο-σέλφι",
  "FaceTec_retry_your_image_label": "Η σέλφι σας",
  "FaceTec_retry_ideal_image_label": "Ιδανική πόζα",
  "FaceTec_retry_instruction_message_1": "Χρήση Ιδανικής Πόζας και Φωτισμού",
  "FaceTec_retry_instruction_message_2": "Ουδέτερη έκφραση, χωρίς χαμόγελο",
  "FaceTec_retry_instruction_message_3": "Είναι πολύ θολό, καθαρίστε την κάμερα",

  "FaceTec_camera_feed_issue_header": "Πρόβλημα προστατεύοντας τη σύνδεση της κάμερας",
  "FaceTec_camera_feed_issue_header_mobile": "Πρόβλημα προστατεύοντας<br/>τη σύνδεση της κάμερας",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Αυτή η εφαρμογή αποκλείει ύποπτες διαμορφώσεις ιστού.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Μάθετε περισσότερα εδώ.</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Αυτό το σύστημα δεν μπορεί να επαληθευτεί λόγω των εξής:",
  "FaceTec_camera_feed_issue_table_header_1": "Πιθανό πρόβλημα",
  "FaceTec_camera_feed_issue_table_header_2": "Σωστός",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Το θάλαμο επιτρέπει να μην θυμούνται στον Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Mark Θυμηθείτε δικαιώματα.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Το θάλαμο που χρησιμοποιείται ήδη από άλλη εφαρμογή.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Κλείστε την άλλη εφαρμογή.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "Μια εφαρμογή 3ης στάθμευσης τροποποιεί το βίντεο.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Κλείστε / απεγκαταστήστε την άλλη εφαρμογή.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Εξοπλισμός που δεν μπορεί να προστατευθεί.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Χρησιμοποιήστε μια διαφορετική συσκευή.",
  "FaceTec_camera_feed_issue_subtable_message": "Αυτή η εφαρμογή αποκλείει ύποπτες διαμορφώσεις ιστού. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Μάθετε περισσότερα εδώ.</a>.",
  "FaceTec_camera_feed_issue_action": "ΠΡΟΣΠΑΘΗΣΤΕ ΞΑΝΑ",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "ΟΚ",

  "FaceTec_camera_permission_header": "Ενεργοποίηση κάμερας",
  "FaceTec_camera_permission_message": "Τα δικαιώματα κάμερας απενεργοποιήθηκαν.<br/>ελέγξτε το λειτουργικό σας σύστημα και τις ρυθμίσεις του προγράμματος περιήγησης",

  "FaceTec_enter_fullscreen_header": "Λειτουργία Selfie Πλήρους Οθόνης",
  "FaceTec_enter_fullscreen_message": "Πριν ξεκινήσουμε, κάντε κλικ στο παρακάτω κουμπί για να ανοίξετε τη λειτουργία πλήρους οθόνης.",
  "FaceTec_enter_fullscreen_action": "ΆΝΟΙΓΜΑ ΠΛΉΡΟΥΣ ΟΘΌΝΗΣ",

  "FaceTec_initializing_camera": "Ασφάλιση τροφοδοσίας κάμερας",

  "FaceTec_idscan_type_selection_header": "Προετοιμάστε για σάρωση<br/>την Ταυτότητά σας",
  "FaceTec_idscan_capture_id_front_instruction_message": "Μπροστινή όψη ταυτότητας",
  "FaceTec_idscan_capture_id_back_instruction_message": "Πίσω όψη ταυτότητας",
  "FaceTec_idscan_review_id_front_instruction_message": "Φωτογραφία καθαρή & ευανάγνωστη;",
  "FaceTec_idscan_review_id_back_instruction_message": "Φωτογραφία καθαρή & ευανάγνωστη;",
  "FaceTec_idscan_additional_review_message": "Πρόσθετος έλεγχος<br/>Απαιτείται",
  "FaceTec_idscan_ocr_confirmation_main_header": "Έλεγχος & Επιβεβαίωση",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Μετακίνηση κάτω",

  "FaceTec_result_success_message": "Επιτυχία",
  "FaceTec_result_facescan_upload_message": "Μεταφόρτωση<br/>Κρυπτογραφημένο<br/>3D FaceScan",
  "FaceTec_result_idscan_upload_message": "Μεταφόρτωση<br/>Κρυπτογραφημένη<br/>Σάρωση ID",
  "FaceTec_result_idscan_unsuccess_message": "Η Φωτογραφία ταυτότητας<br/>Δεν ταιριάζει<br/>Στο πρόσωπο του χρήστη",
  "FaceTec_result_idscan_success_front_side_message": "Η σάρωση ταυτότητας ολοκληρώθηκε",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Μπροστινή όψη ταυτότητας<br/>Σαρώθηκε",
  "FaceTec_result_idscan_success_back_side_message": "Η σάρωση ταυτότητας ολοκληρώθηκε",
  "FaceTec_result_idscan_success_passport_message": "Ολοκληρώθηκε η σάρωση διαβατηρίου",
  "FaceTec_result_idscan_success_user_confirmation_message": "Σάρωση φωτογραφίας ταυτότητας<br/>Ολοκληρώθηκε",
  "FaceTec_result_idscan_success_additional_review_message": "Λήψη φωτογραφίας ταυτότητας<br/>Ολοκληρώθηκε",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Το πρόσωπο δεν ταίριαζε<br/>Αρκετά",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Έγγραφο ταυτότητας<br/>Δεν είναι πλήρως ορατό",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Κείμενο Ταυτότητας Μη Ευανάγνωστο",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Ο τύπος αναγνωριστικού δεν υποστηρίζεται<br/>Χρησιμοποιήστε διαφορετικό έγγραφο ταυτοποίησης",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Η σάρωση του γραμμοκώδικα απέτυχε<br/>Προσπαθήστε ξανά",
};

module.exports = FaceTecStrings;
