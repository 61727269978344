import { CoffeeIcon } from '../Icons';
import { t } from '@lingui/macro';
import './CoffeeScreen.scss';

export const ProviderPendingScreen = ({ processType = 'Sign' }: { processType?: string }) => {
  return (
    <div className="coffee-screen" role="alert">
      <CoffeeIcon className="coffee-screen__icon" />
      <div>{t({ id: 'StatusScreen.CoffeeTime' })}</div>
      <div>{t({ id: `StatusScreen.ProviderPending.${processType}.Text` })}</div>
    </div>
  );
};
