import { ReactNode, useEffect, useState } from 'react';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { useLanguage, useLayout } from 'contexts';
import { OtpRequest } from './OtpRequest';
import { OtpCode } from './OtpCode';
import { useSigningContext } from '../SigningContext';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import './OtpVerification.scss';

export enum OtpVerificationScreen {
  Request,
  Code,
}

type OtpVerificationScreenComponents = {
  [screen in OtpVerificationScreen]: ReactNode;
};

export const OtpVerification = () => {
  const { setDashboardSize } = useLayout();
  const { setCanChangeLanguage } = useLanguage();
  const { signatureId } = useSigningContext();
  const [wasCodeRequested] = usePersistentStorage(`signature-${signatureId}-code-requested`);
  const [screen, setScreen] = useState<OtpVerificationScreen>(
    wasCodeRequested ? OtpVerificationScreen.Code : OtpVerificationScreen.Request
  );

  useEffect(() => {
    setCanChangeLanguage(false);
    setDashboardSize('small');

    return () => {
      setCanChangeLanguage(true);
      setDashboardSize('normal');
    };
  }, []);

  const otpScreenComponents: OtpVerificationScreenComponents = {
    [OtpVerificationScreen.Request]: <OtpRequest onRequest={() => setScreen(OtpVerificationScreen.Code)} />,
    [OtpVerificationScreen.Code]: <OtpCode />,
  };

  return <WizardBox>{otpScreenComponents[screen]}</WizardBox>;
};
