import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { AppDownloads, MarketplaceURL } from '../../common/AppDownloads';
import { SimplySignIcon } from '../../common/Icons';
import { WizardBox } from '../../common/WizardBox/WizardBox';
import { SimplySignIntroProps } from "./models";
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './SimplySignDownload.scss';

export const SimplySignDownload = ({ onBack, onCancel, onContinue }: Pick<SimplySignIntroProps, "onBack" | "onCancel" | "onContinue" >) => {
  return (
    <WizardBox className="simply-download">
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Providers.SimplySign.Download.Header' })}
        </Text>
      </div>
      <div className="simply-download__logo-container">
        <SimplySignIcon className="simply-download__icon" />
      </div>
      <div className="simply-download__downloads-container">
        <AppDownloads {...MarketplaceURL.SimplySign} />
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onContinue && (
            <Button appearance="primary" onClick={onContinue}>
              {t({ id: 'Common.Proceed' })}
            </Button>
          )}
        </div>
      </div>
    </WizardBox>
  );
};
