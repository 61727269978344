import { Flex } from '@fluentui/react-migration-v0-v9';
import { Spinner } from '@fluentui/react-components';

export const LoadingScreen = ({ label }: { label?: string }) => {
  return (
    <Flex column vAlign="center" style={{ height: '100%' }}>
      <Spinner label={label} labelPosition="below" />
    </Flex>
  );
}
