import { Header } from "shared-fe-components/src/common/Header";
import { Trans } from '@lingui/react';
import { Text } from '@fluentui/react-components';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { CertificateParameters } from "./CertificateParameters";
import { t } from '@lingui/macro';
import { MSzafirCertificateType } from "./models";

export class CertificateIntroProps {
    data?: any;
    certificateType: MSzafirCertificateType | null = null;
    onCertTypeChanged?: (certType: string | undefined) => void;
}

export const CertificateIntro = (props: CertificateIntroProps) => {
    const handleCertTypeChanged = (selectedKey: string | undefined): void => {
        if(props.onCertTypeChanged !== undefined && props.onCertTypeChanged !== null) {
            props.onCertTypeChanged(selectedKey);
        }
    }

    return (
        <>                
            <Header as="h1">{t({ id: 'Common.Greeting' })}</Header>
            {props.data &&
            <>
                <Text as="p">
                    <Trans id="DashboardCertificate.Introduction" values={{ sender: props.data?.sender.email }} components={[<Anchor href={`mailto:${props.data?.sender.email}`} />]} />
                </Text>
                <CertificateParameters certificateType={props.certificateType} onCertTypeChanged={handleCertTypeChanged} />
            </>
            }
        </>
    )
}