var FaceTecOcrStrings = {
  "groups": [
    {
      "groupFriendlyName": "Deine Informationen",
      "fields": [
        {
          "fieldKey": "lastName",
          "fieldFriendlyName": "Nachname",
          "uiFieldDescriptionText": "Last Name"
        },
        {
          "fieldKey": "firstName",
          "fieldFriendlyName": "Vorname",
          "uiFieldDescriptionText": "First Name"
        },
        {
          "fieldKey": "placeOfBirth",
          "fieldFriendlyName": "Geburtsort",
          "uiFieldDescriptionText": "Place of Birth"
        },
        {
          "fieldKey": "middleInitial",
          "fieldFriendlyName": "Mittelinitiale",
          "uiFieldDescriptionText": "Middle Initial"
        },
        {
          "fieldKey": "nationality",
          "fieldFriendlyName": "Nationalität",
          "uiFieldDescriptionText": "Nationality"
        },
        {
          "fieldKey": "givenName",
          "fieldFriendlyName": "Vorname",
          "uiFieldDescriptionText": "Given Name"
        },
        {
          "fieldKey": "fullName",
          "fieldFriendlyName": "Vor- und Nachname",
          "uiFieldDescriptionText": "Full Name"
        },
        {
          "fieldKey": "dateOfBirth",
          "fieldFriendlyName": "Geburtsdatum",
          "uiFieldDescriptionText": "Date of Birth"
        },
        {
          "fieldKey": "middleName",
          "fieldFriendlyName": "Zweiter Vorname",
          "uiFieldDescriptionText": "Middle Name"
        }
      ],
      "groupKey": "userInfo"
    },
    {
      "groupFriendlyName": "Deine Adresse",
      "fields": [
        {
          "fieldKey": "zipCode",
          "fieldFriendlyName": "PLZ",
          "uiFieldDescriptionText": "Zip Code"
        },
        {
          "fieldKey": "country",
          "fieldFriendlyName": "Land",
          "uiFieldDescriptionText": "Country"
        },
        {
          "fieldKey": "address3",
          "fieldFriendlyName": "Adresszeile 3",
          "uiFieldDescriptionText": "Address Line 3"
        },
        {
          "fieldKey": "province",
          "fieldFriendlyName": "Provinz",
          "uiFieldDescriptionText": "Province"
        },
        {
          "fieldKey": "address2",
          "fieldFriendlyName": "Adresszeile 2",
          "uiFieldDescriptionText": "Address #2"
        },
        {
          "fieldKey": "city",
          "fieldFriendlyName": "Stadt",
          "uiFieldDescriptionText": "City"
        },
        {
          "fieldKey": "address1",
          "fieldFriendlyName": "Adresszeile 1",
          "uiFieldDescriptionText": "Address Line 1"
        },
        {
          "fieldKey": "county",
          "fieldFriendlyName": "Grafschaft",
          "uiFieldDescriptionText": "County"
        },
        {
          "fieldKey": "state",
          "fieldFriendlyName": "Bundesstaat",
          "uiFieldDescriptionText": "State"
        },
        {
          "fieldKey": "territory",
          "fieldFriendlyName": "Gebiet",
          "uiFieldDescriptionText": "Territory"
        }
      ],
      "groupKey": "addressInfo"
    },
    {
      "groupFriendlyName": "Körperliche Merkmale & Sonstiges",
      "fields": [
        {
          "fieldKey": "hair",
          "fieldFriendlyName": "Haarfarbe",
          "uiFieldDescriptionText": "Hair"
        },
        {
          "fieldKey": "sex",
          "fieldFriendlyName": "Geschlecht",
          "uiFieldDescriptionText": "Gender"
        },
        {
          "fieldKey": "weight",
          "fieldFriendlyName": "Gewicht",
          "uiFieldDescriptionText": "Weight"
        },
        {
          "fieldKey": "eyes",
          "fieldFriendlyName": "Augenfarbe",
          "uiFieldDescriptionText": "Eyes"
        },
        {
          "fieldKey": "height",
          "fieldFriendlyName": "Höhe",
          "uiFieldDescriptionText": "Height"
        }
      ],
      "groupKey": "secondaryUserInfo"
    },
    {
      "groupFriendlyName": "Weitere Informationen",
      "fields": [
        {
          "fieldKey": "customField5",
          "fieldFriendlyName": "Eingabefeld 5",
          "uiFieldDescriptionText": "Custom Field 5"
        },
        {
          "fieldKey": "customField1",
          "fieldFriendlyName": "Eingabefeld 1",
          "uiFieldDescriptionText": "Custom Field 1"
        },
        {
          "fieldKey": "customField2",
          "fieldFriendlyName": "Eingabefeld 2",
          "uiFieldDescriptionText": "Custom Field 2"
        },
        {
          "fieldKey": "customField3",
          "fieldFriendlyName": "Eingabefeld 3",
          "uiFieldDescriptionText": "Custom Field 3"
        },
        {
          "fieldKey": "customField4",
          "fieldFriendlyName": "Eingabefeld 4",
          "uiFieldDescriptionText": "Custom Field 4"
        }
      ],
      "groupKey": "customFields"
    },
    {
      "groupFriendlyName": "Details zum Dokument",
      "fields": [
        {
          "fieldKey": "restrictions",
          "fieldFriendlyName": "Einschränkungen",
          "uiFieldDescriptionText": "Restrictions"
        },
        {
          "fieldKey": "passportType",
          "fieldFriendlyName": "Passart",
          "uiFieldDescriptionText": "Passport Type"
        },
        {
          "fieldKey": "idNumber",
          "fieldFriendlyName": "Dokumentennummer",
          "uiFieldDescriptionText": "Photo ID #"
        },
        {
          "fieldKey": "dateOfIssue",
          "fieldFriendlyName": "Ausgabedatum",
          "uiFieldDescriptionText": "Issued Date"
        },
        {
          "fieldKey": "idNumber2",
          "fieldFriendlyName": "Zweite Dokumentennummer",
          "uiFieldDescriptionText": "Secondary ID #"
        },
        {
          "fieldKey": "issuingAuthority",
          "fieldFriendlyName": "Ausstellende Behörde",
          "uiFieldDescriptionText": "Issuing Authority"
        },
        {
          "fieldKey": "dateOfExpiration",
          "fieldFriendlyName": "Ablaufdatum",
          "uiFieldDescriptionText": "Expiration Date"
        },
        {
          "fieldKey": "countryCode",
          "fieldFriendlyName": "Ländercode",
          "uiFieldDescriptionText": "Country Code"
        },
        {
          "fieldKey": "end",
          "fieldFriendlyName": "Ende",
          "uiFieldDescriptionText": "End"
        },
        {
          "fieldKey": "mrzLine3",
          "fieldFriendlyName": "MRZ 3",
          "uiFieldDescriptionText": "MRZ 3"
        },
        {
          "fieldKey": "barcode",
          "fieldFriendlyName": "Strichcode",
          "uiFieldDescriptionText": "Barcode"
        },
        {
          "fieldKey": "class",
          "fieldFriendlyName": "Klasse",
          "uiFieldDescriptionText": "Class"
        },
        {
          "fieldKey": "mrzLine1",
          "fieldFriendlyName": "MRZ 1",
          "uiFieldDescriptionText": "MRZ 1"
        },
        {
          "fieldKey": "mrzLine2",
          "fieldFriendlyName": "MRZ 2",
          "uiFieldDescriptionText": "MRZ 2"
        }
      ],
      "groupKey": "idInfo"
    }
  ]
};

export default FaceTecOcrStrings;
