import * as client from "./client";
import ky from "ky";

export const requestContainer = async (files: any) =>
  await client.post("storage/request-container", files);

export const uploadFile = async (uploadUrl: any, file: any) =>
  await ky.put(uploadUrl, {
    headers: { "x-ms-blob-type": "BlockBlob" },
    body: file,
    timeout: 120000,
  });

export const mergePdf = async (mergeData: any) =>
  await client.post("pdf/merge", mergeData, undefined, 2);

export const getMergeSession = async (sessionId: any) =>
  await client.getSafe(`pdf/merge/${sessionId}`, undefined, 2);

export const getMergedFile = async (downloadUrl: any) =>
  await ky.get(downloadUrl, {
    timeout: 120000,
  })
