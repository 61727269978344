import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSigningContext } from '../SigningContext';
import { EidEasySign, EidEasyIntro } from 'shared-fe-components/src/providers/EidEasy';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { useConfigurationContext, useUserContext } from 'contexts';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { lastSelectedCountryStorageKey } from 'shared-fe-components/src/providers/ProviderSelection';

export const EidEasyFlow = () => {
  const [step, setStep] = useState<'intro' | 'loading' | 'sign'>('intro');
  const [signingUrl, setSigningUrl] = useState<string>('');
  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy, selectedProvider, sign, getSignatureStatus } =
    useSigningContext();
  const { providers } = useConfigurationContext();
  const { user } = useUserContext();
  const subprovider = useMemo(() => selectedProvider.split(':')?.[1], [selectedProvider]);
  const [lastSelectedCountry] = usePersistentStorage(lastSelectedCountryStorageKey);

  const goToIntro = () => setStep('intro');
  const goToSign = useCallback(async () => {
    setStep('loading');
    const additionalParameters = {
      eidEasyParameters: {
        selectedSubproviderName: subprovider,
        signerEmail: user?.email,
      }
    };
    const response = await sign({ additionalParameters });

    const baseUrl = providers.eidEasy.baseUrl;
    const clientId = providers.eidEasy.clientId;
    const docId = response.providerResponse.docId;

    const url = new URL('/single-method-signature', baseUrl);
    url.searchParams.set('client_id', clientId);
    url.searchParams.set('doc_id', docId);
    url.searchParams.set('country', lastSelectedCountry);
    url.searchParams.set('method', subprovider);
    setSigningUrl(url.toString());

    setStep('sign');
  }, [sign, providers, subprovider, user]);

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  // status refetching effect
  useEffect(() => {
    if (step === 'sign') {
      const interval = setInterval(async () => {
        await getSignatureStatus({});
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [step, getSignatureStatus]);

  return (
    <>
      {step === 'intro' && (
        <EidEasyIntro
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToSign}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'loading' && <LoadingScreen />}
      {step === 'sign' && <EidEasySign onBack={goToIntro} onCancel={onCancel} signingUrl={signingUrl} />}
    </>
  );
};
