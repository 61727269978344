import { CoffeeIcon } from '../Icons';
import { t } from '@lingui/macro';
import './CoffeeScreen.scss';

export const QueuedScreen = () => {
  return (
    <div className="coffee-screen" role="alert">
      <CoffeeIcon className="coffee-screen__icon" />
      <div>{t({ id: `StatusScreen.Queued.Wait` })}</div>
      <div>{t({ id: `StatusScreen.Queued.Text` })}</div>
    </div>
  );
};
