import { useEffect } from 'react';
import { Text } from '@fluentui/react-components';
import { CheckIcon } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import { useLanguage } from '../../../../contexts/LanguageContext';
import './SimplySignAuth.scss';

export const SimplySignAuth = () => {
  const { setCanChangeLanguage } = useLanguage();

  useEffect(() => {
    setCanChangeLanguage(false);
  }, [setCanChangeLanguage]);

  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams;
    const code = urlParams.get('code') as string;
    const state = urlParams.get('state') as string;
    localStorage.setItem('code', code);
    localStorage.setItem('state', state);
    setTimeout(() => window.close(), 5000);
  }, []);

  return (
    <div className="simply-sign-auth">
      <h1 className="simply-sign-auth__header">
        <CheckIcon />
        <span>{t({ id: 'Providers.SimplySign.Auth.Header' })}</span>
      </h1>
      <Text as="p" className="simply-sign-auth__subheader">
        {t({ id: 'Providers.SimplySign.Auth.Success' })}
      </Text>
    </div>
  );
};
