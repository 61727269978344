import { Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { TimeoutIcon } from '../Icons';
import { TimeoutScreenProps } from './models';
import { WizardTintedBox } from '../WizardBox';

export const TimeoutScreen = ({ onCancel, onRetry }: TimeoutScreenProps) => {
  return (
    <WizardTintedBox color="red" role="alert">
      <div></div>
      <div>
        <TimeoutIcon />
        <h1>{t({ id: 'StatusScreen.Timeout.Description' })}</h1>
      </div>
      <div>
        <div></div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onRetry && (
            <Button apperance="primary" onClick={onRetry}>
              {t({ id: 'Common.Retry' })}
            </Button>
          )}
        </div>
      </div>
    </WizardTintedBox>
  );
};
