import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import Webcam from 'react-webcam';
import { Button, Text } from '@fluentui/react-components';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { t } from '@lingui/macro';
import { CameraIcon, RecordIcon, VerificationSuccessIcon } from 'shared-fe-components/src/common/Icons';
import { Header } from 'shared-fe-components/src/common/Header';
import { useVerifyTaskContext } from '../VerifyTaskContext';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import * as api from 'shared-fe-components/src/api';
import './Record.scss';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';

interface Props {
  onBack: () => void;
  onContinue: () => void;
  videoDuration: number;
}

export const Record = ({ onContinue, onBack, videoDuration }: Props) => {
  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [display, setDisplay] = useState<null | 'WebcamError' | 'UnknownError' | 'Loading' | 'Success'>(null);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const { verificationVideoDetails, setVerificationVideoDetails, verificationId } = useVerifyTaskContext();

  const startTimer = () => {
    if (!timerStarted) setTimerStarted(true);
  };

  const onStartRecording = useCallback(() => {
    if (webcamRef.current === null) return;
    if (webcamRef.current?.stream === null || webcamRef.current?.stream === undefined) {
      setDisplay('WebcamError');
      return;
    }
    setRecordedChunks([]);
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm',
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const startUploading = useCallback(async () => {
    try {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      const fileName = `verification-${verificationId}-video.webm`;
      const file = new File([blob], fileName);
      const container = await api.merging.requestContainer({ documents: [{ fileName }] });
      await api.merging.uploadFile(container.documents[0].uploadUrl, file);

      setVerificationVideoDetails({
        ...verificationVideoDetails,
        videoParams: {
          verificationVideoSource: {
            containerId: container.id,
            fileId: container.documents[0].id,
          },
        },
      });
      setDisplay('Success');
    } catch (error) {
      setDisplay('UnknownError');
      console.error(error);
    }
  }, [recordedChunks]);

  const onStopRecording = useCallback(() => {
    if (mediaRecorderRef.current === null) return;
    mediaRecorderRef.current.stop();
    setCapturing(false);
    startUploading();
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const onDownload = useCallback(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'recording.webm';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (webcamRef.current === null) return;
    // we need to do this like that, so it doesn't echo
    // we cannot set `<Webcam audio={false}`
    // because it won't record the audio then
    webcamRef.current.video.muted = true;
  }, [webcamRef]);

  const isVideoReady = useMemo(() => recordedChunks.length > 0 && !capturing, [recordedChunks, capturing]);

  useEffect(() => {
    if (isVideoReady) setDisplay('Loading');
  }, [isVideoReady]);

  switch (display) {
    case 'WebcamError':
      return <WebcamError />;
    case 'UnknownError':
      return <ErrorScreen processType="Verify" />;
    case 'Success':
      return <UploadSuccessful {...{ onDownload, onContinue }} />;
    case 'Loading':
      return <LoadingScreen />;
    default:
      break;
  }

  return (
    <WizardBox>
      <div></div>
      <div>
        <div className="record-video-record__video-container">
          <div className="record-video-record__timer">
            {timerStarted ? (
              <Timer videoDuration={videoDuration} onStart={onStartRecording} onEnd={onStopRecording} />
            ) : (
              t({ id: 'Common.GetReady' })
            )}
          </div>
          <Webcam
            audio={true}
            ref={webcamRef}
            onUserMedia={startTimer}
            onUserMediaError={() => setDisplay('WebcamError')}
          />
        </div>
      </div>
      <div>
        <div>
          <ButtonBack onClick={onBack} />
        </div>
        <div>{process.env.NODE_ENV === 'development' && <Button onClick={onContinue}>(dev) Skip</Button>}</div>
      </div>
    </WizardBox>
  );
};

interface TimerProps {
  videoDuration: number;
  onStart: () => any;
  onEnd: () => any;
}

const Timer = ({ videoDuration, onStart, onEnd }: TimerProps) => {
  const getCountdownTimes = () => {
    const seconds = Array.from(Array(videoDuration).keys()).reverse();
    const zeroPaddedstrings = seconds.map((s) => s.toString().padStart(2, '0'));
    const strings = zeroPaddedstrings.map((s) => `00:${s}`);
    const withIcon = strings.map((s) => (
      <div className="record-video-record__timer-inner-with-icon">
        <RecordIcon />
        <div>{s}</div>
      </div>
    ));
    // yes I know it'll give us 00:61, but max time is 20 seconds...
    // and I refuse to waste time on doing maths with dayjs
    return withIcon;
  };

  const elements = ['3', '2', '1', ...getCountdownTimes(), t({ id: 'Common.ThankYou' })];

  const [currentElementIndex, setCurrentElementIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // we need to steal current state to a regular variable
      // accesing currentElementIndex will give us outdated state
      //
      // also if we try to call onStart / onEnd from the setCurrentElementIndex((v) => ...)
      // we'll upset React because of calling state change from state changing function
      let _curr;
      setCurrentElementIndex((currentValue) => {
        _curr = currentValue;
        return currentValue;
      });
      if (_curr === undefined) return;
      if (_curr === 3) {
        onStart();
      }
      if (_curr + 1 === elements.length) {
        clearInterval(interval);
        onEnd();
        return;
      }
      setCurrentElementIndex((v) => v + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <>{elements[currentElementIndex]}</>;
};

const UploadSuccessful = ({ onDownload, onContinue }: any) => (
  <div className="record-video-record__success">
    <VerificationSuccessIcon />
    <Header as="h1">{t({ id: 'RecordVideo.UploadSuccessful' })}</Header>
    {process.env.NODE_ENV === 'development' && <Button onClick={onDownload}>(dev) Download</Button>}
    <Button appearance="primary" onClick={onContinue}>
      {t({ id: 'Common.Proceed' })}
    </Button>
  </div>
);

const WebcamError = () => (
  <div className="record-video-record__error">
    <CameraIcon />
    <Header as="h1">{t({ id: 'RecordVideo.CameraError.Title' })}</Header>
    <Text>{t({ id: 'RecordVideo.CameraError.Text' })}</Text>
  </div>
);
