import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider, UserContextProvider } from './contexts';
import { LanguageContextProvider } from './contexts/LanguageContext';
import { LocalizationProvider } from './contexts/LocalizationContext';
import { DashboardLayout, Signing, SimplySignAuth, EidEasyCallback, Verification } from './components/Dashboard';
import { NotFound } from './components/NotFound';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { OperationCompletedCallback } from 'components/OperationCompletedCallback/OperationCompletedCallback';
import { FC, PropsWithChildren } from 'react';
import { ConfigurationProvider } from './contexts';
import './lib/setupApi';
import { Certificate } from 'components/Dashboard/Certificate/Certificate';

const Routing = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<NotFound />} />
        <Route path="sign/:sessionId/:signatureId" element={<Signing />} />
        <Route path="verify/:sessionId/:verificationId" element={<Verification />} />
        <Route path="certificate-generation/:sessionId/:certificateId" element={<Certificate />} />
        <Route path="providers/asseco/simplysign/callback" element={<SimplySignAuth />} />
        <Route path="providers/eideasy/callback/signing" element={<EidEasyCallback type="signing" />} />
        <Route path="providers/eideasy/callback/verification" element={<EidEasyCallback type="verification" />} />
        <Route path="providers/kir/mszafir/callback" element={<OperationCompletedCallback type='signing' provider='Mszafir' />} />
        {/*<Route path="*" element={<NotFound />} />*/}
      </Route>
    </Routes>
  </BrowserRouter>
);

const Providers: FC<PropsWithChildren> = ({ children }) => (
  <ThemeContextProvider>
    <LanguageContextProvider>
      <LocalizationProvider>
        <ConfigurationProvider>
          <UserContextProvider>
            <LayoutContextProvider>{children}</LayoutContextProvider>
          </UserContextProvider>
        </ConfigurationProvider>
      </LocalizationProvider>
    </LanguageContextProvider>
  </ThemeContextProvider>
);

export const App = () => {
  return (
    <Providers>
      <Routing />
    </Providers>
  );
};
