import { useEffect, useState } from 'react';
import { useLanguage } from 'contexts';
import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { z } from 'zod';
import { decode } from 'js-base64';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { ThemeSwitch } from 'components/Common/ThemeSwitch';
import { CheckIcon, InperlyLogoSloganColor } from 'shared-fe-components/src/common/Icons';
import { useSearchParams } from 'react-router-dom';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { acceptVerification } from 'shared-fe-components/src/api/providers/eidEasy';
import './EidEasyCallback.scss';

interface Props {
  type: 'signing' | 'verification';
}

export const EidEasyCallback = ({ type }: Props) => {
  const { setCanChangeLanguage } = useLanguage();

  useEffect(() => {
    setCanChangeLanguage(true);
  }, [setCanChangeLanguage]);

  return (
    <div className="eideasy-callback">
      <div className="eideasy-callback__header">
        <InperlyLogoSloganColor alt="Inperly Logo" className="eideasy-callback__header-logo" />
        <div className="eideasy-callback__header-middle" />
        <LanguageDropdown />
        <ThemeSwitch />
      </div>
      <div className="eideasy-callback__content">
        {type === 'signing' && <CallbackSuccessTexts />}
        {type === 'verification' && <VerificationCallback />}
      </div>
    </div>
  );
};

const VerificationCallback = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const [lastEideasyCallback, setLastEideasyCallback] = usePersistentStorage('last-eideasy-callback-id');

  useEffect(() => {
    (async () => {
      try {
        const code = searchParams.get('code');
        const state = searchParams.get('state')?.split('_')[1];
        if (!state || !code) {
          throw new Error('invalid search params');
        }
        const stateShape = z.object({
          VerificationId: z.string(),
          OperationId: z.string(),
          ClientId: z.string(),
          SessionId: z.string(),
          BackendId: z.string(),
        });
        // we want zod to throw an error if something's not right
        // that's why we're not using checkContract here
        const challenge = stateShape.parse(JSON.parse(decode(state)));

        if (lastEideasyCallback !== challenge.OperationId) {
          await acceptVerification({ code, challenge });
          setLastEideasyCallback(challenge.OperationId);
        }
        setLoading(false);
      } catch (error) {
        // errors here are rather caused by user trying to exploit us, so no error screens for them!
      }
    })();
  }, [searchParams]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <CallbackSuccessTexts />;
};

const CallbackSuccessTexts = () => (
  <>
    <CheckIcon className="eideasy-callback__check-icon" />
    <h1>{t({ id: 'Providers.EidEasy.Callback.Header' })}</h1>
    <Text align="center">{t({ id: 'Providers.EidEasy.Callback.Text' })}</Text>
  </>
);
