import * as client from '../client';

export const enroll = async (request) =>
  await client.post('providers/facetec/enrollment-3d', request, undefined, -1);

export const matchFaceWithDocument = async (request) =>
  await client.post('providers/facetec/match-3d-2d-idscan', request, undefined, -1);

export const endSession = async (request) =>
  await client.postRaw('providers/facetec/end-session', request, undefined, -1);
