import { useEffect, useMemo, useState } from 'react';
import { Text } from '@fluentui/react-components';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import * as api from 'shared-fe-components/src/api';
import { t } from '@lingui/macro';
import { EidEasyProvider, Providers } from './Providers';
import { useProviderSelectionContext } from '.';

export const ProvidersList = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [providersCountryResponse, setProvidersCountryResponse] = useState<any>({});
  const [providersForSession, setProvidersForSession] = useState<any>([]);

  const { country } = useProviderSelectionContext();

  useEffect(() => {
    (async () => {
      const providersCountrySigning = await api.configurationProviders.getProvidersCountryVerification();
      setProvidersCountryResponse(providersCountrySigning);

      const sessionProviders = await api.verification.getAvailableProviders();
      setProvidersForSession(sessionProviders);

      setLoading(false);
    })();
  }, []);

  const availableProviders = useMemo(() => {
    let providersInCountry = providersCountryResponse?.countries?.[country] || [];

    const worldwideProviders = providersCountryResponse?.countries?.['WORLD'];
    if (worldwideProviders) {
      providersInCountry = providersInCountry.concat(worldwideProviders);
    }

    if (providersInCountry) {
      const enabledProviders = providersInCountry.filter((provider: string) => {
        if (provider.startsWith('EidEasy:')) {
          return providersForSession.includes('EidEasy');
        }
        return providersForSession.includes(provider);
      });

      return enabledProviders;
    }

    return [];
  }, [providersCountryResponse, providersForSession, country]);

  const providersCount = useMemo(() => Object.values(availableProviders).length, [availableProviders]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <Text className="provider-selection__available-providers">
          {t({ id: 'ProviderSelection.Signing.ProvidersCount', values: { providersCount: providersCount } })}
        </Text>
      </div>
      <div>
        {availableProviders.map((provider: string) => {
          if (provider in Providers) {
            const RenderProvider = Providers[provider];
            return <RenderProvider key={provider} />;
          } else if (provider.startsWith('EidEasy:')) {
            const name = providersCountryResponse?.names?.[provider] ?? provider;
            return <EidEasyProvider key={provider} {...{ provider, name }} />;
          }
          return null;
        })}
      </div>
    </>
  );
};
