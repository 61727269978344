import { Button as FluentButton, Spinner } from '@fluentui/react-components';
import './Button.scss';

type FluentButtonType = typeof FluentButton;
interface Props extends FluentButtonType {
  loading?: boolean;
  primary?: boolean;
  outline?: boolean;
  inverted?: boolean;
  text?: boolean;
}

const getButtonAppearance = ({ primary, outline, text }: Pick<Props, 'primary' | 'outline' | 'text'>) => {
  if (primary) return 'primary';
  if (outline) return 'outline';
  if (text) return 'transparent';
};

export const Button = ({
  loading = false,
  primary = false,
  outline = false,
  inverted = false,
  text = false,
  children,
  ...rest
}: Props) => {
  const props = {
    appearance: getButtonAppearance({ primary, outline, text }),
    ...rest,
    className: `migrbutton ${inverted ? 'migrbutton--inverted' : ''} ${rest?.className ?? ''}`,
  };

  const spinnerAppearance = primary ? 'inverted' : 'primary';

  return (
    <FluentButton {...props}>
      {loading && <Spinner appearance={spinnerAppearance} size="tiny" style={{ marginRight: 5 }} />}
      {children}
    </FluentButton>
  );
};
