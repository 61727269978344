import { useEffect, useState } from 'react';
import { Intro, Record } from './';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import * as api from 'shared-fe-components/src/api/index';
import { useVerifyTaskContext } from '../VerifyTaskContext';

interface Props {
  onBack: () => void;
  onContinue: () => void;
}

export const RecordVerificationVideo = ({ onBack, onContinue }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [step, setStep] = useState<'Intro' | 'Record'>('Intro');
  const [videoDuration, setVideoDuration] = useState<number>(5);
  const [administrator, setAdministrator] = useState<string>('');
  const { sessionId, verificationVideoDetails, setVerificationVideoDetails } = useVerifyTaskContext();

  useEffect(() => {
    (async () => {
      if (verificationVideoDetails.skipRecording) {
        onContinue();
        return;
      }
      const gdprResp = api.gdpr.get();
      const verificationSession = api.verification.getVerificationSession(sessionId);
      await Promise.all([gdprResp, verificationSession]);
      if ((await verificationSession)?.verificationVideoOptions?.required !== true) {
        setVerificationVideoDetails({
          ...verificationVideoDetails,
          skipRecording: true,
        });
        onContinue();
        return;
      }
      setAdministrator((await gdprResp).adminName);
      setVideoDuration((await verificationSession)?.verificationVideoOptions?.videoDuration);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  switch (step) {
    case 'Intro':
      return (
        <Intro
          onBack={onBack}
          onContinue={() => setStep('Record')}
          videoDuration={videoDuration}
          administrator={administrator}
        />
      );
    case 'Record':
      return <Record onBack={() => setStep('Intro')} onContinue={onContinue} videoDuration={videoDuration} />;
    default:
      return <></>;
  }
};
