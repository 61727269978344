import { createContext, useContext, useEffect, useState } from 'react';
import * as api from 'shared-fe-components/src/api';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { UserObject, UserProviderProps, User, isUser } from './models';
import { useConfigurationContext } from 'contexts';

const Context = createContext<UserObject | null>(null);

export const UserContextProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [customToken, setCustomToken] = useState<string | null>(null);
  const [userFeatures, setUserFeatures] = useState<{ [key: string]: boolean }>({});
  const [availableFeatures, setAvailableFeatures] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { features } = useConfigurationContext();

  useEffect(() => {
    const token: string | null = new URLSearchParams(window.location.search).get('token');
    setCustomToken(token);

    if (token) {
      (async () => {
        const user = await api.user.getCurrent();

        if (isUser(user)) {
          setUser(user);
        }

        const userFeatures = await api.user.getFeatures();
        setUserFeatures(userFeatures);

        setIsLoading(false);
      })();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (features) {
      // features contains global state of features - if they are enabled in the app or not, for all the users.
      // userFeatures, on the other hand, contains state of features for currently logged in user - some of them may be unavailable due to user's subscription plan, or disabled by admin.
      // if feature is enabled globally, it should be available if user has access to it. if feature is disabled globally, it won't be available, no matter what plan or config the user has.
      const result = { ...userFeatures };
      for (const featureName in features) {
        if (result[featureName] !== undefined) {
          result[featureName] &&= features[featureName];
        } else {
          result[featureName] = features[featureName];
        }
      }

      setAvailableFeatures(result);
    }
  }, [features, userFeatures]);

  return (
    <Context.Provider value={{ user, customToken, features: availableFeatures, hasLicense: !!user?.license.isActive }}>
      {isLoading ? <LoadingScreen /> : <>{children}</>}
    </Context.Provider>
  );
};

export const useUserContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Lack of UserProvider in components tree');
  }
  return ctx;
};
