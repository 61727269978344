var FaceTecStrings = {
  "FaceTec_action_ok": "OK",
  "FaceTec_action_im_ready": "ESTOU PRONTO",
  "FaceTec_action_try_again": "TENTE NOVAMENTE",
  "FaceTec_action_continue": "CONTINUAR",
  "FaceTec_action_take_photo": "TIRAR FOTO",
  "FaceTec_action_accept_photo": "ACEITAR",
  "FaceTec_action_retake_photo": "VOLTAR",
  "FaceTec_action_confirm": "CONFIRMAR INFORMAÇÕES",

  "FaceTec_accessibility_cancel_button": "Cancelar",
  "FaceTec_accessibility_tap_guidance": "Toque duas vezes em qualquer lugar na tela para orientação de alinhamento facial.",
  "FaceTec_accessibility_key_down_guidance": "Pressione Enter ou a Barra de Espaço Para Orientação de Alinhamento Facial",

  "FaceTec_accessibility_feedback_move_phone_away": "Rosto Muito Próximo",
  "FaceTec_accessibility_feedback_move_phone_closer": "Rosto Muito Longe",
  "FaceTec_accessibility_feedback_face_too_far_left": "Rosto Muito à Esquerda",
  "FaceTec_accessibility_feedback_face_too_far_right": "Rosto Muito à Direita",
  "FaceTec_accessibility_feedback_face_too_low": "Rosto Muito Baixo",
  "FaceTec_accessibility_feedback_face_too_high": "Rosto Muito Alto",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Rosto Muito Virado Para a Esquerda",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Rosto Muito Virado Para a Direita",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Rosto Apontando Muito Para a Esquerda",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Rosto Apontando Muito Para a Direita",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Rosto Fora da Câmera ou Muito Longe",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Segure o Dispositivo ao Nível dos Olhos",
  "FaceTec_accessibility_feedback_move_away_web": "Câmera Muito Próxima",
  "FaceTec_accessibility_feedback_move_closer_web": "Câmera Muito Longe",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Mover la cámara al nivel de los ojos",

  "FaceTec_presession_frame_your_face": "Enquadre Seu Rosto no Oval",
  "FaceTec_presession_look_straight_ahead": "Olhe Para Frente",
  "FaceTec_presession_hold_steady3": "Mantenha a Posição por: 3",
  "FaceTec_presession_hold_steady2": "Mantenha a Posição por: 2",
  "FaceTec_presession_hold_steady1": "Mantenha a Posição por: 1",
  "FaceTec_presession_eyes_straight_ahead": "Olhe Para Frente",
  "FaceTec_presession_remove_dark_glasses": "Remova os Óculos Escuros",
  "FaceTec_presession_neutral_expression": "Expressão Neutra, Sem Sorriso",
  "FaceTec_presession_conditions_too_bright": "Condições Muito Iluminadas",
  "FaceTec_presession_brighten_your_environment": "Ilumine Seu Ambiente",

  "FaceTec_feedback_center_face": "Centralize Seu Rosto",
  "FaceTec_feedback_face_not_found": "Enquadre Seu Rosto",
  "FaceTec_feedback_move_phone_away": "Afaste-se",
  "FaceTec_feedback_move_away_web": "Afaste-se",
  "FaceTec_feedback_move_phone_closer": "Aproxime-se",
  "FaceTec_feedback_move_web_closer": "Aproxime o Rosto e Preencha o Oval",
  "FaceTec_feedback_move_web_even_closer": "Ainda Mais Perto",
  "FaceTec_feedback_move_phone_to_eye_level": "Mova a Câmera Para o Nível Dos Olhos",
  "FaceTec_feedback_move_to_eye_level_web": "Olhe Direto Para a Câmera",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Olhe Para Frente",
  "FaceTec_feedback_face_not_upright": "Mantenha Sua Cabeça Reta",
  "FaceTec_feedback_face_not_upright_mobile": "Mantenha a Cabeça Reta",
  "FaceTec_feedback_hold_steady": "Mantenha a Posição",
  "FaceTec_feedback_use_even_lighting": "Ilumine o Rosto de Maneira Mais Uniforme",

  "FaceTec_instructions_header_ready_desktop": "Prepare-se Para Sua Selfie em Vídeo",
  "FaceTec_instructions_header_ready_1_mobile": "Prepare-se Para",
  "FaceTec_instructions_header_ready_2_mobile": "Sua Selfie de Vídeo",
  "FaceTec_instructions_message_ready_desktop": "Enquadre Seu Rosto No Oval, Pressione Estou Pronto e Chegue Mais Perto",
  "FaceTec_instructions_message_ready_1_mobile": "Enquadre Seu Rosto No Oval,",
  "FaceTec_instructions_message_ready_2_mobile": "Pressione \"Estou Pronto\", e Aproxime-se",

  "FaceTec_retry_header": "Vamos Tentar de Novo",
  "FaceTec_retry_subheader_message": "Faça Uma Selfie de Vídeo Melhor",
  "FaceTec_retry_your_image_label": "Sua selfie",
  "FaceTec_retry_ideal_image_label": "Pose Ideal",
  "FaceTec_retry_instruction_message_1": "Por favor, Use Pose e Iluminação Ideal",
  "FaceTec_retry_instruction_message_2": "Expressão Neutra, Sem Sorriso",
  "FaceTec_retry_instruction_message_3": "Muito Embaçado, Limpe a câmera",

  "FaceTec_camera_feed_issue_header": "Problema Protegendo O Feed Da Câmera",
  "FaceTec_camera_feed_issue_header_mobile": "Problema protegendo<br/>O Feed Da Câmera",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Este aplicativo bloqueia configurações suspeitas de webcam.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Saiba mais aqui.</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Este sistema não pode ser verificado devido ao seguinte:",
  "FaceTec_camera_feed_issue_table_header_1": "Possível Problema",
  "FaceTec_camera_feed_issue_table_header_2": "Corrigir",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Permissões de câmera não lembradas no Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Marque Lembrar Permissões.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Câmera já em uso por outro App..",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Feche o outro Aplicativo.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "Um aplicativo 3rd-Party está modificando o vídeo.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Feche / desinstale o outro aplicativo.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Hardware não capaz de ser protegido.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Use um dispositivo diferente.",
  "FaceTec_camera_feed_issue_subtable_message": "Este aplicativo bloqueia configurações suspeitas de webcam. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Saiba mais aqui.</a>.",
  "FaceTec_camera_feed_issue_action": "TIRAR",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

  "FaceTec_camera_permission_header": "Ative a Câmera",
  "FaceTec_camera_permission_message": "Permissões de câmera desativadas.<br/>Verifique as configurações do seu sistema operacional e do navegador",

  "FaceTec_enter_fullscreen_header": "Modo Selfie Em Tela Cheia",
  "FaceTec_enter_fullscreen_message": "Antes de começarmos, clique no botão abaixo para abrir o modo de tela cheia.",
  "FaceTec_enter_fullscreen_action": "ABRIR TELA CHEIA",

  "FaceTec_initializing_camera": "Protegendo o Feed da Câmera",

  "FaceTec_idscan_type_selection_header": "Prepare-se Para Escanear<br/>Seu Documento de Identificação",
  "FaceTec_idscan_capture_id_front_instruction_message": "Mostre a Frente da Identificação",
  "FaceTec_idscan_capture_id_back_instruction_message": "Mostre o Verso da Identificação",
  "FaceTec_idscan_review_id_front_instruction_message": "Confirme Se Nítida e Legível",
  "FaceTec_idscan_review_id_back_instruction_message": "Confirme Se Nítida e Legível",
  "FaceTec_idscan_additional_review_message": "Revisão Adicional<br/>Necessária",
  "FaceTec_idscan_ocr_confirmation_main_header": "Revise e Confirme",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Role Para Baixo",
  "FaceTec_idscan_feedback_flip_id_to_back_message": "Mostre o Verso da Identificação",

  "FaceTec_result_success_message": "Sucesso",
  "FaceTec_result_facescan_upload_message": "Carregando<br/>3D FaceScan<br/>Criptografado",
  "FaceTec_result_idscan_upload_message": "Carregando<br/>Escaneamento de Identificação<br/>Criptografado",
  "FaceTec_result_idscan_unsuccess_message": "A Foto da Identificação<br/>Não Correspondeu<br/>Ao Rosto do Usuário",
  "FaceTec_result_idscan_success_front_side_message": "Escaneamento da Identificação Concluído",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Frente da Identificação<br/>Escaneada",
  "FaceTec_result_idscan_success_back_side_message": "Escaneamento da Identificação Concluído",
  "FaceTec_result_idscan_success_passport_message": "Escaneamento de Passaporte Concluído",
  "FaceTec_result_idscan_success_user_confirmation_message": "Escaneamento da Identificação com Foto<br/>Concluído",
  "FaceTec_result_idscan_success_additional_review_message": "Captura de Foto da Identificação<br/>Concluída",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "O Rosto Não Correspondeu<br/>Muito Bem",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "O Documento de Identificação<br/>Não Está Totalmente Visível",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "O Texto da Identificação Não Está Legível",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Tipo de Identificação Não Suportado<br/>Use Uma Identificação Diferente",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Falha ao Escanear o Código de Barras<br/>Por Favor, Tente Novamente",
};

module.exports = FaceTecStrings;
