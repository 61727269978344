var FaceTecStrings = {
  "FaceTec_action_ok": "OK",
  "FaceTec_action_im_ready": "JESTEM GOTOWY",
  "FaceTec_action_try_again": "SPRÓBUJ PONOWNIE",
  "FaceTec_action_continue": "KONTYNUUJ",
  "FaceTec_action_take_photo": "ZRÓB ZDJĘCIE",
  "FaceTec_action_accept_photo": "ZAAKCEPTUJ",
  "FaceTec_action_retake_photo": "ZRÓB PONOWNIE",
  "FaceTec_action_confirm": "POTWIERDŹ INFORMACJE",

  "FaceTec_accessibility_cancel_button": "Anuluj",
  "FaceTec_accessibility_tap_guidance": "Dotknij dwukrotnie ekranu, aby uzyskać wskazówki dotyczące ustawienia twarzy",
  "FaceTec_accessibility_key_down_guidance": "Naciśnij Enter lub spację, aby uzyskać wskazówki dotyczące ustawienia twarzy",

  "FaceTec_accessibility_feedback_move_phone_away": "Kamera zbyt blisko",
  "FaceTec_accessibility_feedback_move_phone_closer": "Kamera zbyt daleko",
  "FaceTec_accessibility_feedback_face_too_far_left": "Twarz za bardzo z lewej",
  "FaceTec_accessibility_feedback_face_too_far_right": "Twarz za bardzo z prawej",
  "FaceTec_accessibility_feedback_face_too_low": "Twarz za nisko",
  "FaceTec_accessibility_feedback_face_too_high": "Twarz za wysoko",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Twarz obrócona zbyt bardzo w lewo",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Twarz obrócona zbyt daleko w prawo",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Twarz skierowana zbyt daleko w lewo",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Twarz skierowana zbyt daleko w prawo",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Twarz niewidoczna w kamerze",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Trzymaj urządzenie na wysokości oczu",
  "FaceTec_accessibility_feedback_move_away_web": "Kamera zbyt blisko",
  "FaceTec_accessibility_feedback_move_closer_web": "Kamera zbyt daleko",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Przesuń kamerę do poziomu oczu",

  "FaceTec_presession_frame_your_face": "Wykadruj swoją twarz w owalu",
  "FaceTec_presession_look_straight_ahead": "Patrz prosto przed siebie",
  "FaceTec_presession_hold_steady3": "Trzymaj nieruchomo przez: 3",
  "FaceTec_presession_hold_steady2": "Trzymaj nieruchomo przez: 2",
  "FaceTec_presession_hold_steady1": "Trzymaj nieruchomo przez: 1",
  "FaceTec_presession_eyes_straight_ahead": "Patrz prosto przed siebie",
  "FaceTec_presession_remove_dark_glasses": "Zdejmij ciemne okulary",
  "FaceTec_presession_neutral_expression": "Neutralny wyraz twarzy, bez uśmiechu",
  "FaceTec_presession_conditions_too_bright": "Otoczenie zbyt jasne",
  "FaceTec_presession_brighten_your_environment": "Zapewnij lepsze oświetlenie",

  "FaceTec_feedback_center_face": "Wyśrodkuj swoją twarz",
  "FaceTec_feedback_face_not_found": "Wykadruj swoją twarz",
  "FaceTec_feedback_move_phone_away": "Oddal się",
  "FaceTec_feedback_move_away_web": "Oddal się",
  "FaceTec_feedback_move_phone_closer": "Przybliż się",
  "FaceTec_feedback_move_web_closer": "Przybliż twarz aby wypełnić owal",
  "FaceTec_feedback_move_web_even_closer": "Jeszcze bliżej",
  "FaceTec_feedback_move_phone_to_eye_level": "Przesuń telefon na wysokość oczu",
  "FaceTec_feedback_move_to_eye_level_web": "Patrz prosto w kamerę",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Patrz prosto przed siebie",
  "FaceTec_feedback_face_not_upright": "Trzymaj głowę prosto",
  "FaceTec_feedback_face_not_upright_mobile": "Trzymaj głowę prosto",
  "FaceTec_feedback_hold_steady": "Nie ruszaj się",
  "FaceTec_feedback_use_even_lighting": "Oświetl twarz bardziej równimiernie",

  "FaceTec_instructions_header_ready_desktop": "Przygotuj się do selfie wideo",
  "FaceTec_instructions_header_ready_1_mobile": "Przygotuj się do",
  "FaceTec_instructions_header_ready_2_mobile": "selfie wideo",
  "FaceTec_instructions_message_ready_desktop": "Wykadruj swoją twarz w owalu, naciśnij przycisk Jestem gotowy i przybliż się",
  "FaceTec_instructions_message_ready_1_mobile": "Wykadruj swoją twarz w owalu,",
  "FaceTec_instructions_message_ready_2_mobile": "naciśnij przycisk Jestem gotowy i przybliż się",

  "FaceTec_retry_header": "Spróbujmy ponownie",
  "FaceTec_retry_subheader_message": "Potrzebujemy wyraźniejszego selfie wideo",
  "FaceTec_retry_your_image_label": "Twoje selfie",
  "FaceTec_retry_ideal_image_label": "Idealna pozycja",
  "FaceTec_retry_instruction_message_1": "Bez odblasków i silnego światła",
  "FaceTec_retry_instruction_message_2": "Neutralny wyraz twarzy, bez uśmiechu",
  "FaceTec_retry_instruction_message_3": "Zbyt niewyraźne, przetrzyj kamerę",

  "FaceTec_camera_feed_issue_header": "Problem z zabezpieczeniem obrazu z kamery",
  "FaceTec_camera_feed_issue_header_mobile": "Problem z zabezpieczeniem<br/>obrazu z kamery",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Ta aplikacja blokuje podejrzane konfiguracje kamer internetowych.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; pointer-events:all;'>Dowiedz się więcej</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Ten system nie może zostać zweryfikowany z następujących powodów:",
  "FaceTec_camera_feed_issue_table_header_1": "Możliwy problem",
  "FaceTec_camera_feed_issue_table_header_2": "Rozwiązanie",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Uprawnienia aparatu nie są zapamiętywane w przeglądarce Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Sprawdź zapamiętywanie uprawnień.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Kamera jest już używana przez inną aplikację.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Zamknij inną aplikację.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "Aplikacja innej firmy modyfikuje wideo.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Zamknij/odinstaluj inną aplikację.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Sprzętu nie da się zabezpieczyć.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Użyj innego urządzenia.",
  "FaceTec_camera_feed_issue_subtable_message": "Ta aplikacja blokuje podejrzane konfiguracje kamer internetowych. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; pointer-events:all;'>Dowiedz się więcej</a>.",
  "FaceTec_camera_feed_issue_action": "SPRÓBUJ PONOWNIE MIMO TO",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

  "FaceTec_camera_permission_header": "Włącz kamerę",
  "FaceTec_camera_permission_message": "Dostęp do kamery wyłączony.<br/>Sprawdź ustawienia systemu operacyjnego i przeglądarki.",

  "FaceTec_enter_fullscreen_header": "Wideo selfie w trybie pełnoekranowym",
  "FaceTec_enter_fullscreen_message": "Zanim zaczniemy, kliknij poniższy przycisk, aby otworzyć tryb pełnoekranowy.",
  "FaceTec_enter_fullscreen_action": "TRYB PEŁNOEKRANOWY",

  "FaceTec_initializing_camera": "Zabezpieczanie obrazu z kamery",

  "FaceTec_idscan_type_selection_header": "Przygotuj się do skanowania<br/>Twojego dokumentu",
  "FaceTec_idscan_capture_id_front_instruction_message": "Pokaż przód dokumentu",
  "FaceTec_idscan_capture_id_back_instruction_message": "Pokaż tył dokumentu",
  "FaceTec_idscan_review_id_front_instruction_message": "Potwierdź, że zdjęcie jest wyraźne i czytelne",
  "FaceTec_idscan_review_id_back_instruction_message": "Potwierdź, że zdjęcie jest wyraźne i czytelne",
  "FaceTec_idscan_additional_review_message": "Dodatkowe sprawdzenie<br/>wymagane",
  "FaceTec_idscan_ocr_confirmation_main_header": "Sprawdź i potwierdź",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Przewiń w dół",

  "FaceTec_result_success_message": "Sukces!",
  "FaceTec_result_facescan_upload_message": "Przesyłanie<br/>zaszyfrowanych danych<br/>3D FaceScan",
  "FaceTec_result_idscan_upload_message": "Przesyłanie<br/>zaszyfrowanego<br/>dokumentu",
  "FaceTec_result_idscan_unsuccess_message": "Zdjęcie z dokumentu<br/>nie pasuje do<br/>twarzy użytkownika",
  "FaceTec_result_idscan_success_front_side_message": "Skanowanie dokumentu zakończone",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Przód dokumentu<br/>zeskanowany",
  "FaceTec_result_idscan_success_back_side_message": "Skanowanie dokumentu zakończone",
  "FaceTec_result_idscan_success_passport_message": "Skanowanie paszportu zakończone",
  "FaceTec_result_idscan_success_user_confirmation_message": "Skanowanie dokumentu<br/>zakończone",
  "FaceTec_result_idscan_success_additional_review_message": "Skanowanie dokumentu<br/>zakończone",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Twarz nie pasuje<br/>w wystarczającym stopniu",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Dokument<br/>nie jest widoczny w całości",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Nieczytelny tekst dokumentu",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Nieobsługiwany typ dokumentu<br/>Użyj innego dokumentu",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Nie udało się zeskanować kodu kreskowego<br/>Spróbuj ponownie",
};

module.exports = FaceTecStrings;
