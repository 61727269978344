import { CoffeeIcon } from '../Icons';
import { t } from '@lingui/macro';
import './CoffeeScreen.scss';

export const SignatureLockedScreen = () => {
  return (
    <div className="coffee-screen" role="alert">
      <CoffeeIcon className="coffee-screen__icon" />
      <div>{t({ id: 'StatusScreen.CoffeeTime' })}</div>
      <div>{t({ id: 'StatusScreen.SignatureLocked.Text.1' })}</div>
      <div>{t({ id: 'StatusScreen.SignatureLocked.Text.2' })}</div>
    </div>
  );
};
