import { Text, Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CountryCallingCodeDropdown, NationalityDropdown, Input } from '../../common/ReactHookForm';
import { CountryFlagsPreloader } from '../../common/CountryFlags/CountryFlagsPreloader';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { IdNowFormTextFieldProps, IdNowFormProps, IdNowFormData } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { InputFormWithError } from 'shared-fe-components/src/common/WithError';
import './IdNowForm.scss';
dayjs.extend(customParseFormat);

const IdNowFormFieldTitle = ({ title }: { title: string }) => (
  <div key={`title-for-${title}`} className="idnow-form__field-title">
    <Text>{t({ id: `Providers.IdNow.Form.Field.${title}.Title` })}</Text>
  </div>
);

const IdNowFormTextField = ({
  fieldName,
  additionalRules = {},
  className = '',
  checkRequired = true,
  errorMsg,
}: IdNowFormTextFieldProps) => {
  const formContext = useFormContext();
  const {
    control,
    formState: { errors },
  } = formContext;

  const errorMessage = errorMsg || errors?.[fieldName]?.message;

  const inputProps = {
    fluid: true,
    placeholder: t({ id: `Providers.IdNow.Form.Field.${fieldName}.Placeholder` }),
    error: !!errorMessage,
    appearance: 'filled-darker',
    rules: {
      required: {
        value: checkRequired,
        message: t({ id: 'Form.Errors.RequiredField' }),
      },
      ...additionalRules,
    },
  };

  return (
    <div className={`idnow-form__input-field ${className}`}>
      <InputFormWithError {...inputProps} control={control} fieldName={fieldName} errorMessage={errorMessage} />
    </div>
  );
};

const IdNowFormMobilePhoneField = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldNameCode = 'mobilephoneCode';
  const fieldNameNumber = 'mobilephoneNumber';

  const errorMessageCode = errors?.[fieldNameCode]?.message;
  const errorMessageNumber = errors?.[fieldNameNumber]?.message;

  const codeRules = {
    required: {
      value: true,
      message: t({ id: 'Form.Errors.RequiredField' }),
    },
  };

  const numberRules = {
    pattern: {
      value: /^\d+$/g,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.OnlyDigits' }),
    },
    maxLength: {
      value: 15,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.MaxLength' }),
    },
    minLength: {
      value: 5,
      message: t({ id: 'Providers.IdNow.Form.Field.mobilephoneNumber.Error.MinLength' }),
    },
  };

  return (
    <div className="idnow-form__input-field">
      <div className="idnow-form__mobilephone-fields">
        <CountryCallingCodeDropdown
          errorMessage={errorMessageCode}
          menuPlacement="auto"
          fieldName={fieldNameCode}
          className="idnow-form__mobilephone-fields-code"
          rules={codeRules}
          minMenuHeight={400}
        />
        <IdNowFormTextField
          fieldName={fieldNameNumber}
          className="idnow-form__mobilephone-fields-number"
          additionalRules={numberRules}
          errorMsg={errorMessageNumber}
        />
      </div>
    </div>
  );
};

const IdNowFormNationalityField = () => {
  const {
    formState: { errors },
  } = useFormContext();
  const fieldName = 'nationality';

  const errorMessage = errors?.[fieldName]?.message;

  const rules = {
    required: {
      value: true,
      message: t({ id: 'Form.Errors.RequiredField' }),
    },
  };

  return (
    <div className="idnow-form__input-field">
      <NationalityDropdown
        errorMessage={errorMessage}
        menuPlacement="bottom"
        fieldName={fieldName}
        className="idnow-form__nationality-field"
        rules={rules}
        minMenuHeight={400}
      />
    </div>
  );
};

export const IdNowForm = ({
  formData,
  onBack,
  onCancel,
  onContinue,
  userData,
  processType = 'Sign',
}: IdNowFormProps) => {
  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      firstname: '',
      lastname: '',
      nationality: '',
      mobilephoneCode: null,
      mobilephoneNumber: '',
      email: '',
      ...formData,
    },
  });
  const { handleSubmit, getValues, setValue } = formMethods;
  // RHF has a caching system, use reset() in useEffect() to update values by props
  // if ever needed

  useEffect(() => {
    const firstname = getValues('firstname');
    const lastname = getValues('lastname');
    const email = getValues('email');
    if (userData) {
      if (!firstname) setValue('firstname', userData?.givenName ?? '');
      if (!lastname) setValue('lastname', userData?.surname ?? '');
      if (!email) setValue('email', userData?.mail ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailFieldProps = {
    fieldName: 'email',
    additionalRules: {
      pattern: {
        value: /\S+@\S+/,
        message: t({ id: 'Form.Errors.IncorrectEmail' }),
      },
    },
  };

  const prepareData = (data: IdNowFormData) => {
    const newData = data;

    const callingCode = data.mobilephoneCode?.split('-')?.[1];
    const mobilephoneNumber = data.mobilephoneNumber?.replaceAll(/\D/g, '');
    newData.mobilephone = `+${callingCode}${mobilephoneNumber}`;

    return newData;
  };

  const onGoBack = () => {
    onBack(prepareData(getValues()));
  };

  const onFormSubmit = (data: any) => {
    onContinue(prepareData(data));
  };

  const onConfirm = handleSubmit(onFormSubmit);

  const subheader = t({ id: `Providers.IdNow.Form.Subheader.${processType === 'Sign' ? 'Sign' : 'Verify'}` });

  return (
    <FormProvider {...formMethods}>
      <WizardBox className="idnow-form">
        <div className="idnow-form__header">
          <Text as="h1" weight="bold" block>
            {t({ id: 'Providers.IdNow.Form.Header' })}
          </Text>
          <Text as="p" className="idnow-form__subheader" block>
            {subheader}
          </Text>
        </div>

        <div>
          <CountryFlagsPreloader />
          <div className="idnow-form__form-container">
            <div className="idnow-form__names">
              <IdNowFormFieldTitle title="firstname" />
              <IdNowFormFieldTitle title="lastname" />

              <IdNowFormTextField fieldName="firstname" />
              <IdNowFormTextField fieldName="lastname" />
            </div>

            <div>
              <IdNowFormFieldTitle title="email" />
              <IdNowFormTextField {...emailFieldProps} />
            </div>

            <div>
              <IdNowFormFieldTitle title="nationality" />
              <IdNowFormNationalityField />
            </div>

            <div>
              <IdNowFormFieldTitle title="mobilephone" />
              <IdNowFormMobilePhoneField />
            </div>
          </div>
        </div>

        <div>
          <div>
            <ButtonBack onClick={onGoBack} />
          </div>
          <div>
            <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
            <Button appearance="primary" onClick={onConfirm}>
              {t({ id: 'Common.Proceed' })}
            </Button>
          </div>
        </div>
      </WizardBox>
    </FormProvider>
  );
};
