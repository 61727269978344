import * as client from "./client";

export const getProvidersCountrySigning = async () =>
  await client.get("configuration/providers/countries/signing", {
    retry: {
      limit: 10,
    },
  });

export const getProvidersCountryVerification = async () =>
  await client.get("configuration/providers/countries/verification", {
    retry: {
      limit: 10,
    },
  });
