// Welcome to the skeleton code for performing the 3D Liveness + Photo ID Match process.
// This file removes ALL comment annotations, as well as networking calls.

// NOTE: This example DOES NOT perform a Liveness Check.  To perform the Liveness Check, you need to actually make an API call.
// Please see the LivenessCheckProcessor file for a complete demonstration using the FaceTec Testing API.

import { FaceTecSDK } from './core-sdk/FaceTecSDK.js/FaceTecSDK';
import {
  FaceTecSessionResult,
  FaceTecFaceScanResultCallback,
  FaceTecIDScanResult,
  FaceTecIDScanResultCallback,
  FaceTecIDScanProcessor,
} from './core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { faceTec as apiClient, HTTPError } from 'shared-fe-components/src/api';

export interface FaceTecResult {
  isSessionCorrectly: boolean;
  isIdScanCorrectly: boolean;
  latestRequestErrorCode: number | null
}

export class PhotoIdMatchProcessor implements FaceTecIDScanProcessor {
  sessionId: string;
  enrollmentIdentifier: string;
  onCompletelyDone: (faceTecResult: FaceTecResult) => void;

  externalDatabaseRefID: string | null = null;

  latestSessionResult: FaceTecSessionResult | null;
  latestIdScanResult: FaceTecIDScanResult | null;

  latestRequestErrorCode: number | null = null;

  constructor(
    sessionToken: string,
    enrollmentIdentifier: string,
    isInIframe: boolean,
    onCompletelyDone: (faceTecResult: FaceTecResult) => void
  ) {
    this.sessionId = sessionToken;
    this.enrollmentIdentifier = enrollmentIdentifier;
    this.onCompletelyDone = onCompletelyDone;

    this.latestSessionResult = null;
    this.latestIdScanResult = null;

    if (!isInIframe) {
      new FaceTecSDK.FaceTecSession(this, sessionToken);
    } else {
      new FaceTecSDK.FaceTecSessionFromIFrame(this, sessionToken);
    }
  }

  public async processSessionResultWhileFaceTecSDKWaits(
    sessionResult: FaceTecSessionResult,
    faceScanResultCallback: FaceTecFaceScanResultCallback
  ) {
    this.latestSessionResult = sessionResult;

    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      faceScanResultCallback.cancel();
      return;
    }

    var parameters = {
      faceScan: sessionResult.faceScan,
      auditTrailImage: sessionResult.auditTrail[0],
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      sessionId: this.sessionId,
      sessionToken: this.sessionId,
      faceTecSessionId: sessionResult.sessionId,
      enrollmentIdentifier: this.enrollmentIdentifier, // enrollmentIdentifier that you pick, or could be created in your server code
    };

    // Call Your API Here and handle results here.
    try {
      const response = await apiClient.enroll(parameters);
      // if (response.wasProcessed === true) {
      this.externalDatabaseRefID = response.externalDatabaseRefID;
      faceScanResultCallback.proceedToNextStep(response.scanResultBlob);
      // }
    } catch (error: any) {
      this.latestRequestErrorCode = error instanceof HTTPError ? error.response.status : -1;
      faceScanResultCallback.cancel();
    }
  }

  public async processIDScanResultWhileFaceTecSDKWaits(
    idScanResult: FaceTecIDScanResult,
    idScanResultCallback: FaceTecIDScanResultCallback
  ) {
    this.latestIdScanResult = idScanResult;

    if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
      idScanResultCallback.cancel();
      return;
    }

    var parameters: { [key: string]: any } = {
      idScan: idScanResult.idScan,
      sessionId: this.sessionId,
      faceTecSessionId: idScanResult.sessionId,
      enrollmentIdentifier: this.enrollmentIdentifier, // enrollmentIdentifier that you pick, or could be created in your server code,
      externalDatabaseRefID: this.externalDatabaseRefID,
    };

    //
    // Sending up front and back images are non-essential, but are useful for auditing purposes, and are required in order for the FaceTec Server Dashboard to render properly.
    //
    if (idScanResult.frontImages && idScanResult.frontImages[0]) {
      parameters.idScanFrontImage = idScanResult.frontImages[0];
    }

    if (idScanResult.backImages && idScanResult.backImages[0]) {
      parameters.idScanBackImage = idScanResult.backImages[0];
    }

    // Call Your API Here and handle results here.
    try {
      const response = await apiClient.matchFaceWithDocument(parameters);
      // if (response.wasProcessed === true) {
      idScanResultCallback.proceedToNextStep(response.scanResultBlob);
      // }
    } catch (error: any) {
      this.latestRequestErrorCode = error instanceof HTTPError ? error.response.status : -1;
      idScanResultCallback.cancel();
    }
  }

  public async onFaceTecSDKCompletelyDone() {
    // Entrypoint where FaceTec SDKs are done and you can proceed.

    let sessionResult = null;
    if (this.latestSessionResult) {
      sessionResult = {
        status: this.latestSessionResult.status,
        statusText: FaceTecSDK.FaceTecSessionStatus[this.latestSessionResult.status],
        isCompletelyDone: this.latestSessionResult.isCompletelyDone,
      };
    }

    let idScanResult = null;
    if (this.latestIdScanResult) {
      idScanResult = {
        status: this.latestIdScanResult.status,
        statusText: FaceTecSDK.FaceTecIDScanStatus[this.latestIdScanResult.status],
        isCompletelyDone: this.latestIdScanResult.isCompletelyDone,
      };
    }

    try {
      await apiClient.endSession({
        sessionId: this.sessionId,
        sessionResult,
        idScanResult,
      });
    } catch (error: any) {
      this.latestRequestErrorCode = error instanceof HTTPError ? error.response.status : -1;
    }

    this.onCompletelyDone({
      isIdScanCorrectly: !!idScanResult?.isCompletelyDone,
      isSessionCorrectly: !!sessionResult?.isCompletelyDone,
      latestRequestErrorCode: this.latestRequestErrorCode
    });
  }
}
