import { DashboardLayoutHeader } from '../../DashboardLayout/DashboardLayoutHeader';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { ThemeSwitch } from 'components/Common/ThemeSwitch';
import { VideoPersonRegular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { Text, Button } from '@fluentui/react-components';
import './Intro.scss';

interface IntroProps {
  denyVerification: () => void;
  startVerification: () => void;
}
export const Intro = ({ denyVerification, startVerification }: IntroProps) => {
  return (
    <WizardBox className="dashboard-verification-header">
      <div className="dashboard-verification-header__title">
        <DashboardLayoutHeader />
        <div className="dashboard-verification-header__options">
          <div className="dashboard-verification-header__language">
            <LanguageDropdown />
          </div>
          <ThemeSwitch />
        </div>
      </div>
      <div className="dashboard-verification-intro--welcome">
        <div className="dashboard-verification-intro__icon">
          <VideoPersonRegular />
        </div>
        <div className="dashboard-verification-intro--content">
          <Text as="h1" weight="bold">
            {t({ id: 'Common.Greeting' })}
          </Text>
          <Text>{t({ id: 'Webportal.Verification.Intro.Instruction.Description' })}</Text>
          <ol>
            <li>
              <Text>{t({ id: 'Webportal.Verification.Intro.Instruction.List.1' })}</Text>
            </li>
            <li>
              <Text>{t({ id: 'Webportal.Verification.Intro.Instruction.List.2' })}</Text>
            </li>
            <li>
              <Text>{t({ id: 'Webportal.Verification.Intro.Instruction.List.3' })}</Text>
            </li>
            <li>
              <Text>{t({ id: 'Webportal.Verification.Intro.Instruction.List.4' })}</Text>
            </li>
          </ol>
          <div className="dashboard-verification-intro--action-wrapper">
            <Button onClick={startVerification} appearance="primary">
              {t({ id: 'Webportal.Verification.Intro.Verify.Button' })}
            </Button>
            <Button onClick={denyVerification}>{t({ id: 'Common.Refuse' })}</Button>
          </div>
        </div>
      </div>
      <div></div>
    </WizardBox>
  );
};
