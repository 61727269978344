import { ReactNode, useEffect, useRef, useState } from 'react';
import { IdNowRequirements, IdNowForm, IdNowDownload } from 'shared-fe-components/src/providers/IdNow';
import { IdNowVerify } from './IdNowVerify';
import { useVerifyTaskContext } from '../VerifyTaskContext';

type Step = 'requirements' | 'form' | 'verify' | 'download';

export const IdNowFlow = () => {
  const [step, setStep] = useState<Step>('requirements');
  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useVerifyTaskContext();

  const formData = useRef({});
  const additionalParameters = useRef({});

  const goToRequirements = () => setStep('requirements');
  const goToDownload = () => setStep('download');
  const goToVerify = () => setStep('verify');
  const goToForm = () => setStep('form');

  useEffect(() => {
    overlay !== null && goToRequirements();
  }, [overlay]);

  const handleFormData = (data: any) => {
    formData.current = data;
    additionalParameters.current = {
      ...additionalParameters.current,
      idNowParameters: { verificationMode: 'Level2', ...formData.current },
    };
  };

  const onBackInForm = (data: any) => {
    handleFormData(data);
    goToRequirements();
  };

  const onContinueInForm = (data: any) => {
    handleFormData(data);
    goToVerify();
  };

  const view: Record<Step, ReactNode> = {
    requirements: (
      <IdNowRequirements onBack={goToProviderSelection} onCancel={onCancel} onContinue={goToForm} onShowPrivacyPolicy={showPrivacyPolicy} />
    ),
    form: (
      <IdNowForm formData={formData.current} onBack={onBackInForm} onCancel={onCancel} onContinue={onContinueInForm} processType="Verify" />
    ),
    verify: (
      <IdNowVerify
        additionalParameters={additionalParameters.current}
        onBack={goToForm}
        onCancel={onCancel}
        onGoToDownload={goToDownload}
      />
    ),
    download: <IdNowDownload onBack={goToVerify} onCancel={onCancel} />,
  };

  return view[step];
};
