import { useEffect, useState } from 'react';
import { EdoIntro } from 'shared-fe-components/src/providers/Edo/EdoIntro';
import { EdoDownload } from 'shared-fe-components/src/providers/Edo/EdoDownload';
import { EdoDocInfo } from 'shared-fe-components/src/providers/Edo/EdoDocInfo';
import { EdoVerify } from './EdoVerify';
import { useVerifyTaskContext } from '../VerifyTaskContext';

export const EdoFlow = () => {
  const [step, setStep] = useState('intro');

  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useVerifyTaskContext();

  const goToIntro = () => {
    setStep('intro');
  };

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  const goToHelp = () => {
    setStep('help');
  };

  const goToDownload = () => {
    setStep('download');
  };

  const goToVerify = () => {
    setStep('verify');
  };

  return (
    <>
      {step === 'intro' && (
        <EdoIntro
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToVerify}
          onHelp={goToHelp}
          onShowDownload={goToDownload}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'verify' && <EdoVerify onBack={goToIntro} onCancel={onCancel} />}
      {step === 'help' && <EdoDocInfo onBack={goToIntro} />}
      {step === 'download' && <EdoDownload onCancel={onCancel} onContinue={goToVerify} onBack={goToIntro} />}
    </>
  );
};
