import { FC, PropsWithChildren, createContext, useContext, useState } from 'react';

export type DashboardSize = 'small' | 'normal';

export interface LayoutContextData {
  dashboardSize: DashboardSize;
  setDashboardSize: (size: DashboardSize) => void;
}

export const LayoutContext = createContext<LayoutContextData | null>(null);

export const LayoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dashboardSize, setDashboardSize] = useState<DashboardSize>('normal');

  return <LayoutContext.Provider value={{ dashboardSize, setDashboardSize }}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => {
  const ctx = useContext(LayoutContext);

  if (!ctx) {
    throw new Error('Lack of LayoutProvider in components tree');
  }

  return ctx;
};
