import { ReactNode, HTMLAttributes } from 'react';
import { WizardBox } from './WizardBox';
import './WizardTintedBox.scss';

interface WizardTintedBoxProps extends HTMLAttributes<HTMLDivElement> {
  color?: string
  hideHeader?: boolean,
  hideFooter?: boolean,
  className?: string,
  children: ReactNode,
  rest?: any
}
export const WizardTintedBox = ({
  color,
  hideHeader = false,
  hideFooter = false,
  className: additionalClassName,
  children,
  ...rest
}: WizardTintedBoxProps) => {
  const boxProps = {
    className:
      `wizard-tinted-box wizard-tinted-box--${color} ` +
      `${hideHeader ? 'wizard-tinted-box--hide-header' : ''} ` +
      `${hideFooter ? 'wizard-tinted-box--hide-footer' : ''} ` +
      `${additionalClassName || ''}`,
    ...rest,
    growContent: hideHeader || hideFooter,
  };
  return <WizardBox {...boxProps}>{children}</WizardBox>;
};
