import { useEffect, useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import { Button } from '@fluentui/react-components';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { useVerifyTaskContext } from '../VerifyTaskContext';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';

import { FaceTecSDK } from 'shared-fe-components/src/providers/FaceTec/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { Config } from 'shared-fe-components/src/providers/FaceTec/Config';
import { PhotoIdMatchProcessor, Strings, OcrStrings } from 'shared-fe-components/src/providers/FaceTec';
import { useConfigurationContext, useLanguage } from 'contexts';
import { Header } from 'shared-fe-components/src/common/Header';

export const FaceTecVerify = ({ additionalParameters, onBack, onCancel }) => {
  const [loading, setLoading] = useState(true);
  const [sessionToken, setSessionToken] = useState(undefined);
  const [enrollmentId, setEnrollmentId] = useState(undefined);
  const [initialized, setInitialized] = useState(false);

  const { verify, getVerificationStatus, onError } = useVerifyTaskContext();
  const { providers } = useConfigurationContext();
  const { language } = useLanguage();

  const startNewVerification = useCallback(async () => {
    setLoading(true);

    const response = await verify({ additionalParameters });
    const token = response.providerResponse.sessionToken;
    if (token) {
      setSessionToken(token);
      setEnrollmentId(response.operationId);
      initializeFaceTec(token);
    }

    setLoading(false);
  }, [verify]);

  useEffect(() => {
    startNewVerification();
  }, [startNewVerification]);

  const initializeFaceTec = (sessionToken) => {
    if (FaceTecSDK.getStatus() === FaceTecSDK.FaceTecSDKStatus.Initialized) {
      setInitialized(true);
      return;
    }

    FaceTecSDK.setResourceDirectory('/providers/facetec/resources');
    FaceTecSDK.setImagesDirectory('/providers/facetec/images');

    const defaultCustomization = Config.retrieveConfigurationWizardCustomization(FaceTecSDK);

    FaceTecSDK.setCustomization(defaultCustomization);

    FaceTecSDK.initializeInProductionMode(
      providers.faceTec.sdkKey,
      Config.DeviceKeyIdentifier,
      Config.PublicFaceScanEncryptionKey,
      (initializedSuccessfully) => {
        if (initializedSuccessfully) {
          if (Strings[language]) {
            FaceTecSDK.configureLocalization(Strings[language]);
          } else {
            console.warn(`Missing FaceTec strings for ${language} language.`);
          }

          if (OcrStrings[language]) {
            FaceTecSDK.configureOCRLocalization(OcrStrings[language]);
          } else {
            console.warn(`Missing FaceTec OCR strings from ${language} language.`);
          }

          setInitialized(true);
        }
      }
    );
  };

  const onCompletelyDoneHandler = async (faceTecResult) => {
    const { latestRequestErrorCode } = faceTecResult;
    if (latestRequestErrorCode !== null) {
      onError({ errorCode: 'TechnicalError' });
      return;
    }

    await getVerificationStatus({});
  };

  useEffect(() => {
    if (initialized && enrollmentId) {
      const isInIframe = false;
      new PhotoIdMatchProcessor(sessionToken, enrollmentId, isInIframe, onCompletelyDoneHandler);
    }
  }, [initialized]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <WizardBox className="facetec" growContent={false} centerContent={true}>
      <div>
        <Header as="h1">{t({ id: 'Verification.VerifyHeader' })}</Header>
      </div>

      <div className="facetec-verify__color-container" style={{ overflow: 'hidden' }}>
        <LoadingScreen />
      </div>

      <div>
        <ButtonBack onClick={onBack} />
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </div>
    </WizardBox>
  );
};
