import ky, { HTTPError } from 'ky';

export { HTTPError } from 'ky';

const apiV1Url = process.env.REACT_APP_INPERLY_API_ENDPOINT + '/api/v1';
const apiV2Url = process.env.REACT_APP_INPERLY_API_ENDPOINT + '/api/v2';
const clientNoVersion = ky.create({
  prefixUrl: process.env.REACT_APP_INPERLY_API_ENDPOINT,
  timeout: 60000,
});
const clientV1 = ky.create({
  prefixUrl: apiV1Url,
  timeout: 120000,
});
const clientV2 = ky.create({
  prefixUrl: apiV2Url,
  timeout: 120000,
});

export const token = async () => {
  // @ts-ignore
  return window.getToken();
};

const getClient = (version: any) => {
  if (version === -1) return clientNoVersion;
  return version === 1 ? clientV1 : clientV2;
};

export interface RequestOptions {
  token?: string | null;
  [key: string]: unknown;
}

const defaultOptions: RequestOptions = { token: null };

export const get = async (url: any, options = defaultOptions as any, apiVersion = 1) => {
  const selectedClient = getClient(apiVersion);
  return await selectedClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${options.token ?? (await token())}`,
        ...(options.additionalHeaders ?? {})
      },
      retry: options.retry,
    })
    .json();
};

export const getSafe = async (url: any, options = defaultOptions as any, apiVersion = 1) => {
  // It's backward compatible get() function
  // It should be replaced by proper handling of HTTP 404 errors for all GET requests
  // but right now it's hard to figure out which should return null and which should throw
  try {
    return await get(url, options, apiVersion);
  } catch (error: any) {
    if (error instanceof HTTPError && error.response.status === 404) {
      return null;
    }

    throw error;
  }
};

export const post = async (url: any, body: Record<string, unknown> = {}, options = defaultOptions, apiVersion = 1) => {
  const selectedClient = getClient(apiVersion);
  const payload = Object.keys(body).length > 0 ? body : undefined;
  return await selectedClient
    .post(url, {
      headers: {
        Authorization: `Bearer ${options.token ?? (await token())}`,
        ...(options.additionalHeaders ?? {})
      },
      json: payload,
    })
    .json();
};

export const postRaw = async (url: any, body = undefined, options = defaultOptions, apiVersion = 1) => {
  const selectedClient = getClient(apiVersion);
  return await selectedClient.post(url, {
    headers: {
      Authorization: `Bearer ${options.token ?? (await token())}`,
    },
    json: body,
  });
};

export const deleteRequest = async (url: any, options = defaultOptions, apiVersion = 1) => {
  const selectedClient = getClient(apiVersion);
  return await selectedClient.delete(url, {
    headers: {
      Authorization: `Bearer ${options.token ?? (await token())}`,
    },
  });
};

export const put = async (url: any, body = undefined, options = defaultOptions, apiVersion = 1) => {
  const selectedClient = getClient(apiVersion);
  return await selectedClient
    .put(url, {
      headers: {
        Authorization: `Bearer ${options.token ?? (await token())}`,
      },
      json: body,
    })
    .json();
};
