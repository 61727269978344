import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Intro } from './Intro/Intro';
import { DenyVerification } from './DenyVerification/DenyVerification';
import { ProviderSelection } from './ProviderSelection';
import { useVerifyTaskContext, VerifyTaskContextProvider } from './VerifyTaskContext';
import { EdoFlow } from './Edo/EdoFlow';
import { IdNowFlow } from './IdNow/IdNowFlow';
import { SuccessScreen } from 'shared-fe-components/src/common/StatusScreens';
import { ProcessType } from 'shared-fe-components/src/common/StatusScreens';
import { FaceTecFlow } from './FaceTec/FaceTecFlow';
import { EidEasyFlow } from './EidEasy/EidEasyFlow';
import { DenySteps } from './DenyVerification/DenyVerification';
import { DummyProvider } from './DummyProvider';

import './Verification.scss';
import { RecordVerificationVideo } from './RecordVerificationVideo';

enum Steps {
  Intro,
  RecordVideo,
  DenyVerification,
  Verify,
  Success,
}

const VerifyTaskProviderSteps = () => {
  const { selectedProvider, setSelectedProvider, onBack, onCancel } = useVerifyTaskContext();

  return (
    <>
      {!selectedProvider && <ProviderSelection onSelection={setSelectedProvider} onCancel={onCancel} onBack={onBack} />}
      {selectedProvider === 'Edo' && <EdoFlow />}
      {selectedProvider === 'IdNow' && <IdNowFlow />}
      {selectedProvider === 'FaceTec' && <FaceTecFlow />}
      {selectedProvider?.startsWith('EidEasy:') && <EidEasyFlow />}
      {selectedProvider?.startsWith('_dummy:') && <DummyProvider />}
    </>
  );
};
export const Verification = () => {
  const [currentScreen, setCurrentScreen] = useState<Steps>(Steps.Intro);
  const [onStepSelect, setOnStepSelect] = useState<DenySteps>();
  const { sessionId, verificationId } = useParams();

  const showCancelConfirmation = () => {
    setCurrentScreen(Steps.Intro);
  };
  const goToSuccess = () => {
    setCurrentScreen(Steps.Success);
  };

  const goToRejected = () => {
    setOnStepSelect(DenySteps.Denied);
    setCurrentScreen(Steps.DenyVerification);
  };

  const contextProps = useMemo(
    () => ({
      sessionId,
      verificationId,
      onBack: () => setCurrentScreen(Steps.Intro),
      onCancel: showCancelConfirmation,
      goToIntro: showCancelConfirmation,
      goToRejected: goToRejected,
      goToSuccess: goToSuccess,
    }),
    [sessionId, verificationId]
  );

  return (
    <div className="dashboard-verification">
      <VerifyTaskContextProvider {...contextProps}>
        {currentScreen === Steps.Intro && (
          <Intro
            startVerification={() => setCurrentScreen(Steps.RecordVideo)}
            denyVerification={() => setCurrentScreen(Steps.DenyVerification)}
          />
        )}
        {currentScreen === Steps.RecordVideo && (
          <RecordVerificationVideo
            onContinue={() => setCurrentScreen(Steps.Verify)}
            onBack={() => setCurrentScreen(Steps.Intro)}
          />
        )}
        {currentScreen === Steps.DenyVerification && (
          <DenyVerification
            onStepSelect={onStepSelect}
            sessionId={sessionId as string}
            verificationId={verificationId as string}
            onBack={() => setCurrentScreen(Steps.Intro)}
          />
        )}
        {currentScreen === Steps.Success && <SuccessScreen processType={ProcessType.Verify} />}
        {currentScreen === Steps.Verify && <VerifyTaskProviderSteps />}
      </VerifyTaskContextProvider>
    </div>
  );
};
