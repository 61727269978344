export const XmlIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={24} fill="none">
    <path
      fill="#fff"
      d="M.857 23.429H17.43a.287.287 0 0 0 .285-.286V6.286h-4.857A.858.858 0 0 1 12 5.429V.57H.857a.287.287 0 0 0-.286.286v22.286c0 .157.129.286.286.286Z"
    />
    <path fill="#fff" d="M17.31 5.714 12.571.975V5.43c0 .157.129.285.286.285h4.453Z" />
    <path
      fill="#605E5C"
      d="M18.034 5.63 12.654.25A.863.863 0 0 0 12.05 0H.857A.858.858 0 0 0 0 .857v22.286c0 .472.385.857.857.857H17.43a.858.858 0 0 0 .857-.857V6.237a.85.85 0 0 0-.252-.606ZM12.571.976l4.74 4.74h-4.454a.286.286 0 0 1-.286-.286V.975ZM17.43 23.43H.857a.286.286 0 0 1-.286-.286V.857C.571.7.7.571.857.571H12V5.43c0 .472.385.857.857.857h4.857v16.857a.287.287 0 0 1-.285.286Z"
      opacity={0.64}
    />
    <path
      fill="#000"
      d="M15.726 18.286H3.131A.28.28 0 0 1 2.857 18a.28.28 0 0 1 .274-.286h12.595A.28.28 0 0 1 16 18a.28.28 0 0 1-.274.286Zm0 1.714H3.131a.28.28 0 0 1-.274-.286.28.28 0 0 1 .274-.285h12.595a.28.28 0 0 1 .274.285.28.28 0 0 1-.274.286Z"
    />
    <mask
      id="a"
      width={14}
      height={3}
      x={2}
      y={17}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path
        fill="#4D49E1"
        d="M15.726 18.286H3.131A.28.28 0 0 1 2.857 18a.28.28 0 0 1 .274-.286h12.595A.28.28 0 0 1 16 18a.28.28 0 0 1-.274.286Zm0 1.714H3.131a.28.28 0 0 1-.274-.286.28.28 0 0 1 .274-.285h12.595a.28.28 0 0 1 .274.285.28.28 0 0 1-.274.286Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#4D49E1"
        d="M15.726 17.714H3.131v1.143h12.595v-1.143Zm-12.595 0c.187 0 .298.151.298.286H2.286c0 .45.355.857.845.857v-1.143Zm.298.286c0 .135-.111.286-.298.286v-1.143a.851.851 0 0 0-.845.857h1.143Zm-.298.286h12.595v-1.143H3.131v1.143Zm12.595 0A.292.292 0 0 1 15.43 18h1.142a.851.851 0 0 0-.845-.857v1.143ZM15.43 18c0-.135.11-.286.297-.286v1.143c.49 0 .845-.407.845-.857H15.43Zm.297 1.429H3.131v1.142h12.595V19.43Zm-12.595 0c.187 0 .298.15.298.285H2.286c0 .45.355.857.845.857V19.43Zm.298.285c0 .135-.111.286-.298.286v-1.143a.851.851 0 0 0-.845.857h1.143ZM3.13 20h12.595v-1.143H3.131V20Zm12.595 0a.292.292 0 0 1-.297-.286h1.142a.851.851 0 0 0-.845-.857V20Zm-.297-.286c0-.134.11-.285.297-.285v1.142c.49 0 .845-.407.845-.857H15.43Z"
      />
    </g>
    <path stroke="#4D49E1" strokeLinecap="round" strokeMiterlimit={10} d="M10.857 8 7.43 16" />
    <path
      stroke="#4D49E1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="m13.242 10.286 2.186 1.985-2.186 2.015m-8.199 0L2.857 12.3l2.186-2.014"
    />
  </svg>
);
