import { ConfigurationAppType } from "api/contracts";

export interface ProviderSelectionProps {
  file: any;
  files: any;
  xadesFormat: string;
  onSelection: (v: string) => void;
  features: ConfigurationAppType["features"];
  signing: ConfigurationAppType["signing"];
  onBack: () => void;
  onCancel: () => void;
}

export interface ProviderSelectionContextValues
  extends Pick<ProviderSelectionProps, 'files' | 'onSelection' | 'features' | 'signing' | 'xadesFormat'> {
  country: string;
  isXML: boolean;
}

export interface ProviderSelectionFile {
  name: string;
  size: number;
  extension: string;
};

export const getFileInfo = (file: any): ProviderSelectionFile => {
  const name = file?.name ?? file?.fileName ?? '';
  const size = file?.size ?? file?.fileSize ?? Infinity;
  const extension = name.split('.').at(-1).toLowerCase() ?? '';

  return { name, size, extension };
};

export const isAcceptableFileSize = (file: any, maxSize: number) => {
  const { size } = getFileInfo(file);
  return !Number.isFinite(size) || size <= maxSize;
};
