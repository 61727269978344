import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { AppDownloads, MarketplaceURL } from '../../common/AppDownloads';
import { EdoAppLogoIcon } from '../../common/Icons';
import { EdoIntroProps } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './EdoDownload.scss';

export const EdoDownload = ({
  onBack,
  onCancel,
  onContinue,
}: Pick<EdoIntroProps, 'onBack' | 'onCancel' | 'onContinue'>) => {
  return (
    <WizardBox className="edo-download">
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Providers.Edo.Download.Header' })}
        </Text>
      </div>
      <div className="edo-download__logo-container">
        <EdoAppLogoIcon className="edo-download__icon" />
      </div>
      <div className="edo-download__downloads-container">
        <AppDownloads {...MarketplaceURL.Edo} />
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onContinue && (
            <Button appearance="primary" onClick={onContinue}>
              {t({ id: 'Common.Proceed' })}
            </Button>
          )}
        </div>
      </div>
    </WizardBox>
  );
};
