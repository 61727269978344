import { useEffect, useState } from 'react';
import { useSigningContext } from '../SigningContext';
import { NativeIntro, SignerDataForm } from 'shared-fe-components/src/providers/Native';
import { NativeSign } from './NativeSign';
import 'shared-fe-components/src/providers/Native/SignerDataForm.scss'

export const NativeFlow = () => {
  const [step, setStep] = useState('intro');
  const [signatureType, setSignatureType] = useState(null);
  const [signerData, setSignerData] = useState(null);

  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy, recipientPhoneNumber } = useSigningContext();

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  const goToIntro = () => setStep('intro');
  const goToSign = () => setStep('sign');
  const goToPhoneNumberVerification = () => setStep('phone-number-verification');

  const onSignerDataConfirmed = (signerData) => {
    setSignerData(signerData);
    goToSign();
  }

  const onSelectSignatureType = (signatureType) => {
    setSignatureType(signatureType);
    goToPhoneNumberVerification();
  }

  return (
    <>
      {step === 'intro' && (
        <NativeIntro
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToPhoneNumberVerification}
          onShowPrivacyPolicy={showPrivacyPolicy}
          onSelectSignatureType={onSelectSignatureType}
          recipientPhoneNumber={recipientPhoneNumber} />
      )}
      {step === 'phone-number-verification' && (
        <SignerDataForm onCancel={onCancel} onBack={goToIntro} signatureType={signatureType} knownPhoneNumber={recipientPhoneNumber}
          onSignerDataConfirmed={onSignerDataConfirmed} />
      )}
      {step === 'sign' && (
        <NativeSign onCancel={onCancel} onBack={goToPhoneNumberVerification} signerData={signerData} signatureType={signatureType} />
      )}
    </>
  );
}
