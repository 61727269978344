import * as client from './client';

export const getVerificationStatus = async (sessionId: any, verificationId: any) =>
  await client.getSafe(`verification/sessions/${sessionId}/verifications/${verificationId}`);

export const getVerificationSession = async (sessionId: any) =>
  await client.getSafe(`verification/sessions/${sessionId}`);

export const denyVerification = async (sessionId: string, verificationId: string, message: string) =>
  await client.postRaw(`verification/sessions/${sessionId}/verifications/${verificationId}/reject`, {
    message,
  } as any);

export const requestVerification = async ({ meetingId, culture, videoDuration }: any) =>
  await client.post(`verification/request-verification`, {
    verificationType: 'TeamsMeeting',
    meetingId,
    culture,
    ...(videoDuration
      ? {
          verificationVideoOptions: {
            required: true,
            videoDuration,
          },
        }
      : {}),
  } as any);

export const verify = async ({ sessionId, verificationId, verificationProviderType, additionalParameters = {} }: any) =>
  await client.post(`verification/verify`, {
    sessionId,
    verificationId,
    verificationProviderType,
    ...additionalParameters,
  });

export const completeVerification = async (sessionId: any) =>
  await client.post(`verification/sessions/${sessionId}/complete`);

// gets list of available providers based on sessionId from token
export const getAvailableProviders = async () => await client.get('verification/providers');

export const describeVerificationFromGetVerificationStatus = ({ data, error }: any) => {
  const desc = {
    errorCode: null,
    success: false,
    providerPending: false,
    timeout: false,
    failed: false,
    cancelled: false,
  } as any;

  if (data?.detailedError) {
    const detErr = data.detailedError;
    if (detErr.providerErrorCode === 'Unknown') {
      desc.errorCode = 'Unknown';
    } else if (detErr.providerType && detErr.providerErrorCode) {
      desc.errorCode = `${detErr.providerType}.${detErr.providerErrorCode}`;
    } else if (detErr.operationErrorCode) {
      desc.errorCode = detErr.operationErrorCode;
    } else {
      desc.errorCode = '';
    }
  }

  switch (data?.status) {
    case 'Succeeded':
      desc.success = data.isVerified;
      break;
    case 'ProviderPending':
      desc.providerPending = true;
      break;
    case 'Cancelled':
      desc.cancelled = true;
      desc.errorCode = data.status;
      break;
    case 'Failed':
      desc.failed = true;
      break;
    default:
      break;
  }

  // TODO: this should probabyl be done the same way as with signing
  if (error && !desc.errorCode) {
    desc.errorCode = error;
  }

  return desc;
};
