import { useState } from 'react';
import { Button } from '../../common/FluentMigration';
import { WizardBox } from '../../common/WizardBox';
import { LoadingScreen } from '../../common/LoadingScreen';
import { t } from '@lingui/macro';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './EidEasy.scss';

interface Props {
  signingUrl: string;
  onBack: () => void;
  onCancel: () => void;
}

export const EidEasySign = ({ signingUrl, onBack, onCancel }: Props) => {
  const [buttonVisible, setButtonVisible] = useState<boolean>(true);

  const onButtonClick = () => {
    window.open(signingUrl);
    setButtonVisible(false);
  };

  return (
    <WizardBox growContent={false} centerContent={true}>
      <div></div>
      <div>
        {buttonVisible ? (
          <Button primary onClick={onButtonClick} className="eideasy-sign__button">
            {t({ id: 'Providers.EidEasy.OpenProviderButton' })}
          </Button>
        ) : (
          <div className="no-overflow">
            <LoadingScreen />
          </div>
        )}
      </div>
      <div>
        <ButtonBack onClick={onBack} />
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </div>
    </WizardBox>
  );
};
