import { FaceTecSDK } from "./core-sdk/FaceTecSDK.js/FaceTecSDK";

export var Config = (function () {
    // -------------------------------------
    // REQUIRED
    // Available at https://dev.facetec.com/account
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var DeviceKeyIdentifier = "d3Y7Lgh7csVYJEEcCyvukrE3N64kd5kd";

    // -------------------------------------
    // REQUIRED
    // The FaceScan Encryption Key you define for your application.
    // Please see https://dev.facetec.com/facemap-encryption-keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var PublicFaceScanEncryptionKey = process.env.REACT_APP_FACETEC_PUBLIC_KEY;

    // This app can modify the customization to demonstrate different look/feel preferences
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    function retrieveConfigurationWizardCustomization(FaceTecSDK) {
        var sdkImageDirectory = "/providers/facetec/images/";

        // For Color Customization
        var outerBackgroundColor = "#ffffff";
        var frameColor = "#ffffff";
        var borderColor = "#4260D0";
        var ovalColor = "#4260D0";
        var dualSpinnerColor = "#4260D0";
        var textColor = "#4260D0";
        var buttonAndFeedbackBarColor = "#4260D0";
        var buttonAndFeedbackBarTextColor = "#ffffff";
        var buttonColorHighlight = "#3B56BB";
        var buttonColorDisabled = "#A4B3E8";

        // For Frame Corner Radius Customization
        let frameCornerRadius = "3px";

        // For Cancel Button Customization
        var cancelButtonImage = sdkImageDirectory + "FaceTec_cancel.png";
        var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;

        // For image Customization
        var yourAppLogoImage = sdkImageDirectory + "FaceTec_your_app_logo.png";
        var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec;


        // Set a default customization
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();


        // Set Frame Customization
        defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius;
        defaultCustomization.frameCustomization.backgroundColor = frameColor;
        defaultCustomization.frameCustomization.borderColor = borderColor;

        // Set Overlay Customization
        defaultCustomization.overlayCustomization.brandingImage = yourAppLogoImage;
        defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor;

        // Set Guidance Customization
        defaultCustomization.guidanceCustomization.backgroundColors = frameColor;
        defaultCustomization.guidanceCustomization.foregroundColor = textColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorDisabled;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorHighlight;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor;
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor;

        // Set Oval Customization
        defaultCustomization.ovalCustomization.strokeColor = ovalColor;
        defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor;
        defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor;

        // Set Feedback Customization
        defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor;

        // Set Cancel Customization
        defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage;
        defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation;

        // Set Security Watermark Customization
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage);

        // Set Result Screen Customization
        defaultCustomization.resultScreenCustomization.backgroundColors = frameColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor;

        // Set ID Scan Customization
        defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor;
        defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor;
        defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor;
        defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorDisabled;
        defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorHighlight;
        defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor;
        defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor;

        // Set Initial Loading Customization
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor;
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor;

        return defaultCustomization;
    };


    function retrieveLowLightConfigurationWizardCustomization(FaceTecSDK) {
        var defaultCustomization = retrieveConfigurationWizardCustomization(FaceTecSDK);
        this.currentLowLightCustomization = defaultCustomization;
        return defaultCustomization;
    }


    function retrieveDynamicDimmingConfigurationWizardCustomization(FaceTecSDK) {
        var defaultCustomization = retrieveConfigurationWizardCustomization(FaceTecSDK);
        this.currentDynamicDimmingCustomization = defaultCustomization;
        return defaultCustomization;
    }


    var currentCustomization;
    var currentLowLightCustomization;
    var currentDynamicDimmingCustomization;

    // -------------------------------------
    // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
    // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's Design Showcase,
    // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
    // Configuration Wizard.
    var wasSDKConfiguredWithConfigWizard = true;

    var SDKInIFrameFeatureFlag = { ac_ziif: "991d6c1c-5e5c-4e32-89c7-1ddfac46572e" };
    currentCustomization = new FaceTecSDK.FaceTecCustomization([SDKInIFrameFeatureFlag]);

    return {
        wasSDKConfiguredWithConfigWizard,
        DeviceKeyIdentifier,
        PublicFaceScanEncryptionKey,
        currentCustomization,
        currentLowLightCustomization,
        currentDynamicDimmingCustomization,
        retrieveConfigurationWizardCustomization,
        retrieveLowLightConfigurationWizardCustomization,
        retrieveDynamicDimmingConfigurationWizardCustomization
    };

})();
