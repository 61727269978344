import { useState } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { VerificationRejectIcon } from 'shared-fe-components/src/common/Icons';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { t } from '@lingui/macro';
import * as api from 'shared-fe-components/src/api/index';
import { useSigningContext } from '../SigningContext';
import { TextAreaWithCounter } from 'shared-fe-components/src/common/TextAreaWithCounter/TextAreaWithCounter';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import './RejectSign.scss';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';

export const RejectSign = ({ onBack }: { onBack: () => void }) => {
  const [step, setStep] = useState('intro');
  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      message: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, control } = formMethods;
  const { sessionId, signatureId } = useSigningContext();

  const onFormSubmit = async (data: { message: string }) => {
    setStep('loading');
    try {
      const reject = await api.signing.rejectSign(sessionId, signatureId, data.message);
      if (reject) setStep('rejected');
    } catch (err) {
      setStep('error');
    }
  };

  const onConfirm = handleSubmit(onFormSubmit);

  const messageMaxLength = 200;

  const messageProps = {
    characterLimit: messageMaxLength,
    size: 'large',
  };

  return (
    <>
      {step === 'intro' && (
        <WizardBox className="reject-sign">
          <div>
            <Header as="h1">{t({ id: 'Signing.Reject.Sign.Header' })}</Header>
          </div>
          <div>
            <Text size={400} weight="semibold">
              {t({ id: 'Signing.Reject.Sign.Confirmation' })}
            </Text>
            <Text size={400} weight="semibold">
              {t({ id: 'Signing.Reject.Sign.Explanation.Header' })}
            </Text>
            <FormProvider {...formMethods}>
              <Controller
                control={control}
                name="message"
                rules={{ maxLength: messageMaxLength }}
                render={(formData) => (
                  <TextAreaWithCounter className="reject-sign__message" {...messageProps} formData={formData} />
                )}
              />
            </FormProvider>
          </div>
          <div>
            <div>
              <ButtonBack onClick={onBack} />
            </div>
            <div>
              <Button appearance="primary" onClick={onConfirm}>
                {t({ id: 'Common.Refuse' })}
              </Button>
            </div>
          </div>
        </WizardBox>
      )}
      {step === 'loading' && <LoadingScreen />}
      {step === 'error' && <ErrorScreen processType="Refuse" />}
      {step === 'rejected' && (
        <Flex className="reject-completed" column vAlign="center" hAlign="center">
          <VerificationRejectIcon />
          <Text as="h1" weight="bold">
            {t({ id: 'Signing.Reject.Sign.Rejected' })}
          </Text>
          <Text>{t({ id: 'Signing.Reject.Sign.Process.End' })}</Text>
        </Flex>
      )}
    </>
  );
};
