import { useEffect, useState, useCallback } from 'react';
import { Image, Text } from '@fluentui/react-components';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import { EdoAppLogoIconBig } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { useVerifyTaskContext } from '../VerifyTaskContext';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import 'shared-fe-components/src/providers/Edo/EdoContainer.scss';

export const EdoVerify = ({ onBack, onCancel }) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [sessionExpirationDate, setSessionExpirationDate] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [newCodeButtonVisible, setNewCodeButtonVisible] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);
  const { verify, getVerificationStatus } = useVerifyTaskContext();

  const startNewVerification = useCallback(async () => {
    setLoading(true);
    const response = await verify({});

    const expirationDate = response?.providerResponse?.tokenExpirationDate;
    setSessionExpirationDate(expirationDate);
    setTimeLeft('');
    setData(response);
    setLoading(false);
    setCodeExpired(false);
  }, [verify]);

  useEffect(() => {
    startNewVerification();
  }, [startNewVerification]);

  useEffect(() => {
    const updateTimeLeft = () => {
      if (sessionExpirationDate) {
        const secondsLeft = dayjs(sessionExpirationDate).diff(dayjs(), 'seconds');
        if (secondsLeft >= 0) {
          setTimeLeft(dayjs(secondsLeft * 1000).format('mm:ss'));
        } else {
          setCodeExpired(true);
        }
      }
    };
    updateTimeLeft();
    const timerInterval = setInterval(updateTimeLeft, 1000);
    return () => clearInterval(timerInterval);
  }, [sessionExpirationDate]);

  useEffect(() => {
    const statusInterval = setInterval(async () => {
      if (!loading) {
        await getVerificationStatus({});
      }
    }, 5000);

    return () => clearInterval(statusInterval);
  }, [getVerificationStatus, loading]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <WizardBox className="edo" growContent={false} centerContent={true}>
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Verification.VerifyHeader' })}
        </Text>
      </div>
      <div className="edo__content-container">
        <div className="edo__edo-red-container">
          <div className="edo__instructions">
            <EdoAppLogoIconBig />
            {!codeExpired && (
              <>
                <ol>
                  <li>{t({ id: 'Providers.Edo.Guide.1' })}</li>
                  <li>{t({ id: 'Providers.Edo.Guide.2' })}</li>
                </ol>
                <div className="edo__divider" />
                <div className="edo__verification-data">
                  <div>{t({ id: 'Providers.Edo.Code.Expires' })}</div>
                  <div className="edo__verification-data--value">{timeLeft}</div>
                  <div>{t({ id: 'Providers.Edo.Code' })}</div>
                  <div className="edo__verification-data--value">{data?.providerResponse?.code}</div>
                </div>
              </>
            )}
            {codeExpired && (
              <>
                <div>{t({ id: 'Providers.Edo.ExpiredCode.1.Verify' })}</div>
                <div>{t({ id: 'Providers.Edo.ExpiredCode.2' })}</div>
                <div className="edo__divider" />
                <Button outline inverted onClick={startNewVerification}>
                  {t({ id: 'Providers.Edo.Code.GetNew' })}
                </Button>
              </>
            )}
          </div>
          <div>
            {data && (
              <Image
                src={`data:image/png;base64, ${data?.providerResponse?.qrcode}`}
                className={codeExpired ? 'edo__qr-code--expired' : ''}
                alt={t({ id: 'Common.QRCodeAlt' })}
              />
            )}
          </div>
        </div>
        <div className="edo__generate-new-code">
          {!newCodeButtonVisible && !codeExpired && (
            <Button text onClick={() => setNewCodeButtonVisible(true)}>
              {t({ id: 'Providers.Edo.Code.NotWorking' })}
            </Button>
          )}
          {newCodeButtonVisible && !codeExpired && (
            <Button text onClick={startNewVerification}>
              {t({ id: 'Providers.Edo.Code.GetNew' })}
            </Button>
          )}
        </div>
      </div>
      <div>
        <div>
          <ButtonBack onClick={onBack} />
        </div>
        <div>
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
        </div>
      </div>
    </WizardBox>
  );
};
