var FaceTecStrings = {
  "FaceTec_action_ok": "أوافق",
  "FaceTec_action_im_ready": "أنا مستعد",
  "FaceTec_action_try_again": "المحاولة مرة أخرى",
  "FaceTec_action_continue": "المتابعة",
  "FaceTec_action_take_photo": "التقاط صورة",
  "FaceTec_action_accept_photo": "أقبل",
  "FaceTec_action_retake_photo": "إعادة",
  "FaceTec_action_confirm": "تأكيد المعلومات",

  "FaceTec_accessibility_cancel_button": "إلغاء",
  "FaceTec_accessibility_tap_guidance": "انقر مرتين في أي مكان على الشاشة لتوجيه محاذاة الوجه.",
  "FaceTec_accessibility_key_down_guidance": "اضغط على Enter أو مفتاح المسافة للحصول على إرشادات محاذاة الوجه",

  "FaceTec_accessibility_feedback_move_phone_away": "وجهك قريب أكثر من اللازم",
  "FaceTec_accessibility_feedback_move_phone_closer": "وجهك بعيد أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_too_far_left": "وجهك ناحية اليسار أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_too_far_right": "وجهك ناحية اليمين أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_too_low": "وجهك منخفض أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_too_high": "وجهك مرتفع أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "تم تدوير وجهك ناحية اليسار أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "تم تدوير وجهك ناحية اليمين أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "وجهك يشير إلى اليسار أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "وجهك يشير إلى اليمين أكثر من اللازم",
  "FaceTec_accessibility_feedback_face_not_in_camera": "الوجه ليس على الكاميرا أو بعيدًا جدًا",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "أمسك الجهاز على مستوى العين",
  "FaceTec_accessibility_feedback_move_away_web": "الكاميرا قريبة جدا",
  "FaceTec_accessibility_feedback_move_closer_web": "الكاميرا بعيدة جدًا",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "انقل الكاميرا إلى مستوى العين",

  "FaceTec_presession_frame_your_face": "ضع وجهك في الشكل البيضاوي",
  "FaceTec_presession_look_straight_ahead": "انظر إلى الأمام مباشرةً",
  "FaceTec_presession_hold_steady3": "أمسك بثبات لمدة: 3",
  "FaceTec_presession_hold_steady2": "أمسك بثبات لمدة: 2",
  "FaceTec_presession_hold_steady1": "أمسك بثبات لمدة: 1",
  "FaceTec_presession_eyes_straight_ahead": "انظر إلى الأمام مباشرةً",
  "FaceTec_presession_remove_dark_glasses": "قم بإزالة النظارات الداكنة",
  "FaceTec_presession_neutral_expression": "تعبير محايد، لا تبتسم",
  "FaceTec_presession_conditions_too_bright": "الظروف شديدة السطوع",
  "FaceTec_presession_brighten_your_environment": "قم بزيادة الضوء في محيطك",

  "FaceTec_feedback_center_face": "قم بتوسيط وجهك",
  "FaceTec_feedback_face_not_found": "ضع وجهك في الإطار",
  "FaceTec_feedback_move_phone_away": "تحرّك بعيدًا",
  "FaceTec_feedback_move_away_web": "تحرّك بعيدًا",
  "FaceTec_feedback_move_phone_closer": "اقترب أكثر",
  "FaceTec_feedback_move_web_closer": "جعل الوجه أقرب وملء البيضاوي",
  "FaceTec_feedback_move_web_even_closer": "حتى أقرب",
  "FaceTec_feedback_move_phone_to_eye_level": "حرّك الكاميرا إلى مستوى العين",
  "FaceTec_feedback_move_to_eye_level_web": "انظر مباشرة إلى الكاميرا",
  "FaceTec_feedback_face_not_looking_straight_ahead": "انظر إلى الأمام مباشرةً",
  "FaceTec_feedback_face_not_upright": "ارفع رأسك بشكل مستقيم",
  "FaceTec_feedback_face_not_upright_mobile": "ارفع رأسك بشكل مستقيم",
  "FaceTec_feedback_hold_steady": "أمسك بثبات",
  "FaceTec_feedback_use_even_lighting": "اجعل الضوء على وجهك بشكل متساوٍ أكثر",

  "FaceTec_instructions_header_ready_desktop": "احصل على استعداد لفيديوسيلفي الخاص بك",
  "FaceTec_instructions_header_ready_1_mobile": "استعد من أجل",
  "FaceTec_instructions_header_ready_2_mobile": "تصوير فيديو ذاتي",
  "FaceTec_instructions_message_ready_desktop": "تأطير وجهك في الصحافة البيضاوي \"أنا مستعد\" والاقتراب",
  "FaceTec_instructions_message_ready_1_mobile": "ضع وجهك في الشكل البيضاوي",
  "FaceTec_instructions_message_ready_2_mobile": "اضغط على أنا جاهز واقترب",

  "FaceTec_retry_header": "لنحاول ذلك مرة أخرى",
  "FaceTec_retry_subheader_message": "نحتاج إلى تصوير فيديو ذاتي أوضح",
  "FaceTec_retry_your_image_label": "صورتك الذاتية",
  "FaceTec_retry_ideal_image_label": "الوضعية المثالية",
  "FaceTec_retry_instruction_message_1": "استخدم الوضعية والإضاءة المثاليين",
  "FaceTec_retry_instruction_message_2": "تعبير محايد، لا تبتسم",
  "FaceTec_retry_instruction_message_3": "ضبابية للغاية، نظّف الكاميرا",

  "FaceTec_camera_feed_issue_header": "مشكلة من خلال حماية اتصال الكاميرا",
  "FaceTec_camera_feed_issue_header_mobile": "مشكلة لحماية <br/>اتصال الغرفة",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "يحظر هذا التطبيق تكوينات الويب المشبوهة.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>تعرف على المزيد هنا.</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "لا يمكن التحقق من هذا النظام بسبب ما يلي:",
  "FaceTec_camera_feed_issue_table_header_1": "مشكلة ممكنة",
  "FaceTec_camera_feed_issue_table_header_2": "صحيح",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "تصاريح الغرفة لا يتم تذكرها في Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "مارك تذكر الأذونات.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "الغرفة المستخدمة بالفعل من قبل تطبيق آخر.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "أغلق التطبيق الآخر.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "يقوم تطبيق Park الثالث بتعديل الفيديو.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "إغلاق / إلغاء تثبيت التطبيق الآخر.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "المعدات التي لا يمكن حمايتها.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "استخدم جهازًا مختلفًا",
  "FaceTec_camera_feed_issue_subtable_message": "يحظر هذا التطبيق تكوينات الويب المشبوهة. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>تعرف على المزيد هنا.</a>.",
  "FaceTec_camera_feed_issue_action": "المحاولة مرة أخرى",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "أوافق",

  "FaceTec_camera_permission_header": "فعّل الكاميرا",
  "FaceTec_camera_permission_message": "أذونات الكاميرا معطلة.n\ يرجى التحقق من نظام التشغيل الخاص بك وإعدادات المتصفح",

  "FaceTec_enter_fullscreen_header": "وضع صورة شخصية بملء الشاشة",
  "FaceTec_enter_fullscreen_message": "قبل أن نبدأ ، يرجى النقر فوق الزر أدناه لفتح وضع ملء الشاشة.",
  "FaceTec_enter_fullscreen_action": "فتح كامل الشاشة",

  "FaceTec_initializing_camera": "تأمين بث الكاميرا",

  "FaceTec_idscan_type_selection_header": "استعد لمسح<br/>مستند الهوية التعريفية الخاصة بك ضوئيًا",
  "FaceTec_idscan_capture_id_front_instruction_message": "أظهر مقدمة الهوية التعريفية",
  "FaceTec_idscan_capture_id_back_instruction_message": "أظهر خلفية الهوية التعريفية",
  "FaceTec_idscan_review_id_front_instruction_message": "تأكّد من أن الصورة واضحة ومقروءة",
  "FaceTec_idscan_review_id_back_instruction_message": "تأكّد من أن الصورة واضحة ومقروءة",
  "FaceTec_idscan_additional_review_message": "مطلوب<br/>مراجعة إضافية",
  "FaceTec_idscan_ocr_confirmation_main_header": "مراجعة وتأكيد",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "مرر لأسفل",

  "FaceTec_result_success_message": "نجاح",
  "FaceTec_result_facescan_upload_message": "تحميل <br/>مشفر<br/>لمسح ضوئي ثلاثي الأبعاد للوجه",
  "FaceTec_result_idscan_upload_message": "تحميل <br/>مشفر<br/>لمسح ضوئي للهوية التعريفية",
  "FaceTec_result_idscan_unsuccess_message": "صورة الهوية التعريفية<br/>لم تتطابق<br/>مع وجه المستخدم",
  "FaceTec_result_idscan_success_front_side_message": "اكتمل مسح الهوية التعريفية ضوئيًا",
  "FaceTec_result_idscan_success_front_side_back_next_message": "تم مسح<br/>مقدمة الهوية التعريفية ضوئيًا",
  "FaceTec_result_idscan_success_back_side_message": "اكتمل مسح الهوية التعريفية ضوئيًا",
  "FaceTec_result_idscan_success_passport_message": "اكتمل مسح جواز السفر ضوئيًا",
  "FaceTec_result_idscan_success_user_confirmation_message": "اكتمل<br/>مسح صورة الهوية التعريفية ضوئيًا",
  "FaceTec_result_idscan_success_additional_review_message": "اكتمل<br/>التقاط صورة الهوية التعريفية",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "الوجه غير متطابق<br/>بدرجة كافية",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "مستند الهوية التعريفية<br/>غير مرئي بالكامل",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "نص الهوية التعريفية غير مقروء",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "نوع الهوية التعريفية غير معتمد<br/>يُرجى استخدام هوية تعريفية مختلفة",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "فشل مسح الرمز الشريطي ضوئيًا<br/>يُرجى المحاولة مرة أخرى",
};

module.exports = FaceTecStrings;
