export const MarketplaceURL: Record<string, Record<string, string>> = {
  Edo: {
    googlePlay: 'https://play.google.com/store/apps/details?id=pl.pwpw.edohub',
    appStore: 'https://apps.apple.com/app/apple-store/id1503023855?l=pl',
    appGallery: 'https://appgallery.cloud.huawei.com/ag/n/app/C103398923',
  },
  IdNow: {
    googlePlay: 'https://play.google.com/store/apps/details?id=de.idnow',
    appStore: 'https://apps.apple.com/pl/app/idnow-online-ident/id918081242',
  },
  SimplySign: {
    googlePlay: 'https://play.google.com/store/apps/details?id=com.assecods.certum.simplysign',
    appStore: 'https://apps.apple.com/pl/app/certum-simplysign/id1244415465',
  },
};
