import { useContext, useLayoutEffect, FC, ReactNode } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useLanguage } from './LanguageContext';
import { LocalizationContext, selectLocale } from 'shared-fe-components/src/contexts/LocalizationContext';

type LocalizationProviderType = {
  locale?: Object | null;
  children: ReactNode;
};
export const LocalizationProvider: FC<LocalizationProviderType> = ({ locale = null, children }) => {
  const { language } = useLanguage();

  useLayoutEffect(() => {
    i18n.activate(selectLocale(locale ?? language));
  }, [locale, language]);

  return (
    <LocalizationContext.Provider value={{ locale: i18n.locale }}>
      <I18nProvider i18n={i18n}>
        {children}
      </I18nProvider>
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
