import ky from 'ky';

const apiUrl = process.env.REACT_APP_INPERLY_API_ENDPOINT + '/providers/asseco/simplysign';
const client = ky.create({
  prefixUrl: apiUrl,
  timeout: 60000
});

export const getAccessToken = async (authorizationCode: any) =>
  await client.post('access-token', {
    headers: {
      'X-Provider-Token': authorizationCode
    }
  }).json();

export const getCards = async (accessToken: any) =>
  await client.get('cards', {
    headers: {
      'X-Provider-Token': accessToken
    }
  }).json();
