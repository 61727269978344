import { Button, Text } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { Warning24Regular } from '@fluentui/react-icons';
import { useEffect, useState, useCallback } from 'react';
import * as api from 'shared-fe-components/src/api/index';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { Scrollable } from 'shared-fe-components/src/common/Scrollable';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { useFetch } from '../../lib/useFetch';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import './PrivacyPolicy.scss';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';

interface PrivacyPolicyProps {
  required: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const PrivacyPolicy = ({ required, onClose, onAccept }: PrivacyPolicyProps) => {
  const [allowAccept, setAllowAccept] = useState(false);
  const { pristine, loading, data, error, load } = useFetch(() => api.gdpr.get());

  useEffect(() => {
    load();
  }, [load]);

  const onRetryClick = useCallback(() => {
    load();
  }, [load]);

  const onScrolledToEnd = useCallback(() => {
    if (data && !allowAccept) {
      setAllowAccept(true);
    }
  }, [data, allowAccept]);

  return (
    <WizardBox>
      <div>
        <Text as="h1" weight="semibold">
          {t({ id: 'PrivacyPolicy.Header' })}
        </Text>
      </div>

      <div>
        {error && !(pristine || loading) && (
          <Alert intent="error" icon={<Warning24Regular />} action={<Button onClick={onRetryClick}>{t({ id: 'Common.Retry' })}</Button>}>
            {t({ id: 'PrivacyPolicy.Error' })}
          </Alert>
        )}

        {(pristine || loading) && <LoadingScreen />}

        {data && (
          <Scrollable onScrolledToEnd={onScrolledToEnd}>
            <Text as="h2" block weight="semibold">
              {t({ id: 'PrivacyPolicy.Content.Header' })}
            </Text>
            <Text as="p" block>{t({ id: 'PrivacyPolicy.Content.Paragraph0', values: { administrator: data?.adminName } })}</Text>
            <Text as="p" block>
              {t({
                id: 'PrivacyPolicy.Content.Paragraph1',
                values: {
                  contact: data?.adminContact,
                  address: data?.adminAddress,
                },
              })}
            </Text>
            <Text as="p" block>{t({ id: 'PrivacyPolicy.Content.Paragraph2' })}</Text>
            <Text as="p" block>{t({ id: 'PrivacyPolicy.Content.Paragraph3' })}</Text>
            <Text as="p" block>
              {
                <Trans
                  id="PrivacyPolicy.Content.Paragraph4"
                  components={[<Anchor href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer" />]}
                />
              }
            </Text>
          </Scrollable>
        )}
      </div>

      <div>
        {required && (
          <>
            <Button onClick={onClose}>{t({ id: 'Common.Cancel' })}</Button>
            <Button appearance="primary" disabled={!allowAccept} onClick={onAccept}>
              {t({ id: 'Common.Accept' })}
            </Button>
          </>
        )}
        {!required && (
          <>
            <Button onClick={onClose}>{t({ id: 'Common.Close' })}</Button>
          </>
        )}
      </div>
    </WizardBox>
  );
};
