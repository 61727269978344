import { ReactNode } from 'react';
import { Link } from '@fluentui/react-components';
import './Anchor.scss';

interface AnchorProps {
  className?: string;
  children?: ReactNode;
  [rest: string]: any;
}
export const Anchor = ({ className = '', children, ...rest }: AnchorProps) => {
  return (
    <Link as="a" {...rest} className={`anchor ${className}`}>
      {children}
    </Link>
  );
};
