import { useEffect, useState, createElement } from 'react';
import { InperlyPen } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';

type SVG =
  | React.ReactElement<
      {
        src: string;
        alt: string;
      },
      string | React.JSXElementConstructor<string>
    >
  | JSX.Element;

type Provider = {
  name: string;
  label: string;
  image?: SVG;
};

export const EidEasyProviders: Record<string, Pick<Provider, 'name' | 'label'> & { file: string }> = {
  'EidEasy:id-signature': {
    name: 'Estonian ID card',
    label: 'Eid-Easy-Estonian-ID-Card',
    file: 'id-card',
  },
  'EidEasy:mid-signature': {
    name: 'Estonian Mobile-ID',
    label: 'Eid-Easy-Estonian-Mobile-ID',
    file: 'mobile-id',
  },
  'EidEasy:lt-mid-signature': {
    name: 'Lithuanian Mobile-ID',
    label: 'Eid-Easy-Lithuanian-Mobile-ID',
    file: 'mobiil-id',
  },
  'EidEasy:smart-id-signature': {
    name: 'Smart-ID',
    label: 'Eid-Easy-Smart-ID',
    file: 'smart-id',
  },
  'EidEasy:be-id-signature': {
    name: 'Belgian ID card',
    label: 'Eid-Easy-Belgian-ID-card',
    file: 'belgian-eid',
  },
  'EidEasy:lt-id-signature': {
    name: 'Lithuanian ID card',
    label: 'Eid-Easy-Lithuanian-ID-card',
    file: 'id-card',
  },
  'EidEasy:lv-id-signature': {
    name: 'Latvian ID card',
    label: 'Eid-Easy-Latvian-ID-card',
    file: 'id-card',
  },
  'EidEasy:fi-id-signature': {
    name: 'Finnish ID card',
    label: 'Eid-Easy-Finnish-ID-card',
    file: 'id-card',
  },
  'EidEasy:at-handy-signatur-signature': {
    name: 'Austrian Handy-Signatur',
    label: 'Eid-Easy-Austrian-Handy-Signatur',
    file: 'at-handy-sign',
  },
  'EidEasy:lv-eparaksts-mobile-signature': {
    name: 'Latvian eParaksts Mobile',
    label: 'Eid-Easy-Latvian-eParaksts-Mobile',
    file: 'e-paraksts-mark',
  },
  'EidEasy:fi-ftn-advanced-signature': {
    name: 'Finnish Trust Network / Luottamusverkosto',
    label: 'Eid-Easy-Finnish-Trust-Network',
    file: 'ftn',
  },
  'EidEasy:evrotrust-signature': {
    name: 'Evrotrust',
    label: 'Eid-Easy-Evrotrust',
    file: 'evrotrust-logo-en',
  },
  'EidEasy:cz-mojeid-advanced-signature': {
    name: 'MojeId',
    label: 'Eid-Easy-MojeId',
    file: 'mojeid',
  },
  'EidEasy:d-trust-sign-me-qes-signature': {
    name: 'D-Trust sign-me',
    label: 'Eid-Easy-D-Trust-Sign-Me',
    file: 'signme-logo-color',
  },
  'EidEasy:chave-movel-qes-signature': {
    name: 'Chave Movel',
    label: 'Eid-Easy-Chave-Movel',
    file: 'cartao-de-cidado',
  },
  'EidEasy:diia-qes-signature': {
    name: 'DIIA',
    label: '',
    file: 'diia',
  },
  'EidEasy:audkenni-qes-signature': {
    name: 'Audkenni',
    label: 'Eid-Easy-Audkenni',
    file: 'audkenni-logo',
  },
  'EidEasy:simply-sign-qes-signature': {
    name: 'SimplySign',
    label: 'Eid-Easy-SimplySign',
    file: 'simply-sign-logo',
  },
  'EidEasy:yes-com-qes-signature': {
    name: 'Yes.com',
    label: 'Eid-Easy-Yes-Com',
    file: 'yes-com',
  },
  'EidEasy:uanataca-qes-signature': {
    name: 'Uanataca',
    label: 'Eid-Easy-Uanataca',
    file: 'uanataca-logo',
  },
  'EidEasy:mojeid': {
    name: 'MojeID login',
    label: '',
    file: 'pl-mojeid',
  },
  'EidEasy:freja-eid-login': {
    name: 'Freja eID login',
    label: '',
    file: 'freja-eid',
  },
  'EidEasy:ee-id-login': {
    name: 'Estonian ID card login',
    label: 'Eid-Easy-Estonian-ID-Card',
    file: 'id-card',
  },
  'EidEasy:mid-login': {
    name: 'Estonian Mobile ID',
    label: 'Eid-Easy-Estonian-Mobile-ID',
    file: 'mobile-id',
  },
  'EidEasy:smartid': {
    name: 'Smart-ID login',
    label: 'Eid-Easy-Smart-ID',
    file: 'smart-id',
  },
  'EidEasy:pt-id-login': {
    name: 'Portugese Cartão de Cidadão',
    label: '',
    file: 'cartao-de-cidado',
  },
  'EidEasy:be-id-login': {
    name: 'Belgium ID card login',
    label: 'Eid-Easy-Belgian-ID-card',
    file: 'id-card',
  },
  'EidEasy:fi-id-login': {
    name: 'Finnish Henkilökortti',
    label: 'Eid-Easy-Finnish-ID-card',
    file: 'id-card',
  },
  'EidEasy:lv-eparaksts-mobile-login': {
    name: 'eParaksts Mobile login',
    label: 'Eid-Easy-Latvian-eParaksts-Mobile',
    file: 'eparaksts-mobile-square',
  },
  'EidEasy:at-handy-signatur-login': {
    name: 'Austrian Handy-signatur login',
    label: 'Eid-Easy-Austrian-Handy-Signatur',
    file: 'at-handy-sign',
  },
  'EidEasy:lt-id-login': {
    name: 'Lithuanian ID Card',
    label: 'Eid-Easy-Lithuanian-ID-card',
    file: 'id-card',
  },
  'EidEasy:lt-mobile-id': {
    name: 'Lithuanian Mobile ID',
    label: 'Eid-Easy-Lithuanian-Mobile-ID',
    file: 'mobiil-id',
  },
  'EidEasy:rs-id-login': {
    name: 'Serbian ID Card',
    label: '',
    file: 'id-card',
  },
  'EidEasy:edoapp-eid-login': {
    name: 'eDO App login',
    label: 'Eid-Easy-eDO App',
    file: 'edoapp-logo',
  },
  'EidEasy:otp-email-non-qualified': {
    name: 'E-mail/SMS',
    label: '',
    file: 'id-card',
  },
} as const;

export const EidEasyService = (provider: string): Provider => {
  const [image, setImage] = useState<SVG | undefined>(undefined);
  const { name, label, file } = EidEasyProviders[provider];

  useEffect(() => {
    (async () => {
      /**Lazy loading image */
      try {
        const importedImage: { default: string } = await import(`./images/${file}.svg`);

        const component = createElement('img', {
          src: importedImage.default,
          alt: `${name} logo`,
          className: 'provider-selection__eid-provider-icon',
        });
        setImage(component);
      } catch {
        setImage(InperlyPen);
      }
    })();
  }, [file]);

  return { name, label: t({ id: label }), image };
};
