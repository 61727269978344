import { Text, Button } from '@fluentui/react-components';
import { EdoAppFingerprintIcon, IdDocumentIcon, SmartphoneIcon } from '../../common/Icons';
import { t } from '@lingui/macro';
import { LinkToPrivacyPolicy } from '../../common/PrivacyPolicy';
import { ActionableSection } from '../../common/ActionableSection';
import { Anchor } from '../../common/Anchor';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { EdoIntroProps } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';

export const EdoIntro = ({
  onBack,
  onCancel,
  onContinue,
  onHelp,
  onShowDownload,
  onShowPrivacyPolicy,
}: EdoIntroProps) => {
  return (
    <WizardBox>
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Common.GetReady' })}
        </Text>
      </div>
      <div>
        <div className="edo-intro__tiles">
          <ActionableSection icon={<IdDocumentIcon />}>
            <Text weight="semibold">{t({ id: 'Providers.Edo.Intro.IdCard.Title' })}</Text>
            <br />
            <Anchor href="#" onClick={onHelp}>
              {t({ id: 'Providers.Edo.Intro.IdCard.Details' })}
            </Anchor>
          </ActionableSection>
          <ActionableSection icon={<SmartphoneIcon />}>
            <Text as="div" weight="semibold">
              {t({ id: 'Providers.Edo.Intro.Smartphone.Title' })}
            </Text>
          </ActionableSection>
          <ActionableSection icon={<EdoAppFingerprintIcon />}>
            <Text weight="semibold">{t({ id: 'Providers.Edo.Intro.App.Title' })}</Text>
            <br />
            <Anchor href="#" onClick={onShowDownload}>
              {t({ id: 'Providers.Edo.Intro.App.Details' })}
            </Anchor>
          </ActionableSection>
        </div>
        <div>
          <LinkToPrivacyPolicy onShowPrivacyPolicy={onShowPrivacyPolicy} />
        </div>
      </div>
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onContinue && (
            <Button appearance="primary" onClick={onContinue}>
              {t({ id: 'Common.Proceed' })}
            </Button>
          )}
        </div>
      </div>
    </WizardBox>
  );
};
