import { useEffect, createContext, useContext } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { NationalityDropdown } from 'shared-fe-components/src/common/ReactHookForm';
import { useForm, FormProvider } from 'react-hook-form';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { lastSelectedCountryStorageKey } from 'shared-fe-components/src/providers/ProviderSelection';
import type { ProviderSelectionProps, ProviderSelectionContextValues } from './models';
import { ProvidersList } from '.';
import { useUserContext, useLanguage } from 'contexts';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';

export const ProviderSelection = ({ onSelection, onBack, onCancel }: ProviderSelectionProps) => {
  const [lastSelectedCountry, setLastSelectedCountry] = usePersistentStorage(lastSelectedCountryStorageKey);
  const { features } = useUserContext();
  const { language } = useLanguage();

  const countryFieldName = 'country';
  const formMethods = useForm({
    defaultValues: {
      [countryFieldName]: lastSelectedCountry ?? 'PL',
    },
  });
  const country = formMethods.watch(countryFieldName);


  useEffect(() => {
    setLastSelectedCountry(country);
  }, [country, setLastSelectedCountry]);

  return (
    <ProviderSelectionContext.Provider value={{ onSelection, features, country }}>
      <WizardBox>
        <div>
          <Text as="h1" weight="bold" block>{t({ id: 'ProviderSelection.Verification.Header' })}</Text>
        </div>

        <div>
          <div>
            <FormProvider {...formMethods}>
              <Text className="verify-window__country-selection-label">
                {t({ id: 'ProviderSelection.Signing.CountrySelectionLabel' })}
              </Text>
              <NationalityDropdown language={language} fieldName={countryFieldName} minMenuHeight={400} />
            </FormProvider>
          </div>
          <ProvidersList />
        </div>

        <div>
          <ButtonBack onClick={onBack} />
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
        </div>
      </WizardBox>
    </ProviderSelectionContext.Provider>
  );
};

const ProviderSelectionContext = createContext<ProviderSelectionContextValues | null>(null);

export const useProviderSelectionContext = () => {
  const ctx = useContext(ProviderSelectionContext);

  if (!ctx) {
    throw Error('Lack of ProviderSelectionContext.Provider in components tree');
  }

  return ctx;
};
