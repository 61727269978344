import { useCallback, useEffect, useRef, useState } from 'react';

export const useCountdown = (): [number, (initialValue: number, intervalMillis: number) => void] => {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const currentIntervalRef = useRef<NodeJS.Timer | null>(null);

  const resetCountdown = () => {
    if (currentIntervalRef.current) {
      clearInterval(currentIntervalRef.current);
      currentIntervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      resetCountdown();
    };
  }, []);

  const startCountdown = useCallback((initialValue: number, intervalMillis: number) => {
    resetCountdown();

    let countdown = initialValue;
    setCurrentValue(countdown);

    currentIntervalRef.current = setInterval(() => {
      if (countdown === 0) {
        resetCountdown();
      } else {
        setCurrentValue(--countdown);
      }
    }, intervalMillis);
  }, []);

  return [currentValue, startCountdown];
};
