import { useEffect, useState } from 'react';
import { EdoIntro } from 'shared-fe-components/src/providers/Edo/EdoIntro';
import { EdoDownload } from 'shared-fe-components/src/providers/Edo/EdoDownload';
import { EdoSign } from './EdoSign';
import { EdoDocInfo } from 'shared-fe-components/src/providers/Edo/EdoDocInfo';
import { useSigningContext } from '../SigningContext';

export const EdoFlow = () => {
  const [step, setStep] = useState('intro');
  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useSigningContext();

  const goToIntro = () => setStep('intro');
  const goToSign = () => setStep('sign');
  const goToHelp = () => setStep('help');
  const goToDownload = () => setStep('download');

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  return (
    <>
      {step === 'intro' && (
        <EdoIntro
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToSign}
          onHelp={goToHelp}
          onShowDownload={goToDownload}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'help' && <EdoDocInfo onBack={goToIntro} />}
      {step === 'sign' && <EdoSign onBack={goToIntro} onCancel={onCancel} />}
      {step === 'download' && <EdoDownload onCancel={onCancel} onContinue={goToSign} onBack={goToIntro} />}
    </>
  );
};
