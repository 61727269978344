import { Header } from "shared-fe-components/src/common/Header";
import { SelectionEvents, OptionOnSelectData } from '@fluentui/react-combobox';
import { MSzafirCertificateType } from "./models";
import { t } from "@lingui/macro";
import { useState } from "react";
import { ErrorMessage } from "shared-fe-components/src/common/WithError";
import { Button, Text, Spinner, Input, Dropdown, Option } from '@fluentui/react-components';
import "./CertificateParameters.scss";
import "./../../../scss/common.scss";

export interface CertificateParametersProperties {
    certificateType: MSzafirCertificateType | null;
    onCertTypeChanged: (selectedKey: string | undefined) => void; 
}

export const CertificateParameters = (props: CertificateParametersProperties) => {
    const StringIsNumber = value => isNaN(Number(value)) === true;
    const certTypesKeys = Object.values(MSzafirCertificateType).filter(StringIsNumber);

    const [isCertTypeValid, setCertTypeValid] = useState(true);    

    function handleCertificateTypeChange(event: SelectionEvents, data: OptionOnSelectData): void {
        props.onCertTypeChanged(data.optionValue);
        setCertTypeValid(data.optionValue !== undefined && data.optionValue != null);
    }

    return(
    <>
        <Header as="h1">{t({id: 'DashboardCertificate.Parameters'})}</Header>
            <div className="form-inline">
                <div className="form-inline__container">
                    <div className="form-inline__label">
                        <Text as="p">{t({id: 'DashboardCertificate.CertificateType'})}:</Text>
                    </div>
                    <div className="form-inline__input">
                        {props.certificateType !== null 
                            ? <Text as="span">{t({id: `DashboardCertificate.${props.certificateType}`})}</Text>
                            : 
                            <>
                                <Dropdown 
                                    className="form-inline__input"
                                    placeholder={t({id: 'DashboardCertificate.ChooseCertificateType'})}
                                    appearance="filled-darker"
                                    onOptionSelect={handleCertificateTypeChange}
                                    error={!isCertTypeValid}
                                    >
                                    {certTypesKeys.map((cType) => (
                                        <Option key={cType} value={cType.toString()}>{t({id: `DashboardCertificate.${cType}`})}</Option>
                                    ))}
                                </Dropdown>
                                {/* {!isCertTypeValid && <ErrorMessage message="Wybierz typ certyfikatu" />} */}
                            </>
                        }
                    </div>
                </div>    
            </div>
    </>)
}