import { createContext, useContext } from "react";
import { i18n } from '@lingui/core';
import { de, en, es, pl, pt, pt_PT } from 'make-plural/plurals'
import { deMessages, enMessages, esMessages, plMessages, ptBrMessages } from 'shared-fe-locales';

export const LocalizationContext = createContext<any>(undefined);

const messages = {
  'de': deMessages,
  'en': enMessages,
  'es': esMessages,
  'pl': plMessages,
  // change the below line when Portugal Portugese version will be needed:
  'pt-pt': ptBrMessages,
  'pt-br': ptBrMessages,
};

i18n.load(messages);
i18n.loadLocaleData({
  de: { plurals: de },
  en: { plurals: en },
  es: { plurals: es },
  pl: { plurals: pl },
  "pt-pt": { plurals: pt_PT },
  "pt-br": { plurals: pt },
});

export const selectLocale = (locale: any) => {
  if (messages.hasOwnProperty(locale)) {
    return locale;
  }

  try {
    const intlLocale = new Intl.Locale(locale).language;

    if (messages.hasOwnProperty(intlLocale)) {
      return intlLocale;
    }
  } catch {
    // locale value is not valid: do nothing
  }

  return 'en';
};

export const useLocalization = () => useContext(LocalizationContext);