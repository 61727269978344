import { Button, Image } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { useState, useEffect, useCallback } from 'react';
import { t } from '@lingui/macro';
import { EdoAppLogoIconBig } from 'shared-fe-components/src/common/Icons';
import dayjs from 'dayjs';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { useSigningContext } from '../SigningContext';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { Info12Regular } from '@fluentui/react-icons';
import { Button as ButtonCustom } from 'shared-fe-components/src/common/FluentMigration';
import 'shared-fe-components/src/providers/Edo/EdoContainer.scss';

interface responseDataType {
  operationId: string;
  providerResponse: {
    code?: string;
    qrcode?: string;
  };
}
export const EdoSign = ({ onBack, onCancel }: { onBack: () => void; onCancel: () => void }) => {
  const [data, setData] = useState<responseDataType | null>(null);
  const [loading, setLoading] = useState(true);
  const [sessionExpirationDate, setSessionExpirationDate] = useState(null);
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [codeExpired, setCodeExpired] = useState(false);
  const [newCodeButtonVisible, setNewCodeButtonVisible] = useState(false);
  const { sign, getSignatureStatus } = useSigningContext();

  const startSigning = useCallback(async () => {
    setLoading(true);
    const response = await sign({});
    const expirationDate = response?.providerResponse?.tokenExpirationDate;
    setSessionExpirationDate(expirationDate);
    setTimeLeft('');
    setData(response);
    setLoading(false);
    setCodeExpired(false);
  }, [sign]);

  // initial fetching
  useEffect(() => {
    startSigning();
  }, [startSigning]);

  // timer effect
  useEffect(() => {
    const updateTimeLeft = () => {
      if (sessionExpirationDate && !loading) {
        const secondsLeft = dayjs(sessionExpirationDate).diff(dayjs(), 'seconds');
        if (secondsLeft >= 0) {
          setTimeLeft(dayjs(secondsLeft * 1000).format('mm:ss'));
        } else {
          setCodeExpired(true);
        }
      }
    };
    updateTimeLeft();
    const timerInterval = setInterval(updateTimeLeft, 1000);
    return () => clearInterval(timerInterval);
  }, [sessionExpirationDate, loading]);

  // status refetching effect
  useEffect(() => {
    const interval = setInterval(async () => {
      if (data) {
        await getSignatureStatus({});
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [data, getSignatureStatus]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <WizardBox className="edo" growContent={false} centerContent={true}>
      <div>
        <Header as="h1">{t({ id: 'Signing.SignHeader' })}</Header>
      </div>
      <div className="edo__content-container">
        <div className="edo__edo-red-container">
          <div className="edo__instructions">
            <EdoAppLogoIconBig />
            {!codeExpired && (
              <>
                <ol>
                  <li>{t({ id: 'Providers.Edo.Guide.1' })}</li>
                  <li>{t({ id: 'Providers.Edo.Guide.2' })}</li>
                </ol>

                <div className="edo__divider"></div>

                <div className="edo__verification-data">
                  <div>{t({ id: 'Providers.Edo.Code.Expires' })}</div>
                  <div className="edo__verification-data--value">{timeLeft}</div>
                  <div>{t({ id: 'Providers.Edo.Code' })}</div>
                  <div className="edo__verification-data--value">{data?.providerResponse.code}</div>
                </div>
              </>
            )}
            {codeExpired && (
              <>
                <div>{t({ id: 'Providers.Edo.ExpiredCode.1' })}</div>
                <div>{t({ id: 'Providers.Edo.ExpiredCode.2' })}</div>

                <div className="edo__divider"></div>

                <ButtonCustom outline inverted onClick={startSigning}>
                  {t({ id: 'Providers.Edo.Code.GetNew' })}
                </ButtonCustom>
              </>
            )}
          </div>
          <div>
            {data && (
              <Image
                src={`data:image/png;base64, ${data.providerResponse.qrcode}`}
                className={codeExpired ? 'edo__qr-code--expired' : ''}
                alt={t({ id: 'Common.QRCodeAlt' })}
              />
            )}
          </div>
        </div>
        <div className="edo__generate-new-code">
          {!newCodeButtonVisible && !codeExpired && (
            <Button
              size="small"
              icon={<Info12Regular />}
              appearance="transparent"
              onClick={() => setNewCodeButtonVisible(true)}
            >
              {t({ id: 'Providers.Edo.Code.NotWorking' })}
            </Button>
          )}
          {newCodeButtonVisible && !codeExpired && (
            <Button size="small" appearance="transparent" onClick={startSigning}>
              {t({ id: 'Providers.Edo.Code.GetNew' })}
            </Button>
          )}
        </div>
      </div>
      <div>
        <ButtonBack onClick={onBack} />
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </div>
    </WizardBox>
  );
};
