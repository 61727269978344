import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'contexts';
import { State, autoStates, states, Theme } from './models';
import './ThemeSwitch.scss';
import { Button, Tooltip } from '@fluentui/react-components';
import { t } from '@lingui/macro';

export const ThemeSwitch = () => {
  const { themeName, setTheme } = useTheme();
  const firstUpdate = useRef(true);
  const [currentTheme, setCurrentTheme] = useState<State>(autoStates[themeName as Theme]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setCurrentTheme(states[themeName as Theme]);
  }, [themeName]);

  const changeTheme = () => {
    setTheme(currentTheme.next);
  };

  return (
    <div>
      <Tooltip relationship="label" content={t({ id: currentTheme.tooltip })}>
        <Button className="theme-switch__button" appearance="transparent" onClick={changeTheme} icon={<span>{currentTheme.icon}</span>} />
      </Tooltip>
    </div>
  );
};
