var FaceTecStrings = {
  "FaceTec_action_ok": "OK",
  "FaceTec_action_im_ready": "EK IS GEREED",
  "FaceTec_action_try_again": "PROBEER WEER",
  "FaceTec_action_continue": "AANHOU",
  "FaceTec_action_take_photo": "NEEM 'N FOTO",
  "FaceTec_action_accept_photo": "AANVAAR",
  "FaceTec_action_retake_photo": "HERNEEM",
  "FaceTec_action_confirm": "BEVESTIG INLIGTING",

  "FaceTec_accessibility_cancel_button": "Kanselleer",
  "FaceTec_accessibility_tap_guidance": "Dubbeltik enige plek op die skerm vir gesigbelyningsleiding.",
  "FaceTec_accessibility_key_down_guidance": "Druk Enter of spasiebalk vir gesigbelyningsleiding",

  "FaceTec_accessibility_feedback_move_phone_away": "Gesig te naby",
  "FaceTec_accessibility_feedback_move_phone_closer": "Gesig te ver weg",
  "FaceTec_accessibility_feedback_face_too_far_left": "Gesig te ver links",
  "FaceTec_accessibility_feedback_face_too_far_right": "Gesig te ver regs",
  "FaceTec_accessibility_feedback_face_too_low": "Gesig te laag",
  "FaceTec_accessibility_feedback_face_too_high": "Gesig te hoog",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Gesig te ver links gedraai",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Gesig te ver regs gedraai",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Gesig wat te ver links wys",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Gesig wys te ver regs",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Gesig nie op kamera of te ver weg nie",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Hou toestel op ooghoogte",
  "FaceTec_accessibility_feedback_move_away_web": "Kamera te naby",
  "FaceTec_accessibility_feedback_move_closer_web": "Kamera te ver weg",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Beweeg kamera na ooghoogte",

  "FaceTec_presession_frame_your_face": "Raam jou gesig in die ovaal",
  "FaceTec_presession_look_straight_ahead": "Kyk Reguit Vooruit",
  "FaceTec_presession_hold_steady3": "Hou vas vir: 3",
  "FaceTec_presession_hold_steady2": "Hou vas vir: 2",
  "FaceTec_presession_hold_steady1": "Hou vas vir: 1",
  "FaceTec_presession_eyes_straight_ahead": "Kyk Reguit Vooruit",
  "FaceTec_presession_remove_dark_glasses": "Verwyder donkerbrille",
  "FaceTec_presession_neutral_expression": "Neutrale uitdrukking, geen glimlag nie",
  "FaceTec_presession_conditions_too_bright": "Toestande te helder",
  "FaceTec_presession_brighten_your_environment": "Verhelder jou omgewing",

  "FaceTec_feedback_center_face": "Sentreer jou gesig",
  "FaceTec_feedback_face_not_found": "Raam jou gesig",
  "FaceTec_feedback_move_phone_away": "Beweeg Weg",
  "FaceTec_feedback_move_away_web": "Beweeg Weg",
  "FaceTec_feedback_move_phone_closer": "Beweeg nader",
  "FaceTec_feedback_move_web_closer": "Bring die gesig nader en vul die ovaal in",
  "FaceTec_feedback_move_web_even_closer": "Nog Nader",
  "FaceTec_feedback_move_phone_to_eye_level": "Beweeg kamera na ooghoogte",
  "FaceTec_feedback_move_to_eye_level_web": "Kyk Direk Na Die Kamera",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Kyk Reguit Vooruit",
  "FaceTec_feedback_face_not_upright": "Hou jou kop reguit",
  "FaceTec_feedback_face_not_upright_mobile": "Hou jou kop reguit",
  "FaceTec_feedback_hold_steady": "Hou vas",
  "FaceTec_feedback_use_even_lighting": "Lig gesig meer eweredig",

  "FaceTec_instructions_header_ready_desktop": "Maak Gereed Vir Jou Video Selfie",
  "FaceTec_instructions_header_ready_1_mobile": "Maak Gereed Vir",
  "FaceTec_instructions_header_ready_2_mobile": "Jou Video Selfie",
  "FaceTec_instructions_message_ready_desktop": "Raam Jou Gesig In Die Ovaal, Druk ek Is Gereed En Beweeg Nader",
  "FaceTec_instructions_message_ready_1_mobile": "Raam Jou Gesig In Die Ovaal",
  "FaceTec_instructions_message_ready_2_mobile": "Druk ek Is Gereed En Beweeg Nader",

  "FaceTec_retry_header": "Kom Ons Probeer Dit Weer",
  "FaceTec_retry_subheader_message": "Ons Benodig'n Duideliker Video Selfie",
  "FaceTec_retry_your_image_label": "Jou Selfie",
  "FaceTec_retry_ideal_image_label": "Ideale Posisie",
  "FaceTec_retry_instruction_message_1": "Gebruik ideale houding en beligting",
  "FaceTec_retry_instruction_message_2": "Neutrale Uitdrukking, Geen Glimlag Nie",
  "FaceTec_retry_instruction_message_3": "Te Vaag, Skoon Kamera",

  "FaceTec_camera_feed_issue_header": "Kwestie van die beveiliging van kameratoevoer",
  "FaceTec_camera_feed_issue_header_mobile": "Kon nie <br/>kameraverbinding beskerm",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Hierdie toepassing blokkeer verdagte webkamera-instellings.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Leer meer hier..</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Hierdie stelsel kan nie geverifieer word nie as gevolg van die volgende:",
  "FaceTec_camera_feed_issue_table_header_1": "Moontlike Probleem",
  "FaceTec_camera_feed_issue_table_header_2": "Korrek",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Kamera regte nie onthou In Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Kontroleer Onthou Toestemmings.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Kamera reeds in gebruik deur'n ander aansoek.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Sluit die ander aansoek.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "n 3de party aansoek is die verandering van die video.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Sluit / verwyder die ander toepassing.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Toerusting wat nie beskerm kan word nie.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Gebruik'n ander toestel",
  "FaceTec_camera_feed_issue_subtable_message": "Hierdie toepassing blokkeer verdagte webkamera-instellings. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Leer meer hier..</a>.",
  "FaceTec_camera_feed_issue_action": "PROBEER WEER",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

  "FaceTec_camera_permission_header": "Aktiveer kamera",
  "FaceTec_camera_permission_message": "Kamera regte gedeaktiveer.<br/>kontroleer u bedryfstelsel en blaaierinstellings",

  "FaceTec_enter_fullscreen_header": "Volskerm Selfie-modus",
  "FaceTec_enter_fullscreen_message": "Voordat ons begin, klik asseblief die knoppie hieronder om volskermmodus oop te maak.",
  "FaceTec_enter_fullscreen_action": "OOP VOLLE SKERM",

  "FaceTec_initializing_camera": "Beveilig kameratoevoer",

  "FaceTec_idscan_type_selection_header": "Berei voor om<br/>jou identifikasie te skandeer",
  "FaceTec_idscan_capture_id_front_instruction_message": "Die voorkant VAN JOU ID",
  "FaceTec_idscan_capture_id_back_instruction_message": "Agterkant VAN JOU ID",
  "FaceTec_idscan_review_id_front_instruction_message": "Duidelike en leesbare foto?",
  "FaceTec_idscan_review_id_back_instruction_message": "Duidelike en leesbare foto?",
  "FaceTec_idscan_additional_review_message": "Bykomende verifikasie<br/>word vereis",
  "FaceTec_idscan_ocr_confirmation_main_header": "Hersien en bevestig",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Beweeg na af",
  "FaceTec_idscan_feedback_flip_id_to_back_message": "Agterkant VAN JOU ID",

  "FaceTec_result_success_message": "Gereed",
  "FaceTec_result_facescan_upload_message": "Oplaai<br/>DIE 3D gesig scan<br/>geënkripteer",
  "FaceTec_result_idscan_upload_message": "Oplaai<br/>DIE ID scan<br/>geënkripteer",
  "FaceTec_result_idscan_unsuccess_message": "Die ID-foto<br/>of pas by<br/>die gebruiker se gesig",
  "FaceTec_result_idscan_success_front_side_message": "ID-skandering voltooi",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Voorkant van ID<br/>geskandeer",
  "FaceTec_result_idscan_success_back_side_message": "ID-skandering voltooi",
  "FaceTec_result_idscan_success_passport_message": "Paspoortskandering voltooi",
  "FaceTec_result_idscan_success_user_confirmation_message": "Foto ID-skandering<br/>voltooi",
  "FaceTec_result_idscan_success_additional_review_message": "Vaslegging van ID-foto<br/>voltooi",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Die gesig pas nie genoeg by<br/>nie",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Die id<br/>is nie ten volle sigbaar nie",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Die teks van die identifikasie is nie leesbaar nie",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Hierdie tipe ID word nie ondersteun nie<br/>Gebruik asseblief 'n ander ID",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Kon nie strepieskode skandeer nie<br/>Probeer asseblief weer",
};

module.exports = FaceTecStrings;
