interface Payload {
  key: string;
  value: string;
  daysToExpiry?: number;
}

export const useCookies = () => {
  const getExpirationTime = (days: number = 400): number => {
    return days * 24 * 60 * 60;
  };

  const setCookie = (payload: Payload) => {
    const { key, value, daysToExpiry } = payload;
    document.cookie = `${key}=${value}; max-age=${getExpirationTime(daysToExpiry)}; SameSite=None; Secure; path=/`;
  };

  const getCookie = (name: string) => {
    const parts = `; ${document.cookie}`.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop()!.split(';').shift();
    }

    return undefined;
  };

  return { getCookie, setCookie };
};
