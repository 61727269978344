var FaceTecOcrStrings = {
  "groups": [
    {
      "groupFriendlyName": "Suas informações",
      "fields": [
        {
          "fieldKey": "lastName",
          "fieldFriendlyName": "Sobrenome",
          "uiFieldDescriptionText": "Last Name"
        },
        {
          "fieldKey": "firstName",
          "fieldFriendlyName": "Nome próprio",
          "uiFieldDescriptionText": "First Name"
        },
        {
          "fieldKey": "placeOfBirth",
          "fieldFriendlyName": "Local de nascimento",
          "uiFieldDescriptionText": "Place of Birth"
        },
        {
          "fieldKey": "middleInitial",
          "fieldFriendlyName": "Inicial do meio",
          "uiFieldDescriptionText": "Middle Initial"
        },
        {
          "fieldKey": "nationality",
          "fieldFriendlyName": "Nacionalidade",
          "uiFieldDescriptionText": "Nationality"
        },
        {
          "fieldKey": "givenName",
          "fieldFriendlyName": "Nome",
          "uiFieldDescriptionText": "Given Name"
        },
        {
          "fieldKey": "fullName",
          "fieldFriendlyName": "Nome e sobrenome",
          "uiFieldDescriptionText": "Full Name"
        },
        {
          "fieldKey": "dateOfBirth",
          "fieldFriendlyName": "Data de nascimento",
          "uiFieldDescriptionText": "Date of Birth"
        },
        {
          "fieldKey": "middleName",
          "fieldFriendlyName": "Segundo nome",
          "uiFieldDescriptionText": "Middle Name"
        }
      ],
      "groupKey": "userInfo"
    },
    {
      "groupFriendlyName": "Seu endereço",
      "fields": [
        {
          "fieldKey": "zipCode",
          "fieldFriendlyName": "Código postal",
          "uiFieldDescriptionText": "Zip Code"
        },
        {
          "fieldKey": "country",
          "fieldFriendlyName": "País",
          "uiFieldDescriptionText": "Country"
        },
        {
          "fieldKey": "address3",
          "fieldFriendlyName": "Endereço Linha 3",
          "uiFieldDescriptionText": "Address Line 3"
        },
        {
          "fieldKey": "province",
          "fieldFriendlyName": "Província",
          "uiFieldDescriptionText": "Province"
        },
        {
          "fieldKey": "address2",
          "fieldFriendlyName": "Endereço Linha 2",
          "uiFieldDescriptionText": "Address #2"
        },
        {
          "fieldKey": "city",
          "fieldFriendlyName": "Cidade",
          "uiFieldDescriptionText": "City"
        },
        {
          "fieldKey": "address1",
          "fieldFriendlyName": "Endereço Linha 1",
          "uiFieldDescriptionText": "Address Line 1"
        },
        {
          "fieldKey": "county",
          "fieldFriendlyName": "Condado",
          "uiFieldDescriptionText": "County"
        },
        {
          "fieldKey": "state",
          "fieldFriendlyName": "Estado",
          "uiFieldDescriptionText": "State"
        },
        {
          "fieldKey": "territory",
          "fieldFriendlyName": "Território",
          "uiFieldDescriptionText": "Territory"
        }
      ],
      "groupKey": "addressInfo"
    },
    {
      "groupFriendlyName": "Traços físicos e diversos",
      "fields": [
        {
          "fieldKey": "hair",
          "fieldFriendlyName": "Cabelo",
          "uiFieldDescriptionText": "Hair"
        },
        {
          "fieldKey": "sex",
          "fieldFriendlyName": "Sexo",
          "uiFieldDescriptionText": "Gender"
        },
        {
          "fieldKey": "weight",
          "fieldFriendlyName": "Peso",
          "uiFieldDescriptionText": "Weight"
        },
        {
          "fieldKey": "eyes",
          "fieldFriendlyName": "Olhos",
          "uiFieldDescriptionText": "Eyes"
        },
        {
          "fieldKey": "height",
          "fieldFriendlyName": "Altura",
          "uiFieldDescriptionText": "Height"
        }
      ],
      "groupKey": "secondaryUserInfo"
    },
    {
      "groupFriendlyName": "Informações adicionais",
      "fields": [
        {
          "fieldKey": "customField5",
          "fieldFriendlyName": "Campo personalizado 5",
          "uiFieldDescriptionText": "Custom Field 5"
        },
        {
          "fieldKey": "customField1",
          "fieldFriendlyName": "Campo personalizado 1",
          "uiFieldDescriptionText": "Custom Field 1"
        },
        {
          "fieldKey": "customField2",
          "fieldFriendlyName": "Campo personalizado 2",
          "uiFieldDescriptionText": "Custom Field 2"
        },
        {
          "fieldKey": "customField3",
          "fieldFriendlyName": "Campo personalizado 3",
          "uiFieldDescriptionText": "Custom Field 3"
        },
        {
          "fieldKey": "customField4",
          "fieldFriendlyName": "Campo personalizado 4",
          "uiFieldDescriptionText": "Custom Field 4"
        }
      ],
      "groupKey": "customFields"
    },
    {
      "groupFriendlyName": "Detalhes do documento",
      "fields": [
        {
          "fieldKey": "restrictions",
          "fieldFriendlyName": "Restrições",
          "uiFieldDescriptionText": "Restrictions"
        },
        {
          "fieldKey": "passportType",
          "fieldFriendlyName": "Tipo de passaporte",
          "uiFieldDescriptionText": "Passport Type"
        },
        {
          "fieldKey": "idNumber",
          "fieldFriendlyName": "Número do documento",
          "uiFieldDescriptionText": "Photo ID #"
        },
        {
          "fieldKey": "dateOfIssue",
          "fieldFriendlyName": "Data de emissão",
          "uiFieldDescriptionText": "Issued Date"
        },
        {
          "fieldKey": "idNumber2",
          "fieldFriendlyName": "ID secundário #",
          "uiFieldDescriptionText": "Secondary ID #"
        },
        {
          "fieldKey": "issuingAuthority",
          "fieldFriendlyName": "Autoridade emissora",
          "uiFieldDescriptionText": "Issuing Authority"
        },
        {
          "fieldKey": "dateOfExpiration",
          "fieldFriendlyName": "Data de validade",
          "uiFieldDescriptionText": "Expiration Date"
        },
        {
          "fieldKey": "countryCode",
          "fieldFriendlyName": "Código do país",
          "uiFieldDescriptionText": "Country Code"
        },
        {
          "fieldKey": "end",
          "fieldFriendlyName": "Fim",
          "uiFieldDescriptionText": "End"
        },
        {
          "fieldKey": "mrzLine3",
          "fieldFriendlyName": "MRZ 3",
          "uiFieldDescriptionText": "MRZ 3"
        },
        {
          "fieldKey": "barcode",
          "fieldFriendlyName": "Código de barras",
          "uiFieldDescriptionText": "Barcode"
        },
        {
          "fieldKey": "class",
          "fieldFriendlyName": "Classe",
          "uiFieldDescriptionText": "Class"
        },
        {
          "fieldKey": "mrzLine1",
          "fieldFriendlyName": "MRZ 1",
          "uiFieldDescriptionText": "MRZ 1"
        },
        {
          "fieldKey": "mrzLine2",
          "fieldFriendlyName": "MRZ 2",
          "uiFieldDescriptionText": "MRZ 2"
        }
      ],
      "groupKey": "idInfo"
    }
  ]
};

export default FaceTecOcrStrings;
