import { useEffect, useState } from 'react';
import { Button, Input, Link, Text } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { Checkmark24Regular } from '@fluentui/react-icons';
import { useSigningContext } from '../SigningContext';
import { requestOtp, verifySignatureProtection } from 'shared-fe-components/src/api/signing';
import { Controller, ControllerProps, useForm } from 'react-hook-form';
import { usePersistentStorage } from 'shared-fe-components/src/hooks';
import { useCountdown } from 'lib/useCountdown';
import { DashboardLayoutHeader } from '../../DashboardLayout/DashboardLayoutHeader';
import { HTTPError } from 'ky';
import { t } from '@lingui/macro';
import dayjs from 'dayjs';

const FIELDNAME_CODE = 'code';

enum OtpErrors {
  InvalidCode = 403,
  TooManyRequests = 429,
}

const RESEND_COOLDOWN_MINUTES = 15;

const VERIFY_COOLDOWN_MINUTES = 15;
const VERIFY_ATTEMPTS_LIMIT = 5;

const calculateUnlockTime = (lockMinutes: number) => {
  const now = dayjs();
  const unlockTime = now.add(lockMinutes, 'minute');
  return unlockTime;
};

const formatTimeLeft = (seconds: number): string => {
  const minutesLeft = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds) % 60;
  return `${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
};

const filterOnlyDigits = (value: string) => value.replace(/\D/g, '');
const trimCode = (value: string) => value.substring(0, 6);

export const OtpCode = () => {
  const { sessionId, signatureId, recipientPhoneNumber, setOtpCode } = useSigningContext();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [isFormLocked, setIsFormLocked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [wasInitialCodeRequested, setWasInitialCodeRequested] = usePersistentStorage(`signature-${signatureId}-code-requested`);
  const [resendUnlockTimePersistent, setResendUnlockTimePersistent] = usePersistentStorage(`signature-${signatureId}-resend-unlock-time`);
  const [hasUsedResend, setHasUsedResend] = useState<boolean>(false);
  const [resendUnlockMinutesLeft, startResendUnlockCountdown] = useCountdown();
  const [isVerifyLocked, setIsVerifyLocked] = useState<boolean>(false);
  const [attemptsCounter, setAttemptsCounter] = useState<number>(0);

  const resetLockAfterCooldown = (unlockTime: number) => {
    const now = dayjs().valueOf();
    const millisToUnlock = unlockTime - now;
    setTimeout(() => {
      setHasUsedResend(false);
      setErrorMessage(null);
    }, millisToUnlock);
  };

  const handleTooManyCodeVerifications = () => {
    setErrorMessage(t({ id: 'Otp.Errors.IncorrectCodeLocked' }));
    setIsVerifyLocked(true);

    setTimeout(() => {
      setAttemptsCounter(0);
      setErrorMessage(null);
    }, VERIFY_COOLDOWN_MINUTES * 60 * 1000);
  };

  useEffect(() => {
    if (attemptsCounter >= VERIFY_ATTEMPTS_LIMIT) {
      handleTooManyCodeVerifications();
    } else {
      setIsVerifyLocked(false);
    }
  }, [attemptsCounter]);

  useEffect(() => {
    if (!wasInitialCodeRequested) {
      requestOtp(sessionId, signatureId);
      setWasInitialCodeRequested(true);
    }

    if (resendUnlockTimePersistent) {
      const now = dayjs();
      const unlockTime = dayjs(resendUnlockTimePersistent);
      if (unlockTime.isAfter(now)) {
        const differenceInSeconds = Math.floor((unlockTime.valueOf() - now.valueOf()) / 1000);
        startResendUnlockCountdown(differenceInSeconds, 1000);
        resetLockAfterCooldown(unlockTime.valueOf());
      }
    }
  }, []);

  useEffect(() => {
    const errorMessage = errors[FIELDNAME_CODE]?.message;
    const validationErrorMessage = typeof errorMessage === 'string' ? errorMessage : '';
    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
    }
  }, [errors]);

  const handleProceedClick = handleSubmit(async (data) => {
    setIsFormLocked(true);
    setErrorMessage(null);
    try {
      await verifySignatureProtection(sessionId, signatureId, data.code);
      setOtpCode(data.code);
    } catch (error) {
      setAttemptsCounter(attemptsCounter + 1);
      const responseStatus = (error as HTTPError).response.status;
      if (responseStatus === OtpErrors.InvalidCode) {
        setErrorMessage(t({ id: 'Otp.Errors.IncorrectCode' }));
      } else if (responseStatus === OtpErrors.TooManyRequests) {
        handleTooManyCodeVerifications();
      } else {
        console.error(error);
      }
    } finally {
      setIsFormLocked(false);
    }
  });

  const handleRequestAgainClick = async () => {
    setErrorMessage(null);
    try {
      setIsFormLocked(true);
      await requestOtp(sessionId, signatureId);
      const unlockTime = calculateUnlockTime(RESEND_COOLDOWN_MINUTES).valueOf();
      setHasUsedResend(true);
      resetLockAfterCooldown(unlockTime);
      setResendUnlockTimePersistent(unlockTime);
      setValue(FIELDNAME_CODE, '');
      setAttemptsCounter(0); // New code request resets verifying limit
    } catch (error) {
      const responseStatus = (error as HTTPError).response.status;
      if (responseStatus === OtpErrors.TooManyRequests) {
        // If the cooldown hasn't ended yet, and we don't know when it does (i.e. local storage cleared)
        // we assume it will end in next 15 minutes
        startResendUnlockCountdown(RESEND_COOLDOWN_MINUTES * 60, 1000);
        const unlockTime = calculateUnlockTime(RESEND_COOLDOWN_MINUTES).valueOf();
        setResendUnlockTimePersistent(unlockTime);
        resetLockAfterCooldown(unlockTime);
      } else {
        console.error(error);
      }
    } finally {
      setIsFormLocked(false);
    }
  };

  const applyCodeConstraints = (code: string) => {
    return trimCode(filterOnlyDigits(code));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleProceedClick();
    }
  };

  const codeProps: ControllerProps = {
    control,
    name: FIELDNAME_CODE,
    defaultValue: '',
    rules: {
      required: { value: true, message: t({ id: 'Otp.Errors.IncorrectCode' }) },
      minLength: { value: 6, message: t({ id: 'Otp.Errors.MinLength', values: { length: 6 } }) },
    },
    render: ({ field }) => {
      const processedValue = applyCodeConstraints(field.value);
      return (
        <Input
          {...field}
          onKeyDown={handleKeyDown}
          value={processedValue}
          disabled={isFormLocked}
          placeholder={t({ id: 'Otp.CodePlaceholder' })}
        />
      );
    },
  } as const;

  return (
    <>
      <div></div>
      <div>
        <div className="otp__header-container">
          <DashboardLayoutHeader />
          <Text className="otp__label">{t({ id: 'Otp.Verification' })}</Text>
        </div>
        <div className="otp__body-container">
          <Header as="h1">{t({ id: 'Otp.Headers.InsertCode' })}</Header>
          <Text className="otp__label" block>
            {t({
              id: 'Otp.InsertCodeDescription',
              values: { phoneNumber: `+${recipientPhoneNumber.prefix}${String.fromCharCode(160)}${recipientPhoneNumber.number}` },
            })}
          </Text>
        </div>

        {errorMessage && <Text className="otp__error">{t({ id: errorMessage })}</Text>}
        <Controller {...codeProps} />
        <div className="otp__request-again-container">
          {resendUnlockMinutesLeft === 0 && !isFormLocked && !hasUsedResend && (
            <>
              <Text className="otp__label">{t({ id: 'Otp.RequestCodeDescrpition' })}</Text>{' '}
              <Link inline as="button" onClick={handleRequestAgainClick}>
                <Text>{t({ id: 'Otp.Buttons.RequestAgain' })}</Text>
              </Link>
            </>
          )}
          {resendUnlockMinutesLeft === 0 && isFormLocked && !hasUsedResend && (
            <>
              <Text className="otp__label">{t({ id: 'Otp.RequestCodeDescrpition' })}</Text>{' '}
              <Text className="otp__label">{t({ id: 'Otp.Buttons.RequestAgain' })}</Text>
            </>
          )}
          {hasUsedResend && (
            <div className="otp__icon">
              <Checkmark24Regular /> <Text className="otp__label">{t({ id: 'Otp.NewCodeSent' })}</Text>
            </div>
          )}
          {resendUnlockMinutesLeft !== 0 && (
            <>
              <Text className="otp__label">{t({ id: 'Otp.RequestCodeDescrpition' })}</Text>{' '}
              <Text className="otp__label">
                {t({ id: 'Otp.ResendLocked', values: { minutes: formatTimeLeft(resendUnlockMinutesLeft) } })}
              </Text>
            </>
          )}
        </div>
      </div>

      <div>
        <div></div>

        <div>
          <Button appearance="primary" disabled={isFormLocked || isVerifyLocked} onClick={handleProceedClick}>
            {t({ id: 'Common.Proceed' })}
          </Button>
        </div>
      </div>
    </>
  );
};
