import { useState } from 'react';
import {
  SimplySignIntro,
  SimplySignAuthorization,
  SimplySignSignDocument,
  SimplySignDownload,
} from 'shared-fe-components/src/providers/SimplySign/';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';
import { useConfigurationContext } from 'contexts';
import * as api from 'shared-fe-components/src/api/index';
import { useEffect } from 'react';
import { useSigningContext } from '../SigningContext';

export const SimplySignFlow = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [cards, setCards] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [signingStarted, setSigningStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('intro');
  const { providers } = useConfigurationContext();
  const { onCancel, goToProviderSelection, overlay, sign, getSignatureStatus, showPrivacyPolicy } = useSigningContext();

  const goToIntro = () => setStep('intro');
  const goToAuth = () => setStep('authorization');

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
      setIsLoading(false);
      setSigningStarted(false);
    }
  }, [overlay]);

  const onSignDocument = async () => {
    setIsLoading(true);
    setSigningStarted(true);

    const additionalParameters = {
      simplySignParameters: {
        cardData: {
          cardNo: cardNumber.replace(/ /g, ''),
          pin: pinNumber.replace(/ /g, ''),
        },
        accessToken: accessToken,
      },
    };

    await sign({ additionalParameters });
  };

  // status refetching effect
  useEffect(() => {
    const interval = setInterval(async () => {
      if (signingStarted) {
        await getSignatureStatus({});
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [getSignatureStatus, signingStarted]);

  const onSignBack = () => {
    goToIntro();
    setCardNumber('');
    setPinNumber('');
  };

  const onSimplySignAuth = async () => {
    setIsLoading(true);
    try {
      const url = `${window.location.origin}/providers/asseco/simplysign/auth-start.html?endpoint=${encodeURIComponent(
        providers.simplySign.authorizationEndpoint
      )}`;
      const authWin = window.open(url, 'auth');
      const authTimer = setInterval(async () => {
        if (authWin!.closed) {
          clearInterval(authTimer);
          const authCode = localStorage.getItem('code');
          if (authCode) {
            try {
              const tokenData = await api.simplySign.getAccessToken(authCode);
              setAccessToken(tokenData.accessToken);
              localStorage.removeItem('code');
              try {
                const cards = await api.simplySign.getCards(tokenData.accessToken);
                setCards(cards.data);
                setStep('sign');
              } catch {
                setStep('error');
              } finally {
                setIsLoading(false);
              }
            } catch {
              setStep('error');
              setIsLoading(false);
            }
          }
        }
      }, 1000);
    } catch {
      setStep('error');
      setIsLoading(false);
    }
  };

  if (step === 'intro') {
    return (
      <SimplySignIntro
        onCancel={onCancel}
        onBack={goToProviderSelection}
        onContinue={goToAuth}
        onShowPrivacyPolicy={showPrivacyPolicy}
        onShowDownload={() => setStep('download')}
      />
    );
  } else if (step === 'download') {
    return <SimplySignDownload onCancel={onCancel} onContinue={goToAuth} onBack={goToIntro} />;
  } else if (step === 'authorization') {
    return <SimplySignAuthorization onSimplySignAuth={onSimplySignAuth} isLoading={isLoading} onCancel={onCancel} onBack={goToIntro} />;
  } else if (step === 'error') {
    return <ErrorScreen processType="SimplySign" errorCode="SimplySign" onCancel={onCancel} onRetry={goToAuth} />;
  } else if (step === 'sign') {
    return (
      <SimplySignSignDocument
        onCancel={onCancel}
        onSignBack={onSignBack}
        onSignDocument={onSignDocument}
        isLoading={isLoading}
        cardNumber={cardNumber}
        pinNumber={pinNumber}
        cards={cards}
        onPinCodeChange={setPinNumber}
        onCardNumberChange={setCardNumber}
      />
    );
  } else {
    return <></>;
  }
};
