import { Button } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { DocumentSigningError, VerificationErrorIcon } from '../Icons';
import { ErrorScreenProps } from "./models";
import { WizardTintedBox } from '../WizardBox';

export const ErrorScreen = ({ message, errorCode, processType="Sign", onCancel, onRetry }: ErrorScreenProps) => {
  return (
    <WizardTintedBox color="red" role="alert">
      <div></div>
      <div>
      {processType==="Sign" ? <DocumentSigningError /> : <VerificationErrorIcon />}
        <h1>{t({ id: `StatusScreen.Error.${processType}.Subheader` })}</h1>
        <h2>{message || t({ id: `StatusScreen.Error.Code.${errorCode || "Unknown"}` })}</h2>
      </div>
      <div>
        <div></div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onRetry && <Button appearance="primary" onClick={onRetry}>{t({ id: 'Common.Retry' })}</Button>}
        </div>
      </div>
    </WizardTintedBox>
  );
};
