import { Text } from '@fluentui/react-components';
import { Info12Regular } from '@fluentui/react-icons';
import { Trans } from '@lingui/react';
import { Anchor } from '../Anchor';
import './LinkToPrivacyPolicy.scss';

export const LinkToPrivacyPolicy = ({ onShowPrivacyPolicy }: { onShowPrivacyPolicy: () => void }) => {
  return (
    <div className="link-to-privacy-policy">
      <Info12Regular />
      <Text>
        <Trans id="PrivacyPolicy.TextWithLink" components={[<Anchor onClick={onShowPrivacyPolicy} />]} />
      </Text>
    </div>
  );
};
