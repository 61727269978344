import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { CheckIcon } from '../Icons';
import { WizardTintedBox } from '../WizardBox';

export enum ProcessType {
  Sign = 'Sign',
  Verify = 'Verify',
  CertGen = 'CertGen'
}

interface SuccessScreenProps {
  processType?: ProcessType;
  onClose?: () => void;
  signaturesInSession?: number;
  isSigningOnMeeting?: boolean;
}

export const SuccessScreen = ({
  processType = ProcessType.Sign,
  onClose,
  signaturesInSession = 1,
  isSigningOnMeeting = false,
}: SuccessScreenProps) => {
  return (
    <WizardTintedBox color="brand" hideHeader={true} hideFooter={true} role="alert">
      <div></div>
      <div>
        <CheckIcon />
        <Text as="h1" weight="bold">
          {t({ id: `StatusScreen.Success.${processType}.Subheader` })}
        </Text>
        {processType === ProcessType.Sign && !isSigningOnMeeting && (
          <Text as="h2">
            {signaturesInSession === 1
              ? t({ id: `StatusScreen.Success.Sign.Description` })
              : t({ id: `StatusScreen.Success.Sign.Multiple.Signers.Description` })}
          </Text>
        )}
        {onClose && (
          <>
            <div></div>
            <Button appearance="outline" onClick={onClose}>
              {t({ id: 'Common.Close' })}
            </Button>
          </>
        )}
      </div>
      <div></div>
    </WizardTintedBox>
  );
};
