import { InperlyLogoSloganColor } from 'shared-fe-components/src/common/Icons';
import './DashboardLayoutHeader.scss';

export const DashboardLayoutHeader = () => {
  return (
    <div className="dashboard-layout-header">
      <InperlyLogoSloganColor alt="Inperly Logo" className="dashboard-layout-header__logo" />
    </div>
  );
};
