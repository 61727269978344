import { Theme } from '@fluentui/react-components';

export interface ThemeObject {
  theme: Theme;
  customColors: Record<string, string>;
  themeName: string;
  setTheme: (theme: string) => void;
}

// colors with _context_ prefix are for different frameContexts. available values are:
// settings, task, meetingStage, sidePanel, content
export const defaultCustomColors: Record<string, string> = {
  edoRed: '#C81224',
  edoWhite: '#fff',
  edoBlue: '#282E6F',
  idnow: '#ff6b40',
  inperly: '#4D49E1',
  simplySignBlue: '#11a4e0',
  _settings_background: '#ffffff',
  _task_background: '#ffffff',
  _sidePanel_background: '#1f1f1f',
} as const;

// dark colors overrides defaults
export const darkThemeCustomColors: Record<string, string> = {
  _settings_background: '#292929',
  _task_background: '#292929',
} as const;
