import { t } from '@lingui/macro';
import { Button, Divider, Text, Spinner } from '@fluentui/react-components';
import { Trans } from '@lingui/react';
import { Anchor } from '../../common/Anchor';
import { SimplySignIcon } from '../../common/Icons';
import { WizardBox } from '../../common/WizardBox/WizardBox';
import { SimplySignAuthorizationProps } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { Button as CustomButton } from 'shared-fe-components/src/common/FluentMigration';
import './SimplySignAuthorization.scss';

export const SimplySignAuthorization = ({
  onSimplySignAuth,
  onBack,
  isLoading,
  onCancel,
}: SimplySignAuthorizationProps) => {
  return (
    <WizardBox className="simply-sign-authorization" centerContent growContent={false}>
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Signing.SignHeader' })}
        </Text>
      </div>
      {!isLoading ? (
        <div className="simply-sign-authorization-color-wrapper">
          <div className="simply-sign-authorization-wrapper">
            <div className="simply-sign-authorization-content-wrapper">
              <SimplySignIcon className="simply-sign-authorization-logo" />
              <Text as="p">{t({ id: 'Providers.SimplySign.Authorization.Information' })}</Text>
              <CustomButton
                outline
                inverted
                className="simply-sign-authorization-button"
                onClick={onSimplySignAuth}
                disabled={isLoading}
              >
                {t({ id: 'Providers.SimplySign.Authorization.Button.Authorize' })}
              </CustomButton>
            </div>
            <Divider vertical />
            <div className="simply-sign-authorization-content-wrapper">
              <div />
              <Text as="p">
                <Trans
                  id="Providers.SimplySign.Authorization.Guide"
                  components={[
                    <Anchor
                      className="simply-sign-authorization-link"
                      href="https://sklep.certum.pl/simplysign.html"
                      target="_blank"
                    />,
                  ]}
                />
              </Text>
              <div />
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      <div>
        <div>{onBack && <ButtonBack onClick={onBack} />}</div>
        <div>{onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}</div>
      </div>
    </WizardBox>
  );
};
