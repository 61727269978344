import * as client from '../client';
import { RequestSignatureBody, SignParameters, UpdateSigningSessionBody } from './models';

export const selfSign = async ({ source, signatureProviderType, additionalParameters = {}, name, xadesFormat }: any) => {
  const { type } = source;
  const storageBody = {
    document: {
      sources: [
        {
          ...source,
        },
      ],
    },
    signatureProviderType,
    name,
    xadesFormat,
  };

  const nonstorageBody = {
    source: {
      teamsFileSource: source,
    },
    signatureProviderType,
    name,
    xadesFormat,
  };

  const body = type === 'StorageContainer' ? storageBody : nonstorageBody;
  return await client.post(`signing/self-sign`, { ...body, ...additionalParameters });
};

export const sign = async ({
  sessionId,
  signatureId,
  documentIds,
  signatureProviderType,
  additionalParameters = {},
}: SignParameters) => {
  const options = additionalParameters.code
    ? { additionalHeaders: { 'Inperly-Protection-Password': additionalParameters.code } }
    : {};

  return await client.post(
    `signing/sign`,
    {
      sessionId: sessionId,
      signatureId: signatureId,
      documentIds: documentIds,
      signatureProviderType,
      ...additionalParameters,
    },
    options
  );
};

export const signOrSelfSign = async (props: any) => (props.selfSign ? selfSign(props) : sign(props));

export const requestSignature = async (body: RequestSignatureBody) => {
  const payload: RequestSignatureBody = {
    carbonCopyRecipients: [],
    signatureType: 'Sequential',
    ...body,
  };

  return await client.post(`signing/request-signature`, payload as any);
};

// generateSourceUrl is used to get the original file source url, before being signed. It is used to view the original document
export const getSessionState = async (sessionId: string, generateSourceUrl: boolean = false) => {
  return await client.getSafe(`signing/sessions/${sessionId}?generateSourceUrl=${generateSourceUrl}`);
};

export const getSignatureStatus = async (sessionId: any, signatureId: any, code?: string) => {
  const options = code ? { additionalHeaders: { 'Inperly-Protection-Password': code } } : {};
  return await client.get(`signing/sessions/${sessionId}/signatures/${signatureId}`, options);
};

export const getSigningSessions = async (parameters = {}) => await client.post(`signing/sessions`, parameters as any);

export const sendManualReminder = async (payload: { sessionId: string; signatureId: string; message?: string }) => {
  const { sessionId, signatureId, message } = payload;
  const body = typeof message === 'string' ? { message: message } : undefined;
  return await client.post(`signing/remind/${sessionId}/${signatureId}`, body);
};

export const getSessionsStatistics = async () => await client.getSafe('signing/sessions/statistics');

export const cancelSigningSession = async (sessionId: any) =>
  await client.deleteRequest(`signing/sessions/${sessionId}`);

export const rejectSign = async (sessionId: any, signatureId: any, message: any) =>
  await client.postRaw(`signing/sessions/${sessionId}/signatures/${signatureId}/reject`, {
    message,
  } as any);

export const describeSessionFromGetSignatureStatus = ({ data, error }: any) => {
  const desc = {
    canRetry: true,
    errorCode: null,
    dummyProvider: null,
    signatureLocked: false,
  } as any;

  const status = data?.sessionResult ?? data?.sessionStatus;
  if (['Cancelled', 'Expired', 'Rejected'].includes(status)) {
    desc.canRetry = false;
    desc.errorCode = `Session.${status}`;
  } else if (data?.isLocked) {
    desc.dummyProvider = 'SignatureLocked';
  } else if (data?.status === 'ProviderPending') {
    desc.dummyProvider = 'ProviderPending';
  } else if (data?.status === 'Queued') {
    desc.dummyProvider = 'Queued';
  } else if (error) {
    desc.errorCode = 'Unknown';
    if (error instanceof client.HTTPError) {
      const httpStatus = error.response.status;
      if ([401, 409].includes(httpStatus)) {
        desc.canRetry = false;
        desc.errorCode = 'LinkExpired';
      } else if (httpStatus === 404) {
        desc.canRetry = false;
        desc.errorCode = 'DocumentDoesNotExist';
      }
    }
  }

  return desc;
};

export const describeSignatureFromGetSignatureStatus = ({ data, error }: any) => {
  const desc = {
    errorCode: null,
    signatureLocked: false,
    success: false,
    providerPending: false,
    timeout: false,
    failed: false,
    cancelled: false,
  } as any;

  if (data?.isLocked) {
    desc.signatureLocked = true;
  }

  if (data?.detailedError) {
    const detErr = data.detailedError;
    if (detErr.providerErrorCode === 'Unknown') {
      desc.errorCode = 'Unknown';
    } else if (detErr.providerType && detErr.providerErrorCode) {
      desc.errorCode = `${detErr.providerType}.${detErr.providerErrorCode}`;
    } else if (detErr.operationErrorCode) {
      desc.errorCode = detErr.operationErrorCode;
    } else {
      desc.errorCode = '';
    }
  }

  switch (data?.status) {
    case 'Succeeded':
      desc.success = true;
      break;
    case 'ProviderPending':
      desc.providerPending = true;
      break;
    case 'Cancelled':
      desc.cancelled = true;
      desc.errorCode = data.status;
      break;
    case 'Failed':
      desc.failed = true;
      break;
    default:
      break;
  }

  // fallback for when an error was thrown
  // but we haven't figured out the signing error
  // so it might be session error
  if (error && !desc.errorCode) {
    desc.errorCode = describeSessionFromGetSignatureStatus({
      data,
      error,
    }).errorCode;
  }

  return desc;
};

export const changeExpirationDate = async (sessionId: any, newExpirationDate: any) =>
  await client.put(`signing/sessions/${sessionId}/expiration`, {
    newExpirationDate,
  } as any);

export const archiveSigningSession = async (sessionId: any) =>
  await client.post(`signing/sessions/${sessionId}/archive`);

export const unarchiveSigningSession = async (sessionId: any) =>
  await client.post(`signing/sessions/${sessionId}/unarchive`);

export const deleteSigningSession = async (sessionId: any) =>
  await client.deleteRequest(`signing/sessions/${sessionId}`, undefined, 2);

export const requestOtp = async (sessionId: string, signatureId: string) => {
  await client.post(`signing/sessions/${sessionId}/signatures/${signatureId}/protection/request-otp`);
};

export const verifySignatureProtection = async (sessionId: string, signatureId: string, code: string) => {
  await client.post(`signing/sessions/${sessionId}/signatures/${signatureId}/protection/verify`, undefined, {
    token: null,
    additionalHeaders: { 'Inperly-Protection-Password': code },
  });
};

export const updateSigningSession = async (sessionId: string, body: UpdateSigningSessionBody): Promise<void> => {
  await client.put(`signing/sessions/${sessionId}`, body as any);
};

// gets list of available providers based on sessionId from token
export const getAvailableProviders = async () =>
  await client.get('signing/providers');
