var FaceTecOcrStrings = {
  "groups": [
    {
      "groupFriendlyName": "Su información",
      "fields": [
        {
          "fieldKey": "lastName",
          "fieldFriendlyName": "Apellidos",
          "uiFieldDescriptionText": "Last Name"
        },
        {
          "fieldKey": "firstName",
          "fieldFriendlyName": "Nombre",
          "uiFieldDescriptionText": "First Name"
        },
        {
          "fieldKey": "placeOfBirth",
          "fieldFriendlyName": "Lugar de nacimiento",
          "uiFieldDescriptionText": "Place of Birth"
        },
        {
          "fieldKey": "middleInitial",
          "fieldFriendlyName": "Inicial media",
          "uiFieldDescriptionText": "Middle Initial"
        },
        {
          "fieldKey": "nationality",
          "fieldFriendlyName": "Nacionalidad",
          "uiFieldDescriptionText": "Nationality"
        },
        {
          "fieldKey": "givenName",
          "fieldFriendlyName": "Nombre",
          "uiFieldDescriptionText": "Given Name"
        },
        {
          "fieldKey": "fullName",
          "fieldFriendlyName": "Nombre y apellidos",
          "uiFieldDescriptionText": "Full Name"
        },
        {
          "fieldKey": "dateOfBirth",
          "fieldFriendlyName": "Fecha de nacimiento",
          "uiFieldDescriptionText": "Date of Birth"
        },
        {
          "fieldKey": "middleName",
          "fieldFriendlyName": "Segundo nombre",
          "uiFieldDescriptionText": "Middle Name"
        }
      ],
      "groupKey": "userInfo"
    },
    {
      "groupFriendlyName": "Su dirección",
      "fields": [
        {
          "fieldKey": "zipCode",
          "fieldFriendlyName": "Código postal",
          "uiFieldDescriptionText": "Zip Code"
        },
        {
          "fieldKey": "country",
          "fieldFriendlyName": "País",
          "uiFieldDescriptionText": "Country"
        },
        {
          "fieldKey": "address3",
          "fieldFriendlyName": "Dirección Línea 3",
          "uiFieldDescriptionText": "Address Line 3"
        },
        {
          "fieldKey": "province",
          "fieldFriendlyName": "Provincia",
          "uiFieldDescriptionText": "Province"
        },
        {
          "fieldKey": "address2",
          "fieldFriendlyName": "Dirección Línea 2",
          "uiFieldDescriptionText": "Address #2"
        },
        {
          "fieldKey": "city",
          "fieldFriendlyName": "Ciudad",
          "uiFieldDescriptionText": "City"
        },
        {
          "fieldKey": "address1",
          "fieldFriendlyName": "Dirección Línea 1",
          "uiFieldDescriptionText": "Address Line 1"
        },
        {
          "fieldKey": "county",
          "fieldFriendlyName": "Condado",
          "uiFieldDescriptionText": "County"
        },
        {
          "fieldKey": "state",
          "fieldFriendlyName": "Estado",
          "uiFieldDescriptionText": "State"
        },
        {
          "fieldKey": "territory",
          "fieldFriendlyName": "Territorio",
          "uiFieldDescriptionText": "Territory"
        }
      ],
      "groupKey": "addressInfo"
    },
    {
      "groupFriendlyName": "Rasgos físicos y misceláneos",
      "fields": [
        {
          "fieldKey": "hair",
          "fieldFriendlyName": "Cabello",
          "uiFieldDescriptionText": "Hair"
        },
        {
          "fieldKey": "sex",
          "fieldFriendlyName": "Sexo",
          "uiFieldDescriptionText": "Gender"
        },
        {
          "fieldKey": "weight",
          "fieldFriendlyName": "Peso",
          "uiFieldDescriptionText": "Weight"
        },
        {
          "fieldKey": "eyes",
          "fieldFriendlyName": "Ojos",
          "uiFieldDescriptionText": "Eyes"
        },
        {
          "fieldKey": "height",
          "fieldFriendlyName": "Altura",
          "uiFieldDescriptionText": "Height"
        }
      ],
      "groupKey": "secondaryUserInfo"
    },
    {
      "groupFriendlyName": "Información adicional",
      "fields": [
        {
          "fieldKey": "customField5",
          "fieldFriendlyName": "Campo personalizado 5",
          "uiFieldDescriptionText": "Custom Field 5"
        },
        {
          "fieldKey": "customField1",
          "fieldFriendlyName": "Campo personalizado 1",
          "uiFieldDescriptionText": "Custom Field 1"
        },
        {
          "fieldKey": "customField2",
          "fieldFriendlyName": "Campo personalizado 2",
          "uiFieldDescriptionText": "Custom Field 2"
        },
        {
          "fieldKey": "customField3",
          "fieldFriendlyName": "Campo personalizado ",
          "uiFieldDescriptionText": "Custom Field 3"
        },
        {
          "fieldKey": "customField4",
          "fieldFriendlyName": "Campo personalizado ",
          "uiFieldDescriptionText": "Custom Field 4"
        }
      ],
      "groupKey": "customFields"
    },
    {
      "groupFriendlyName": "Detalles del documento",
      "fields": [
        {
          "fieldKey": "restrictions",
          "fieldFriendlyName": "Restricciones",
          "uiFieldDescriptionText": "Restrictions"
        },
        {
          "fieldKey": "passportType",
          "fieldFriendlyName": "Tipo de pasaporte",
          "uiFieldDescriptionText": "Passport Type"
        },
        {
          "fieldKey": "idNumber",
          "fieldFriendlyName": "Número del documento",
          "uiFieldDescriptionText": "Photo ID #"
        },
        {
          "fieldKey": "dateOfIssue",
          "fieldFriendlyName": "Fecha de emisión",
          "uiFieldDescriptionText": "Issued Date"
        },
        {
          "fieldKey": "idNumber2",
          "fieldFriendlyName": "ID secundario #",
          "uiFieldDescriptionText": "Secondary ID #"
        },
        {
          "fieldKey": "issuingAuthority",
          "fieldFriendlyName": "Autoridad emisora",
          "uiFieldDescriptionText": "Issuing Authority"
        },
        {
          "fieldKey": "dateOfExpiration",
          "fieldFriendlyName": "Fecha de caducidad",
          "uiFieldDescriptionText": "Expiration Date"
        },
        {
          "fieldKey": "countryCode",
          "fieldFriendlyName": "Código de país",
          "uiFieldDescriptionText": "Country Code"
        },
        {
          "fieldKey": "end",
          "fieldFriendlyName": "Fin",
          "uiFieldDescriptionText": "End"
        },
        {
          "fieldKey": "mrzLine3",
          "fieldFriendlyName": "MRZ 3",
          "uiFieldDescriptionText": "MRZ 3"
        },
        {
          "fieldKey": "barcode",
          "fieldFriendlyName": "Código de barras",
          "uiFieldDescriptionText": "Barcode"
        },
        {
          "fieldKey": "class",
          "fieldFriendlyName": "Clase",
          "uiFieldDescriptionText": "Class"
        },
        {
          "fieldKey": "mrzLine1",
          "fieldFriendlyName": "MRZ 1",
          "uiFieldDescriptionText": "MRZ 1"
        },
        {
          "fieldKey": "mrzLine2",
          "fieldFriendlyName": "MRZ 2",
          "uiFieldDescriptionText": "MRZ 2"
        }
      ],
      "groupKey": "idInfo"
    }
  ]
};

export default FaceTecOcrStrings;
