import { useCallback, useReducer, useRef } from 'react';
import { useLatestCallable } from './useLatestCallable';

const actionLoading = () => ({ type: 'loading' });
const actionResult = (result: any) => ({ type: 'result', result });
const actionError = (error: any) => ({ type: 'error', error });
const actionReset = () => ({ type: 'reset' });

const initialState = {
  pristine: true,
  loading: false,
  data: undefined,
  error: null,
};

const reducer = (state: any, action: any) => {
  switch (action?.type) {
    case 'loading': {
      return {
        ...state,
        pristine: false,
        loading: true,
      };
    }
    case 'result': {
      return {
        ...state,
        pristine: false,
        loading: false,
        data: action.result,
        error: null,
      };
    }
    case 'error': {
      return {
        ...state,
        pristine: false,
        loading: false,
        data: undefined,
        error: action.error,
      };
    }
    case 'reset': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export const useFetch = (fetcher: any) => {
  const counterRef = useRef(0);

  const [{ pristine, loading, data, error }, dispatch] = useReducer(reducer, initialState);

  const fetcherLatest = useLatestCallable(fetcher);

  const load = useCallback(
    async (...params: any) => {
      const counter = ++counterRef.current;

      try {
        dispatch(actionLoading());

        const result = await fetcherLatest(...params);
        if (counter === counterRef.current) {
          dispatch(actionResult(result));
        }
      } catch (error) {
        if (counter === counterRef.current) {
          dispatch(actionError(error));
        }
      }
    },
    [fetcherLatest]
  );

  const reset = useCallback(() => {
    ++counterRef.current;
    dispatch(actionReset());
  }, []);

  // Note: load and reset will be
  return { pristine, data, error, loading, load, reset };
};
