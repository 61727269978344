import { Button, Text } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { DashboardLayoutHeader } from '../../DashboardLayout/DashboardLayoutHeader';
import { t } from '@lingui/macro';

export interface OtpRequestProps {
  onRequest: () => void;
}

export const OtpRequest = ({ onRequest }: OtpRequestProps) => {
  return (
    <>
      <div></div>
      <div>
        <div className="otp__header-container">
          <DashboardLayoutHeader />
          <Text className="otp__label">{t({ id: 'Otp.Verification' })}</Text>
        </div>
        <div className="otp__body-container">
          <Header as="h1">{t({ id: 'Otp.Headers.GetFileAccess' })}</Header>
          <Text className="otp__label">{t({ id: 'Otp.GetAccessDescription' })}</Text>
        </div>
      </div>
      <div>
        <div></div>
        <div>
          <Button onClick={onRequest} appearance="primary">
            {t({ id: 'Otp.Buttons.RequestPassword' })}
          </Button>
        </div>
      </div>
    </>
  );
};
