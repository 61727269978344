import { ReactNode } from 'react';
import { Alert } from '@fluentui/react-components/unstable';
import { Warning24Regular } from '@fluentui/react-icons';
import './ActionableSection.scss';

interface ActionableSectionProps {
  icon: any;
  children?: ReactNode | ReactNode[];
  action?: string;
  onClick?: () => void;
  error?: string | null;
}

export const ActionableSection = ({ icon, children, action, onClick, error = null }: ActionableSectionProps) => {
  // FIXME remove action?
  const onClickFunction = (() => {
    if (!error && !!action && onClick) {
      return onClick;
    } else {
      return () => {};
    }
  })();

  const sectionClasses = (() => {
    if (error) return 'actionable-section actionable-section--disabled';
    if (onClick) return 'actionable-section actionable-section--clickable';
    return 'actionable-section';
  })();

  return (
    <button className={sectionClasses} onClick={onClickFunction}>
      <div className="actionable-section__icon">{icon}</div>
      <div className="actionable-section__content">{children}</div>
      {error && (
        <Alert intent="error" className="actionable-section__alert" icon={<Warning24Regular />}>
          {error}
        </Alert>
      )}
    </button>
  );
};
