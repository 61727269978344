import { createContext, useContext, useEffect, useMemo } from 'react';
import { Button, Text } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { useForm, FormProvider } from 'react-hook-form';
import { t } from '@lingui/macro';
import { WizardBox } from '../../common/WizardBox';
import { NationalityDropdown } from '../../common/ReactHookForm';
import { usePersistentStorage } from '../../hooks';
import { getFileInfo, ProviderSelectionProps, ProviderSelectionContextValues } from './models';
import { ProvidersList, lastSelectedCountryStorageKey } from '.';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import './ProviderSelection.scss';

export const ProviderSelection = ({
  files,
  onSelection,
  features,
  signing,
  xadesFormat,
  onBack,
  onCancel,
}: ProviderSelectionProps) => {
  const fileInfo = getFileInfo(files[0]);
  const isXML = useMemo<boolean>(() => fileInfo?.extension === 'xml', [fileInfo]);

  const [lastSelectedCountry, setLastSelectedCountry] = usePersistentStorage(lastSelectedCountryStorageKey);

  const countryFieldName = 'country';
  const formMethods = useForm({
    defaultValues: {
      [countryFieldName]: lastSelectedCountry ?? 'PL',
    },
  });
  const country = formMethods.watch(countryFieldName);

  useEffect(() => {
    setLastSelectedCountry(country);
  }, [country, setLastSelectedCountry]);

  return (
    <ProviderSelectionContext.Provider value={{ file: files[0], files, onSelection, features, signing, country, isXML, xadesFormat }}>
      <WizardBox>
        <div>
          <Header as="h1">{t({ id: 'ProviderSelection.Signing.Header', values: { fileName: fileInfo.name } })}</Header>
        </div>

        <div>
          <div>
            <FormProvider {...formMethods}>
              <Text className="provider-selection__country-selection-label">
                {t({ id: 'ProviderSelection.Signing.CountrySelectionLabel' })}
              </Text>
              <NationalityDropdown fieldName={countryFieldName} minMenuHeight={400} />
            </FormProvider>
          </div>
          <ProvidersList />
        </div>

        <div>
          <ButtonBack onClick={onBack} />
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
        </div>
      </WizardBox>
    </ProviderSelectionContext.Provider>
  );
};

const ProviderSelectionContext = createContext<ProviderSelectionContextValues | null>(null);

export const useProviderSelectionContext = () => {
  const ctx = useContext(ProviderSelectionContext);

  if (!ctx) {
    throw Error('Lack of ProviderSelectionContext.Provider in components tree');
  }

  return ctx;
};
