import { useState, useEffect } from 'react';
import { MszafirIntro } from 'shared-fe-components/src/providers/MSzafir';
import { MszafirSign } from './MszafirSign';
import { useConfigurationContext } from 'contexts';
import { useSigningContext } from '../SigningContext';

export const MszafirFlow = () => {
  const [step, setStep] = useState('intro');
  const [additionalParameters, setAdditionalParameters] = useState({});
  const { features } = useConfigurationContext();
  const { file, files, onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useSigningContext();

  const goToIntro = () => setStep('intro');

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  const onSelectCertificate = (certType: string) => {
    setAdditionalParameters({
      mszafirParameters: {
        signingMode: certType,
      },
    });
    setStep('sign');
  };

  return (
    <>
      {step === 'intro' && (
        <MszafirIntro
          features={features}
          fileName={file?.fileName}
          fileNames={files?.map((f) => f.fileName)}
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onSelectCertificate={onSelectCertificate}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'sign' && <MszafirSign additionalParameters={additionalParameters} onBack={goToIntro} onCancel={onCancel} />}
    </>
  );
};
