import { useEffect, useRef, useState } from 'react';
import { IdNowSign } from './IdNowSign';
import { IdNowForm, IdNowRequirements, IdNowDownload } from 'shared-fe-components/src/providers/IdNow';
import { useSigningContext } from '../SigningContext';

type Step = 'requirements' | 'form' | 'sign' | 'download';

export const IdNowFlow = () => {
  const [step, setStep] = useState<Step>('requirements');
  const { onCancel, goToProviderSelection, overlay, showPrivacyPolicy } = useSigningContext();

  const additionalParameters = useRef({});
  const formData = useRef({} as IdNowFormData);

  const goToRequirements = () => setStep('requirements');
  const goToDownload = () => setStep('download');
  const goToForm = () => setStep('form');
  const goToSign = () => setStep('sign');

  useEffect(() => {
    if (overlay !== null) {
      goToRequirements();
    }
  }, [overlay]);

  interface IdNowFormData {
    email: string;
    firstname: string;
    lastname: string;
    mobilephone: string;
    mobilephoneCode: string;
    mobilephoneNumber: string;
    nationality: string;
  }
  const handleFormData = (data: IdNowFormData) => {
    formData.current = data;
    additionalParameters.current = {
      ...additionalParameters.current,
      idNowParameters: { ...formData.current },
    };
  };

  const onBackInForm = (data: IdNowFormData) => {
    handleFormData(data);
    goToRequirements();
  };

  const onContinueInForm = (data: IdNowFormData) => {
    handleFormData(data);
    setStep('sign');
  };

  return (
    <>
      {step === 'requirements' && (
        <IdNowRequirements
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToForm}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'form' && <IdNowForm formData={formData.current} onBack={onBackInForm} onCancel={onCancel} onContinue={onContinueInForm} />}
      {step === 'sign' && (
        <IdNowSign
          additionalParameters={additionalParameters.current}
          onBack={goToForm}
          onCancel={onCancel}
          onGoToDownload={goToDownload}
        />
      )}
      {step === 'download' && <IdNowDownload onBack={goToSign} onCancel={onCancel} />}
    </>
  );
};
