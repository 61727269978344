import React from 'react';
import { Props } from './models';
import './Header.scss';

export const Header = ({ as = 'h1', children, content, className = '', setDefaultColor = true, ...args }: Props) => {
  const headerLevelClass = `ui-header header-${as} ${setDefaultColor ? 'header-default-color' : ''}`.trim();
  const childrenToPass = content ? [<>{content}</>] : children;
  const propsToPass = {
    ...args,
    className: `${headerLevelClass} ${className}`,
  };

  return React.createElement(as, propsToPass, childrenToPass);
};
