import { useState, useEffect, ChangeEvent } from 'react';
import { Button, Text, Spinner, Input, Dropdown, Option } from '@fluentui/react-components';
import { Dismiss16Regular } from '@fluentui/react-icons';
import { t } from '@lingui/macro';
import { WizardBox } from '../../common/WizardBox';
import { CardsItems, SimplySignCards, SimplySignSignDocumentProps } from './models';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { ErrorMessage, InputWithError } from 'shared-fe-components/src/common/WithError';
import './SimplySignSignDocument.scss';

export const SimplySignSignDocument = ({
  onSignBack,
  onCancel,
  onSignDocument,
  isLoading,
  cardNumber,
  pinNumber,
  cards,
  onCardNumberChange,
  onPinCodeChange,
}: SimplySignSignDocumentProps) => {
  const [isCardNumberValid, setIsCardNumberValid] = useState(true);
  const [isPinNumberValid, setIsPinNumberValid] = useState(true);
  const [pinErrorMessage, setPinErrorMessage] = useState('');
  const [cardErrorMessage, setCardErrorMessage] = useState('');

  const [cardsItems, setCardsItems] = useState<CardsItems[]>([]);

  const minPinLength = 6;
  const maxPinLength = 64;
  const pinRegex = new RegExp(`^[0-9]{${minPinLength},${maxPinLength}}$`);

  useEffect(() => {
    if (cards?.length) {
      const items = cards.map((card: SimplySignCards) => ({
        header: card.cardNo,
        content: card.label,
        value: card.cardNo,
      }));
      setCardsItems(items);
      onCardNumberChange(items[0].value);
    }
  }, [cards]);

  const verifyDocumentBeforeSigning = async () => {
    // Checking if the value is empty string
    if (cardNumber === '' || cardNumber === undefined) {
      setCardErrorMessage('Form.Errors.RequiredField');
      setIsCardNumberValid(false);
    }
    if (pinNumber === '' || pinNumber === undefined) {
      setPinErrorMessage(t({ id: 'Form.Errors.RequiredField' }));
      setIsPinNumberValid(false);
    }
    const pinOrCardNotEmpty = pinNumber !== '' || cardNumber !== '';
    const pinAndCardNotEmpty = pinNumber !== '' && cardNumber !== '';
    if (pinOrCardNotEmpty) {
      // Checking if the text is numeric only
      const isValidCardNumber = (str: string) => /^[0-9]*$/.test(str);
      if (!isValidCardNumber(cardNumber)) {
        setCardErrorMessage('Providers.SimplySign.Sign.Document.Card.Number.Error');
        setIsCardNumberValid(false);
      }
      const isValidPinNumber = (str: string) => pinRegex.test(str);
      if (!isValidPinNumber(pinNumber)) {
        setPinErrorMessage(
          t({
            id: 'Providers.SimplySign.Sign.Document.Pin.Number.Error',
            values: { minLength: minPinLength, maxLength: maxPinLength },
          })
        );
        setIsPinNumberValid(false);
      }
      if (onSignDocument) {
        pinAndCardNotEmpty && isValidCardNumber(cardNumber) && isValidPinNumber(pinNumber) && (await onSignDocument());
        onPinCodeChange('');
      }
    }
  };

  const handleCardNumberChange = (value: string) => {
    if (value !== '') setIsCardNumberValid(true);
    onCardNumberChange(value);
  };

  const handlePinCodeChange = (value: string) => {
    if (value !== '') setIsPinNumberValid(true);
    onPinCodeChange(value);
  };

  return (
    <WizardBox className="simply-sign-document">
      <div className="simply-sign-document__header">
        <Text as="h1" weight="bold">
          {t({ id: 'Signing.SignHeader' })}
        </Text>
        <br />
        <Text weight="semibold">{t({ id: 'Providers.SimplySign.Sign.Document.Guide' })}</Text>
      </div>
      {!isLoading ? (
        <div className="simply-sign-document-form">
          <div className="simply-sign-document-form__container">
            <Text weight="semibold" className="simply-sign-document-form__label">
              {t({ id: 'Providers.SimplySign.Sign.Document.Card.Number' })}
            </Text>
            <div className="simply-sign-document-form__input">
              <Dropdown
                className={`simply-sign-document-form__dropdown ${
                  !isCardNumberValid ? 'simply-sign-document-form__dropdown--error' : ''
                }`}
                placeholder={t({ id: 'Providers.SimplySign.Sign.Document.Card.Number.Placeholder' })}
                fluid
                appearance="filled-darker"
                value={cardNumber}
                error={!isCardNumberValid}
                onChange={(event: ChangeEvent<HTMLInputElement>, data: any) => handleCardNumberChange(data.value.value)}
              >
                {cardsItems.map((item) => (
                  <Option key={item.header} value={item.value} checkIcon={null}>
                    {item.header}
                    <br />
                    {item.content}
                  </Option>
                ))}
              </Dropdown>
              {!isCardNumberValid && <ErrorMessage message={t({ id: cardErrorMessage })} />}
            </div>
          </div>
          <div className="simply-sign-document-form__container">
            <Text weight="semibold" className="simply-sign-document-form__label">
              {t({ id: 'Providers.SimplySign.Sign.Document.Pin.Number' })}
            </Text>
            <div className="simply-sign-document-form__input">
              <InputWithError
                value={pinNumber}
                contentAfter={
                  <Button appearance="transparent" icon={<Dismiss16Regular />} onClick={() => onPinCodeChange('')} />
                }
                placeholder={t({ id: 'Providers.SimplySign.Sign.Document.Pin.Number.Placeholder' })}
                fluid
                appearance="filled-darker"
                type="password"
                error={!isPinNumberValid}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handlePinCodeChange(event.target.value)}
                errorMessage={!isPinNumberValid ? pinErrorMessage : ''}
              />
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      <div>
        <div>{onSignBack && <ButtonBack onClick={onSignBack} />}</div>
        <div>
          {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
          {onSignDocument && (
            <Button disabled={isLoading} appearance="primary" onClick={verifyDocumentBeforeSigning}>
              {t({ id: 'Common.Sign' })}
            </Button>
          )}
        </div>
      </div>
    </WizardBox>
  );
};
