import { ReactNode } from 'react';
import { ReactComponent as Dark } from './icons/Dark.svg';
import { ReactComponent as Light } from './icons/Light.svg';
import { ReactComponent as AutoDark } from './icons/AutoDark.svg';
import { ReactComponent as AutoLight } from './icons/AutoLight.svg';

export type Theme = 'default' | 'dark';

export interface State {
  icon: ReactNode;
  next: Theme;
  tooltip: string;
}

type States = {
  [key in Theme]: State;
};

export const states: States = {
  default: {
    icon: <Dark />,
    next: 'dark',
    tooltip: 'DashboardSignIntro.Theme.Switch.To.Dark',
  },
  dark: {
    icon: <Light />,
    next: 'default',
    tooltip: 'DashboardSignIntro.Theme.Switch.To.Light',
  },
};

export const autoStates: States = {
  default: {
    icon: <AutoDark />,
    next: 'dark',
    tooltip: 'DashboardSignIntro.Theme.Switch.To.Dark',
  },
  dark: {
    icon: <AutoLight />,
    next: 'default',
    tooltip: 'DashboardSignIntro.Theme.Switch.To.Light',
  },
};
