import { Button } from '@fluentui/react-components';
import { ChevronLeft16Filled } from '@fluentui/react-icons';
import { t } from '@lingui/macro';

type FluentButtonType = typeof Button;

export const ButtonBack = ({ children, ...rest }: FluentButtonType) => (
  <Button appearance="transparent" icon={<ChevronLeft16Filled />} {...rest}>
    {children ?? t({ id: 'Common.Back' })}
  </Button>
);
