import { Outlet } from 'react-router-dom';
import { useLayout } from 'contexts';
import './DashboardLayout.scss';

export const DashboardLayout = () => {
  const { dashboardSize } = useLayout();

  return (
    <div className="dashboard-layout">
      <div className="dashboard-layout__background-colors"></div>
      <div className="dashboard-layout__background-image"></div>
      <div className="dashboard-layout__content">
        <div className={`dashboard-layout__content-column ${dashboardSize === 'small' ? 'dashboard-layout__content-column--small' : ''}`}>
          <div className="dashboard-layout__content-main">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
