import { default as af } from './FaceTecStrings.af';
import { default as ar } from './FaceTecStrings.ar';
import { default as de } from './FaceTecStrings.de';
import { default as el } from './FaceTecStrings.el';
import { default as es } from './FaceTecStrings.es';
import { default as fr } from './FaceTecStrings.fr';
import { default as kk } from './FaceTecStrings.kk';
import { default as no } from './FaceTecStrings.no';
import { default as pl } from './FaceTecStrings.pl';
import { default as pt_br } from './FaceTecStrings.pt-br';
import { default as ru } from './FaceTecStrings.ru';

export default {
  af, ar, de, el, es, fr, kk, no, pl, ['pt-br']: pt_br, ru
}
