import { useEffect, useRef } from 'react';

import './Scrollable.scss';

const useScrollToEndObserver = (listener: any) => {
  // container must have "overflow: auto" or "overflow: scroll"
  const containerRef = useRef<any>();

  // marker must be the last stable element of the container
  const markerRef = useRef();

  const listenerRef = useRef<any>();
  listenerRef.current = listener;

  useEffect(() => {
    if (!containerRef.current) {
      throw new Error('containerRef must be set');
    }

    if (!markerRef.current) {
      throw new Error('markerRef must be set');
    }

    if (containerRef.current.lastChild !== markerRef.current) {
      throw new Error('markerRef must be the last element of containerRef');
    }

    // use IntersectionObserver to detect if scrolled to end
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          listenerRef.current?.();
        }
      },
      {
        root: containerRef.current,
      }
    );

    intersectionObserver.observe(markerRef.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  return {
    containerRef,
    markerRef,
  };
};

export const Scrollable = ({ children, onScrolledToEnd }: any) => {
  const { containerRef, markerRef }: any = useScrollToEndObserver(onScrolledToEnd);

  return (
    <div ref={containerRef} className="scrollable__container">
      {children}
      <div ref={markerRef} className="scrollable__marker"></div>
    </div>
  );
};
