var FaceTecStrings = {
  "FaceTec_action_ok": "OK",
  "FaceTec_action_im_ready": "ICH BIN BEREIT",
  "FaceTec_action_try_again": "ERNEUT VERSUCHEN",
  "FaceTec_action_continue": "FORTFAHREN",
  "FaceTec_action_take_photo": "FOTO AUFNEHMEN",
  "FaceTec_action_accept_photo": "ANNEHMEN",
  "FaceTec_action_retake_photo": "NOCH MAL",
  "FaceTec_action_confirm": "INFORMATION BESTÄTIGEN",

  "FaceTec_accessibility_cancel_button": "Abbrechen",
  "FaceTec_accessibility_tap_guidance": "Tippen Sie für Hilfe zur Positionierung Ihres Gesichts doppelt auf eine beliebige Stelle des Bildschirms.",
  "FaceTec_accessibility_key_down_guidance": "Drücken Sie die Eingabetaste oder die Leertaste, um eine Anleitung zur Gesichtsausrichtung zu erhalten",

  "FaceTec_accessibility_feedback_move_phone_away": "Ihr Gesicht ist zu nah",
  "FaceTec_accessibility_feedback_move_phone_closer": "Ihr Gesicht ist zu weit entfernt",
  "FaceTec_accessibility_feedback_face_too_far_left": "Ihr Gesicht ist zu weit links",
  "FaceTec_accessibility_feedback_face_too_far_right": "Ihr Gesicht ist zu weit rechts",
  "FaceTec_accessibility_feedback_face_too_low": "Ihr Gesicht ist zu niedrig",
  "FaceTec_accessibility_feedback_face_too_high": "Ihr Gesicht ist zu hoch",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Ihr Gesicht ist zu weit nach links gedreht",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Ihr Gesicht ist zu weit nach rechts gedreht",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Ihr Gesicht zeigt zu weit nach links",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Ihr Gesicht zeigt zu weit nach rechts",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Gesicht nicht in der Kamera oder zu weit weg",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Halten Sie Ihr Gerät auf Augenhöhe",
  "FaceTec_accessibility_feedback_move_away_web": "Kamera zu nah",
  "FaceTec_accessibility_feedback_move_closer_web": "Kamera zu weit weg",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Bewegen Sie die Kamera auf Augenhöhe",

  "FaceTec_presession_frame_your_face": "Positionieren Sie Ihr Gesicht im Oval",
  "FaceTec_presession_look_straight_ahead": "Schauen Sie geradeaus",
  "FaceTec_presession_hold_steady3": "Halten Sie still für: 3",
  "FaceTec_presession_hold_steady2": "Halten Sie still für: 2",
  "FaceTec_presession_hold_steady1": "Halten Sie still für: 1",
  "FaceTec_presession_eyes_straight_ahead": "Schauen Sie geradeaus",
  "FaceTec_presession_remove_dark_glasses": "Legen Sie Ihre Sonnenbrille ab",
  "FaceTec_presession_neutral_expression": "Gesichtsausdruck neutral, nicht lächeln",
  "FaceTec_presession_conditions_too_bright": "Die Umgebung ist zu hell",
  "FaceTec_presession_brighten_your_environment": "Beleuchten Sie Ihre Umgebung stärker",

  "FaceTec_feedback_center_face": "Positionieren Sie Ihr Gesicht mittig",
  "FaceTec_feedback_face_not_found": "Halten Sie Ihr Gesicht innerhalb des Rahmens",
  "FaceTec_feedback_move_phone_away": "Rücken Sie weiter weg",
  "FaceTec_feedback_move_away_web": "Rücken Sie weiter weg",
  "FaceTec_feedback_move_phone_closer": "Rücken Sie näher heran",
  "FaceTec_feedback_move_web_closer": "Bringe das Gesicht näher und fülle das Oval aus",
  "FaceTec_feedback_move_web_even_closer": "Noch näher",
  "FaceTec_feedback_move_phone_to_eye_level": "Halten Sie die Kamera auf Augenhöhe",
  "FaceTec_feedback_move_to_eye_level_web": "Schau direkt in die Kamera",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Schauen Sie geradeaus",
  "FaceTec_feedback_face_not_upright": "Halten Sie Ihren Kopf gerade",
  "FaceTec_feedback_face_not_upright_mobile": "Halten Sie Ihren Kopf gerade",
  "FaceTec_feedback_hold_steady": "Bewegen Sie sich nicht",
  "FaceTec_feedback_use_even_lighting": "Leuchten Sie Ihr Gesicht gleichmäßiger aus",

  "FaceTec_instructions_header_ready_desktop": "Bereiten Sie sich auf Ihr Video-Selfie vor",
  "FaceTec_instructions_header_ready_1_mobile": "Bereiten Sie sich auf",
  "FaceTec_instructions_header_ready_2_mobile": "Ihr Video-Selfie vor",
  "FaceTec_instructions_message_ready_desktop": "Bringe dein Gesicht ins Oval, drücke „Ich bin bereit“ und rücke näher ran",
  "FaceTec_instructions_message_ready_1_mobile": "Positionieren Sie Ihr Gesicht im Oval",
  "FaceTec_instructions_message_ready_2_mobile": "Drücke „Ich bin bereit“ und rücke näher ran",

  "FaceTec_retry_header": "Versuchen wir es noch einmal",
  "FaceTec_retry_subheader_message": "Wir brauchen ein schärferes Video-Selfie",
  "FaceTec_retry_your_image_label": "Ihr Selfie",
  "FaceTec_retry_ideal_image_label": "Optimale Haltung",
  "FaceTec_retry_instruction_message_1": "Pose und Licht müssen perfekt sein",
  "FaceTec_retry_instruction_message_2": "Gesichtsausdruck neutral, nicht lächeln",
  "FaceTec_retry_instruction_message_3": "Zu unscharf, bitte Kamera reinigen",

  "FaceTec_camera_feed_issue_header": "Problem durch Schutz der Kameraverbindung",
  "FaceTec_camera_feed_issue_header_mobile": "Problem durch Schutz<br/>der Kameraverbindung",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Diese Anwendung blockiert verdächtige Webkonfigurationen.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Weitere Informationen</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Dieses System kann aufgrund Folgendes nicht verifiziert werden:",
  "FaceTec_camera_feed_issue_table_header_1": "Mögliches Problem",
  "FaceTec_camera_feed_issue_table_header_2": "Richtig",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Die Kammer erlaubt in Firefox nicht erinnert.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Mark erinnern Sie sich die Berechtigungen.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Kammer bereits von einer anderen Anwendung verwendet.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Schließen Sie die andere Anwendung.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "Eine Anwendung des 3. Parks ändert das Video.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Die andere Anwendung schließen / deinstallieren.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Geräte, die nicht geschützt werden können.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Verwenden Sie ein anderes Gerät.",
  "FaceTec_camera_feed_issue_subtable_message": "Diese Anwendung blockiert verdächtige Webkonfigurationen. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Weitere Informationen</a>.",
  "FaceTec_camera_feed_issue_action": "ERNEUT VERSUCHEN",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

  "FaceTec_camera_permission_header": "Aktivieren Sie Ihre Kamera",
  "FaceTec_camera_permission_message": "Kameraberechtigungen deaktiviert.<br/>Überprüfen Sie die Einstellungen Ihres Betriebssystems und Browsers",

  "FaceTec_enter_fullscreen_header": "Vollbild-Selfie-Modus",
  "FaceTec_enter_fullscreen_message": "Bevor wir beginnen, klicken Sie bitte auf die Schaltfläche unten, um den Vollbildmodus zu öffnen.",
  "FaceTec_enter_fullscreen_action": "VOLLBILD ÖFFNEN",

  "FaceTec_initializing_camera": "Sichern der Kamerainformationen",

  "FaceTec_idscan_type_selection_header": "Bereiten Sie sich auf den Scan<br/>Ihres Ausweisdokuments vor",
  "FaceTec_idscan_capture_id_front_instruction_message": "Ausweis-Vorderseite vorzeigen",
  "FaceTec_idscan_capture_id_back_instruction_message": "Ausweis-Rückseite vorzeigen",
  "FaceTec_idscan_review_id_front_instruction_message": "Prüfe, ob das Bild lesbar ist.",
  "FaceTec_idscan_review_id_back_instruction_message": "Prüfe, ob das Bild lesbar ist.",
  "FaceTec_idscan_additional_review_message": "Zusätzliche Prüfung<br/>erforderlich",
  "FaceTec_idscan_ocr_confirmation_main_header": "Prüfen und bestätigen",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Scrollen Sie nach unten",
  "FaceTec_idscan_feedback_flip_id_to_back_message": "Ausweis-Rückseite vorzeigen",

  "FaceTec_result_success_message": "Erfolg",
  "FaceTec_result_facescan_upload_message": "Lade<br/>verschlüsselten<br/>3D-FaceScan hoch",
  "FaceTec_result_idscan_upload_message": "Lade<br/>verschlüsselten<br/>Ausweis-Scan hoch",
  "FaceTec_result_idscan_unsuccess_message": "Das Foto auf dem Ausweis<br/>stimmt nicht mit dem<br/>Gesicht des Benutzers überein",
  "FaceTec_result_idscan_success_front_side_message": "Ausweis-Scan abgeschlossen",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Vorderseite des Ausweises<br/>gescannt",
  "FaceTec_result_idscan_success_back_side_message": "Ausweis-Scan abgeschlossen",
  "FaceTec_result_idscan_success_passport_message": "Reisepass-Scan abgeschlossen",
  "FaceTec_result_idscan_success_user_confirmation_message": "Bildausweis-Scan<br/>abgeschlossen",
  "FaceTec_result_idscan_success_additional_review_message": "Erfassung des Ausweisfotos<br/>abgeschlossen",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Das Gesicht weicht<br/>zu stark ab",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Der Ausweis ist nicht<br/>vollständig sichtbar",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Der Text auf dem Ausweis ist nicht lesbar",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Dieser Ausweistyp wird nicht unterstützt,<br/>bitte verwenden Sie einen anderen Ausweis",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Der Barcode konnte nicht gescannt werden,<br/>bitte versuchen Sie es noch einmal",
};

module.exports = FaceTecStrings;
