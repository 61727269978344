import React, { Fragment, forwardRef } from 'react';
import { WarningCircleIcon } from '../Icons';
import { Input } from '@fluentui/react-components';
import { useController, Control, FieldValues } from 'react-hook-form';
import { Text } from '@fluentui/react-components';
import './WithError.scss';

type InputWithErrorProps = {
  errorMessage?: string;
  className?: string;
  flex?: boolean;
} & typeof Input;

type InputFormWithErrorProps = {
  errorMessage?: string;
  className?: string;
  flex?: boolean;
  fieldName: string;
  rules: Partial<Record<keyof FieldValues, Record<string, unknown>>>;
  control: Control<FieldValues>;
} & typeof Input;

type ErrorMessageProps = {
  message: string;
};

const Wrapper = ({ children, flex }: { children: React.ReactNode; flex?: boolean }) =>
  flex ? <div className="fui-Input validation-flex">{children}</div> : <>{children}</>;

const InputWithError = forwardRef<HTMLInputElement, InputWithErrorProps>(
  ({ errorMessage, flex, ...rest }: InputWithErrorProps, ref: Ref<HTMLInputElement>) => {
    const className = errorMessage ? `${rest?.className || ''} validation-error` : rest?.className;

    return (
      <Wrapper flex={flex}>
        <Input ref={ref} {...rest} className={className} contentAfter={errorMessage ? <WarningCircleIcon /> : null} />
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </Wrapper>
    );
  }
);
InputWithError.displayName = 'InputWithError';

const InputFormWithError = ({ errorMessage, fieldName, control, rules, flex, ...rest }: InputFormWithErrorProps) => {
  const { field } = useController({ name: fieldName, rules, control });

  const className = errorMessage ? `${rest?.className || ''} validation-error` : rest?.className;

  return (
    <Wrapper flex={flex}>
      <Input {...field} {...rest} className={className} contentAfter={errorMessage ? <WarningCircleIcon /> : null} />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Wrapper>
  );
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text className={`validation-message validation-message__error`}>{message}</Text>
);

export { InputWithError, ErrorMessage, InputFormWithError };
