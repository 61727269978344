import { ReactNode } from 'react';
import { contractValidator, isRecord } from 'shared-fe-components/src/tools';

/**Types */
type License = {
  isActive: boolean;
  isCancelled: boolean;
  isSuspended: boolean;
  licenseId: string;
  status: string;
  subscriptionId: string;
};

export type User = {
  clientId: string;
  email: string;
  id: string;
  license: License;
};

export interface UserObject {
  user: User | null;
  customToken: string | null;
  features: {
    [key: string]: boolean;
  };
  hasLicense: boolean;
}

export interface UserProviderProps {
  children: ReactNode;
}

/**Contracts */
const userContract = {
  clientId: (v: unknown) => typeof v === 'string',
  email: (v: unknown) => typeof v === 'string',
  id: (v: unknown) => typeof v === 'string',
  license: (v: unknown) => isLicense(v),
};

const licenseContract = {
  isActive: (v: unknown) => typeof v === 'boolean',
  isCancelled: (v: unknown) => typeof v === 'boolean',
  isSuspended: (v: unknown) => typeof v === 'boolean',
  licenseId: (v: unknown) => typeof v === 'string',
  status: (v: unknown) => typeof v === 'string',
  subscriptionId: (v: unknown) => typeof v === 'string',
};

const isLicense = (value: any): value is License => {
  return isRecord(value) && contractValidator(value, licenseContract).checkContract();
};

export const isUser = (value: any): value is User => {
  return isRecord(value) && contractValidator(value, userContract).checkContract();
};
