import { useLocalization } from '../../contexts/LocalizationContext';
import PropTypes from 'prop-types';
import countries from 'i18n-iso-countries';
import { useEffect, useState } from 'react';
import { Dropdown } from './Dropdown';
import { CountryFlag } from '../CountryFlags/CountryFlag';

interface NationalityDropdownProps {
  fieldName: string;
  minMenuHeight?: number;
  language?: string;
  menuPlacement?: string;
  className?: string;
  rules?: {};
  errorMessage?: string;
}

export const NationalityDropdown = (props: NationalityDropdownProps) => {
  const { locale = 'en-en' } = useLocalization() ?? 'en-en';
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(true);

  const createOption = ({ iso2Code, name, flagElement }: any) => ({
    value: iso2Code.toUpperCase(),
    labelForDisplaying: (
      <div>
        {flagElement} {name}
      </div>
    ),
    label: name,
  });

  useEffect(() => {
      const generalLocale = props.language ? props.language.split("-")[0] : locale.split("-")[0];
      countries.registerLocale(require(`i18n-iso-countries/langs/${generalLocale}.json`));
      const countryNames = countries.getNames(generalLocale, { select: 'official' });
      const countryCodesList = Object.keys(countryNames);
      const newCountryData = countryCodesList
          .map((iso2Code) => ({
            iso2Code,
            name: countryNames?.[iso2Code],
            flagElement: <CountryFlag iso2Code={iso2Code}/>,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      setOptions(newCountryData.map(createOption));
      setIsLoading(false);
  }, [locale]);


  const dropdownProps = {
    isLoading,
    options,
    filterOption: (option: any, inputValue: any) => option.data.label.toLowerCase().includes(inputValue.toLowerCase()),
    getOptionLabel: (option: any) => option.labelForDisplaying,
    styles: {
      menuList: (base: any) => ({
        ...base,
        maxHeight: '200px',
    })},
    ...props,
  };

  return <Dropdown {...dropdownProps} />;
};

NationalityDropdown.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
