import { Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import {
  EdoAppFingerprintIcon,
  IdNowIcon,
  InperlyPen,
  InperlyBlueOrWhiteIcon,
} from 'shared-fe-components/src/common/Icons';
import { ActionableSection } from 'shared-fe-components/src/common/ActionableSection';
import { useProviderSelectionContext } from '.';

export const EdoApp = () => {
  const { onSelection } = useProviderSelectionContext();

  return (
    <ActionableSection
      icon={<EdoAppFingerprintIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('Edo')}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.Edo.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.Edo.Description' })}
      </Text>
    </ActionableSection>
  );
};

export const IdNow = () => {
  const { onSelection } = useProviderSelectionContext();

  return (
    <ActionableSection icon={<IdNowIcon />} action={t({ id: 'Common.Proceed' })} onClick={() => onSelection('IdNow')}>
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.IdNow.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.IdNow.Description' })}
      </Text>
    </ActionableSection>
  );
};

export const FaceTec = () => {
  const { onSelection } = useProviderSelectionContext();

  return (
    <ActionableSection
      icon={<InperlyBlueOrWhiteIcon />}
      action={t({ id: 'Common.Proceed' })}
      onClick={() => onSelection('FaceTec')}
    >
      <Text block weight="semibold">
        {t({ id: 'ProviderSelection.FaceTec.Name' })}
      </Text>
      <Text block weight="regular">
        {t({ id: 'ProviderSelection.FaceTec.Description' })}
      </Text>
    </ActionableSection>
  );
};

interface EidEasyProviderProps {
  provider: string;
  name: string;
}

export const EidEasyProvider = ({ provider, name }: EidEasyProviderProps) => {
  const { onSelection } = useProviderSelectionContext();

  return (
    <ActionableSection icon={<InperlyPen />} action={t({ id: 'Common.Proceed' })} onClick={() => onSelection(provider)}>
      <Text block weight="semibold">
        {name}
      </Text>
    </ActionableSection>
  );
};

export const Providers: { [key: string]: () => JSX.Element } = {
  Edo: EdoApp,
  IdNow: IdNow,
  FaceTec: FaceTec,
};
