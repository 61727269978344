import { z } from "zod";

export function checkContract<T>(obj: unknown, zodType: z.ZodType<T>): T {
  if (process.env.NODE_ENV === 'development') {
    // we could simply use the below expression ...
    return zodType.parse(obj)
  } else {
    // but we're not brave enough in production:
    return obj as T;
  }
}
