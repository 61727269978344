import { useRef, useLayoutEffect } from "react";
import * as qr from "qr.js";

const defaultComputeOptions = {
  typeNumber: -1, // -1 - let it choose the best format
  errorCorrectLevel: qr.ErrorCorrectLevel.L // L - should generate the smallest version
};
const compute = (code: string) =>
  qr(code, defaultComputeOptions).modules;

const defaultRenderOptions = {
  minPadding: { tiles: 2, pixels: 7 },
  fill: "#000",
  background: "#fff",
};
const render = (data: any, canvas: any, options = {} as any) => {
  options = {
    ...defaultRenderOptions,
    ...options,
  };

  const width = options.width ?? canvas.width;
  const height = options.height ?? canvas.height;

  const context = canvas.getContext("2d");

  // clear whole canvas
  context.fillStyle = options.background;
  context.fillRect(0, 0, width, height);

  // QR should be rendered as square
  const squareSize = Math.min(width, height);

  // choose best fitting padding for the square
  const tileSize = Math.min(
    Math.floor((squareSize - 2 * options.minPadding.pixels) / data.length),
    Math.floor(squareSize / (data.length + options.minPadding.tiles * 2))
  );

  const startX = Math.floor((width - tileSize * data.length) / 2);
  const startY = Math.floor((height - tileSize * data.length) / 2);

  // paint only the black pixels
  context.fillStyle = options.fill;
  for (let rowIndex = 0; rowIndex < data.length; ++rowIndex) {
    const row = data[rowIndex];

    for (let cellIndex = 0; cellIndex < row.length; ++cellIndex) {
      if (row[cellIndex]) {
        const x = startX + cellIndex * tileSize;
        const y = startY + rowIndex * tileSize;

        context.fillRect(x, y, tileSize, tileSize);
      }
    }
  }
};

interface QRCanvasProps {
  code: string,
  width: number,
  height: number,
  [props: string]: any
}
export const QRCanvas = ({ code, width, height, ...props }: QRCanvasProps) => {
  const canvasRef = useRef<any>();

  useLayoutEffect(() => {
    if (!canvasRef.current) {
      console.error("QRCanvas – canvas not supported");
      return;
    }

    if (width > 0 && height > 0) {
      render(compute(code), canvasRef.current, { width, height });
    }
  }, [code, width, height]);

  return <canvas ref={canvasRef} width={width} height={height} {...props} />;
};
