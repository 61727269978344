import { ReactNode, HTMLAttributes } from 'react';
import './WizardBox.scss';

/**
 * Needs three+ divs as children (header, content+, and buttons)
 *
 * @param {boolean} growContent true makes content take up whole space (buttons will stick to the bottom of the container)
 */
interface WizardBoxProps extends HTMLAttributes<HTMLDivElement> {
  growContent?: boolean,
  centerContent?: boolean,
  className?: string,
  children: ReactNode,
  rest?: any
}

export const WizardBox = ({
  growContent = true,
  centerContent = false,
  className: additionalClassName,
  children,
  ...rest
}: WizardBoxProps) => {
  const boxProps = {
    className:
      `wizard-box ` +
      `${growContent ? 'wizard-box--grow-content' : ''} ` +
      `${centerContent ? 'wizard-box--center-content' : ''} ` +
      `${additionalClassName || ''}`,
    ...rest,
  };
  return <div {...boxProps}>{children}</div>;
};
