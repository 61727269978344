import { useParams } from 'react-router-dom';
import './Certificate.scss';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { DashboardLayoutHeader } from '../DashboardLayout/DashboardLayoutHeader';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { ThemeSwitch } from 'components/Common/ThemeSwitch';
import * as api from 'shared-fe-components/src/api/index';
import { useFetch } from 'lib/useFetch';
import { ReactNode, useEffect, useState } from 'react';
import { MSzafirCertificateType } from './models';
import { CertificateIntro } from './CertificateIntro';
import { Button } from '@fluentui/react-components';
import { HTTPError } from 'ky';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { ErrorScreen, ProcessType, SuccessScreen } from 'shared-fe-components/src/common/StatusScreens';
import { t } from '@lingui/macro';

const fetchData = async (sessionId: any, certificateId: any) =>
  await api.certificate.getCertificateStatus(sessionId, certificateId);

enum ScreenType {
    Intro,
    Success,
    Error,
    Loading
};

type ScreenViews = {
    [screen in ScreenType]: ReactNode;
}

export const Certificate = () => {
    const { sessionId, certificateId } = useParams();
    const { pristine, loading, data, error, load, reset } = useFetch(fetchData);
    const [ certificateType, setCertificateType ] = useState<MSzafirCertificateType | null>(null);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ screenType, setScreenType ] = useState(ScreenType.Loading);
    const [ generateCalled, setGenerateCalled ] = useState(false);

    const handleCertTypeChanged = (certType: string | undefined): void => {
        setCertificateType(certType as unknown as MSzafirCertificateType);
    }

    const screenViews: ScreenViews = {
        [ScreenType.Intro]: <CertificateIntro data={data} certificateType={certificateType} onCertTypeChanged={handleCertTypeChanged} />,
        [ScreenType.Success]: <SuccessScreen processType={ProcessType.CertGen} />,
        [ScreenType.Error]: <ErrorScreen message={errorMessage} processType={ProcessType.CertGen} />,
        [ScreenType.Loading]: <div><LoadingScreen /></div>
    };

    useEffect(() => {
        if (sessionId && certificateId) {
          load(sessionId, certificateId);
        } else {
          reset();
        }
      }, [sessionId, certificateId, load, reset]);

    useEffect(() => {
        if(data !== undefined && data !== null)
        {
            if(data.providerParameters?.mszafirParameters?.certificateType !== null)
                setCertificateType(data.providerParameters.mszafirParameters.certificateType);
            else
                setCertificateType(null);

            setScreenType(ScreenType.Intro);
        }
    }, [data])


    const generateCertificate = async () => {
        try
        {
            setGenerateCalled(true);
            await api.certificate.generateCertificate(data.sessionId, data.certificateId);
            setScreenType(ScreenType.Success);
        }catch(error: any)
        {
            if(error instanceof HTTPError) {
                let sender = data.sender.email;
                switch((error as HTTPError).response.status)
                {
                    case 400:
                        setErrorMessage(t({id: "DashboardCertificate.ProviderError"}));
                        break;

                    case 401:
                    case 403:
                        setErrorMessage(t({id: "DashboardCertificate.Forbidden", values: {sender}}));
                        break;

                    case 404:
                        setErrorMessage(t({id: "DashboardCertificate.NotFound", values: {sender}}));
                        break;
                    default:
                        setErrorMessage(t({id: "DashboardCertificate.UnknownError"}));
                }
            }

            setScreenType(ScreenType.Error);
        }
    }

    const handleSendButtonClick = async () => {
        setScreenType(ScreenType.Loading);
        
        await generateCertificate();
    }

    return (
        <>
            <WizardBox>
                <div className="dashboard-certificate-header__title">
                    <DashboardLayoutHeader />
                    <div className="dashboard-certificate-header__options">
                    <div className="dashboard-certificate-header__language">
                        <LanguageDropdown />
                    </div>
                    <ThemeSwitch />
                    </div>
                </div>
                {screenViews[screenType]}
                {!generateCalled &&
                    <div>
                        <Button disabled={certificateType == null} appearance='primary' onClick={handleSendButtonClick}>{t({id: "DashboardCertificate.Generate"})}</Button>
                    </div>
                }
            </WizardBox>
        </>
    )
}