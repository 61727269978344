import { useState } from 'react';
import { Text, Button } from '@fluentui/react-components';
import { Header } from 'shared-fe-components/src/common/Header';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Anchor } from 'shared-fe-components/src/common/Anchor';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { RejectSign } from './RejectSign';
import { OtpVerification } from '../OtpVerification';
import DocumentContentBackgroundIcon from './assets/document-content-background.svg';
import { ProtectionType } from 'shared-fe-components/src/common/Constants';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { ThemeSwitch } from 'components/Common/ThemeSwitch';
import { DashboardLayoutHeader } from '../../DashboardLayout/DashboardLayoutHeader';
import { FilesIcon } from 'shared-fe-components/src/common/FilesIcon';
import { extractFileFormat } from 'shared-fe-components/src/utils';
import './Intro.scss';

type IntroScreen = 'intro' | 'reject' | 'otp';

const FileWrapper = ({ fileName }: { fileName: string }) => {
  return (
    <>
      <img alt="" src={DocumentContentBackgroundIcon} className="dashboard-sign-intro__file-wrapper" />
      <div className="dashboard-sign-intro__file-name">
        <FilesIcon fileType={extractFileFormat(fileName)} />
        <Text className="dashboard-sign-intro__file-text">{fileName}</Text>
      </div>
    </>
  );
};

interface IntroProps {
  sender: string;
  files: {
    documentId: string;
    downloadUrl: string;
    fileName: string;
    fileSize: number;
    signedFileDownloadInfo: any;
  }[];
  protectionType: ProtectionType;
  otpCode: string | null;
  onContinue: () => void;
  onSign: (documentId: string) => void;
  onSignAll: () => void;
}

export const Intro = ({ sender, files, protectionType = ProtectionType.None, otpCode = null, onContinue, onSign, onSignAll }: IntroProps) => {
  const [currentScreen, setCurrentScreen] = useState<IntroScreen>(
    protectionType === ProtectionType.OneTimePassword && !otpCode ? 'otp' : 'intro'
  );

  if (currentScreen === 'reject') {
    return <RejectSign onBack={() => setCurrentScreen('intro')} />;
  } else if (currentScreen === 'otp') {
    return <OtpVerification />;
  } else {
    const multipleDocuments = files && files.length > 1;

    return (
      <WizardBox className="dashboard-sign-intro">
        <div className="dashboard-sign-intro__header">
          <DashboardLayoutHeader />
          <div className="dashboard-sign-intro__options">
            <div className="dashboard-sign-intro__language">
              <LanguageDropdown />
            </div>
            <ThemeSwitch />
          </div>
        </div>
        <div>
          <Header as="h1">{t({ id: 'Common.Greeting' })}</Header>
          <Text as="p">
            <Trans id="DashboardSignIntro.Introduction" values={{ sender: sender }} components={[<Anchor href={`mailto:${sender}`} />]} />
          </Text>
          <div className='dashboard-sign-intro__files-container'>
            {files && files.map(file => (
              <div key={file.documentId} className="dashboard-sign-intro__file">
                <FileWrapper fileName={file.fileName} />
                {!multipleDocuments && (
                  <>
                    <button className="dashboard-sign-intro__file-sign" onClick={() => onSign(file.documentId)}>
                      {t({ id: 'Common.Sign' })}
                    </button>
                    <div style={{ display: 'flex', width: '100%' }}>
                      {file.downloadUrl && (
                        <div className="dashboard-sign-intro__file-signature-preview">
                          <Anchor href={file.downloadUrl} target="_blank">
                            {t({ id: 'DashboardSignIntro.File.Preview' })}
                          </Anchor>
                        </div>
                      )}
                      <button className="dashboard-sign-intro__file-refuse" onClick={() => setCurrentScreen('reject')}>
                        {t({ id: 'Common.Refuse' })}
                      </button>
                    </div>
                  </>
                )}
                {multipleDocuments && (
                  <div style={{ display: 'flex', width: '100%' }}>
                    {file.downloadUrl && (
                      <div className="dashboard-sign-intro__file-signature-preview-full-width">
                        <Anchor href={file.downloadUrl} target="_blank">
                          {t({ id: 'DashboardSignIntro.File.Preview' })}
                        </Anchor>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          {multipleDocuments && <>
            <div className="dashboard-sign-intro__batch-actions">
              <button className="dashboard-sign-intro__batch-actions-sign" onClick={onSignAll}>
                {t({ id: 'Common.SignAll' })}
              </button>
              <button className="dashboard-sign-intro__batch-actions-refuse" onClick={() => setCurrentScreen('reject')}>
                {t({ id: 'Common.Refuse' })}
              </button>
            </div>
          </>}
        </div>
        <div></div>
      </WizardBox>
    );
  }
};
