var FaceTecStrings = {
  "FaceTec_action_ok": "OK",
  "FaceTec_action_im_ready": "JE SUIS PRÊT(E)",
  "FaceTec_action_try_again": "RÉESSAYER",
  "FaceTec_action_continue": "CONTINUER",
  "FaceTec_action_take_photo": "PRENDRE UNE PHOTO",
  "FaceTec_action_accept_photo": "ACCEPTER",
  "FaceTec_action_retake_photo": "REPRENDRE",
  "FaceTec_action_confirm": "CONFIRMEZ LES INFOS",

  "FaceTec_accessibility_cancel_button": "Annuler",
  "FaceTec_accessibility_tap_guidance": "Tapez deux fois n'importe où sur l'écran pour obtenir des indications sur l'alignement du visage.",
  "FaceTec_accessibility_key_down_guidance": "Appuyez sur Entrée ou sur la barre d'espace pour obtenir des conseils sur l'alignement des visages",

  "FaceTec_accessibility_feedback_move_phone_away": "Visage trop proche",
  "FaceTec_accessibility_feedback_move_phone_closer": "Visage trop éloigné",
  "FaceTec_accessibility_feedback_face_too_far_left": "Visage trop à gauche",
  "FaceTec_accessibility_feedback_face_too_far_right": "Visage trop à droite",
  "FaceTec_accessibility_feedback_face_too_low": "Visage trop bas",
  "FaceTec_accessibility_feedback_face_too_high": "Visage trop haut",
  "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Visage tourné trop à gauche",
  "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Visage tourné trop à droite",
  "FaceTec_accessibility_feedback_face_looking_too_far_left": "Visage braqué trop à gauche",
  "FaceTec_accessibility_feedback_face_looking_too_far_right": "Visage braqué trop à droite",
  "FaceTec_accessibility_feedback_face_not_in_camera": "Visage pas sur la caméra ou trop éloigné",
  "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Tenez l'appareil au niveau des yeux",
  "FaceTec_accessibility_feedback_move_away_web": "Caméra trop proche",
  "FaceTec_accessibility_feedback_move_closer_web": "Caméra trop loin",
  "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Déplacer la caméra au niveau des yeux",

  "FaceTec_presession_frame_your_face": "Cadrez votre visage dans l'ovale",
  "FaceTec_presession_look_straight_ahead": "Regardez droit devant",
  "FaceTec_presession_hold_steady3": "Restez stable pendant : 3",
  "FaceTec_presession_hold_steady2": "Restez stable pendant : 2",
  "FaceTec_presession_hold_steady1": "Restez stable pendant : 1",
  "FaceTec_presession_eyes_straight_ahead": "Regardez droit devant",
  "FaceTec_presession_remove_dark_glasses": "Retirez les lunettes noires",
  "FaceTec_presession_neutral_expression": "Expression neutre, pas de sourire",
  "FaceTec_presession_conditions_too_bright": "Conditions trop lumineuses",
  "FaceTec_presession_brighten_your_environment": "Illuminez votre environnement",

  "FaceTec_feedback_center_face": "Centrez votre visage",
  "FaceTec_feedback_face_not_found": "Cadrez votre visage",
  "FaceTec_feedback_move_phone_away": "Éloignez-vous",
  "FaceTec_feedback_move_away_web": "Éloignez-vous",
  "FaceTec_feedback_move_phone_closer": "Approchez-vous",
  "FaceTec_feedback_move_web_closer": "Rapprochez le visage et remplissez l'ovale",
  "FaceTec_feedback_move_web_even_closer": "Encore Plus Près",
  "FaceTec_feedback_move_phone_to_eye_level": "Déplacez la caméra au niveau des yeux",
  "FaceTec_feedback_move_to_eye_level_web": "Regardez Directement La Caméra",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Regardez droit devant",
  "FaceTec_feedback_face_not_upright": "Tenez votre tête droite",
  "FaceTec_feedback_face_not_upright_mobile": "Tenez votre tête droite",
  "FaceTec_feedback_hold_steady": "Restez stable",
  "FaceTec_feedback_use_even_lighting": "Éclairez le visage de manière plus uniforme",

  "FaceTec_instructions_header_ready_desktop": "Préparez-vous pour Votre selfie vidéo",
  "FaceTec_instructions_header_ready_1_mobile": "Préparez-vous pour",
  "FaceTec_instructions_header_ready_2_mobile": "Votre selfie vidéo",
  "FaceTec_instructions_message_ready_desktop": "Cadrez le visage dans l'ovale, cliquez sur Je suis prêt(e) et rapprochez-vous",
  "FaceTec_instructions_message_ready_1_mobile": "Cadrez le visage dans l'ovale",
  "FaceTec_instructions_message_ready_2_mobile": "Cliquez Je suis prêt(e) et rapprochez-vous",

  "FaceTec_retry_header": "Essayons à nouveau",
  "FaceTec_retry_subheader_message": "Il nous faut un selfie vidéo plus clair",
  "FaceTec_retry_your_image_label": "Votre selfie",
  "FaceTec_retry_ideal_image_label": "Pose idéale",
  "FaceTec_retry_instruction_message_1": "Utilisez la pose et l’éclairage idéaux",
  "FaceTec_retry_instruction_message_2": "Expression neutre, pas de sourire",
  "FaceTec_retry_instruction_message_3": "Trop flou, nettoyez la caméra",

  "FaceTec_camera_feed_issue_header": "Problème de protection de la connexion de la caméra",
  "FaceTec_camera_feed_issue_header_mobile": "Problème de protection<br/>de la connexion de la caméra",
  "FaceTec_camera_feed_issue_subheader_message_mobile": "Cette application bloque les paramètres de webcam suspects.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>En savoir plus ici.</a>.",
  "FaceTec_camera_feed_issue_subheader_message": "Ce système ne peut pas être vérifié pour les raisons suivantes:",
  "FaceTec_camera_feed_issue_table_header_1": "Problème Possible",
  "FaceTec_camera_feed_issue_table_header_2": "Correct",
  "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Autorisations de l'appareil photo non mémorisées dans Firefox.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Cochez Mémoriser les autorisations.",
  "FaceTec_camera_feed_issue_table_row_1_cell_1": "Appareil photo déjà utilisé par une autre application.",
  "FaceTec_camera_feed_issue_table_row_1_cell_2": "Fermez l'autre application.",
  "FaceTec_camera_feed_issue_table_row_2_cell_1": "Une application tierce modifie la vidéo.",
  "FaceTec_camera_feed_issue_table_row_2_cell_2": "Fermez/désinstallez l'autre application.",
  "FaceTec_camera_feed_issue_table_row_3_cell_1": "Équipement qui ne peut pas être protégé.",
  "FaceTec_camera_feed_issue_table_row_3_cell_2": "Utilisez un autre appareil.",
  "FaceTec_camera_feed_issue_subtable_message": "Cette application bloque les paramètres de webcam suspects. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>En savoir plus ici.</a>.",
  "FaceTec_camera_feed_issue_action": "RÉESSAYER",
  "FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

  "FaceTec_camera_permission_header": "Activez la caméra",
  "FaceTec_camera_permission_message": "Autorisations de la caméra désactivées.<br/>Vérifiez les paramètres de votre système d'exploitation et de votre navigateur",

  "FaceTec_enter_fullscreen_header": "Mode Selfie Plein Écran",
  "FaceTec_enter_fullscreen_message": "Avant de commencer, veuillez cliquer sur le bouton ci-dessous pour ouvrir le mode plein écran.",
  "FaceTec_enter_fullscreen_action": "OUVRIR EN PLEIN ÉCRAN",

  "FaceTec_initializing_camera": "Sécurisation flux de caméra",

  "FaceTec_idscan_type_selection_header": "Préparez-vous à numériser<br/>Votre pièce d'identité",
  "FaceTec_idscan_capture_id_front_instruction_message": "Montrez le recto de l'ID",
  "FaceTec_idscan_capture_id_back_instruction_message": "Montrez le verso de l'ID",
  "FaceTec_idscan_review_id_front_instruction_message": "Confirmez photo clair et lisible",
  "FaceTec_idscan_review_id_back_instruction_message": "Confirmez photo clair et lisible",
  "FaceTec_idscan_additional_review_message": "Examen supplémentaire<br/>Requis",
  "FaceTec_idscan_ocr_confirmation_main_header": "Examiner et confirmer",
  "FaceTec_idscan_ocr_confirmation_scroll_message": "Défiler vers le bas",
  "FaceTec_idscan_feedback_flip_id_to_back_message": "Montrez le verso de l'ID",

  "FaceTec_result_success_message": "Succès",
  "FaceTec_result_facescan_upload_message": "Téléchargement<br/>FaceScan 3D<br/>Crypté",
  "FaceTec_result_idscan_upload_message": "Téléchargement<br/>Scan d'identité<br/>Crypté",
  "FaceTec_result_idscan_unsuccess_message": "La photo d'identité<br/>Ne correspond pas<br/>Au visage de l'utilisateur",
  "FaceTec_result_idscan_success_front_side_message": "Scan d'identité terminé",
  "FaceTec_result_idscan_success_front_side_back_next_message": "Recto de la pièce d'identité<br/>Scanné",
  "FaceTec_result_idscan_success_back_side_message": "Scan d'identité terminé",
  "FaceTec_result_idscan_success_passport_message": "Scan du passeport terminé",
  "FaceTec_result_idscan_success_user_confirmation_message": "Scan de la photo d'identité<br/>Terminé",
  "FaceTec_result_idscan_success_additional_review_message": "Capture de la photo d'identité<br/>Terminée",
  "FaceTec_result_idscan_retry_face_did_not_match_message": "Le visage ne correspond<br/>Pas assez",
  "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Document d'identité<br/>Pas entièrement visible",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Texte d'identification illisible",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Type de pièce d'identité non pris en charge<br/>Veuillez utiliser une autre pièce d'identité",
  "FaceTec_result_idscan_retry_barcode_not_read_message": "Échec du scan du code-barres<br/>Veuillez réessayer",
};

module.exports = FaceTecStrings;
