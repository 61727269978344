import { useCallback, useEffect, useMemo } from 'react';
import { useSigningContext } from './SigningContext';
import {
  ProviderPendingScreen,
  QueuedScreen,
  SignatureLockedScreen,
} from 'shared-fe-components/src/common/StatusScreens';
import * as api from 'shared-fe-components/src/api';

export const DummyProvider = () => {
  const { selectedProvider, setSelectedProvider, overlay, getSignatureStatus } = useSigningContext();
  const dummyProvider = useMemo(() => selectedProvider?.split(':')?.[1], [selectedProvider]);

  const leaveToProviderSelection = useCallback(() => {
    setSelectedProvider(null);
  }, [setSelectedProvider]);

  // the idea behind this effect is different to other providers
  // triggering an error or timeout screen in context
  // will cause leaving to provider selection – user might try to sign again
  useEffect(() => {
    if (overlay !== null) {
      leaveToProviderSelection();
    }
  }, [overlay, leaveToProviderSelection]);

  // status refetching effect
  useEffect(() => {
    const interval = setInterval(async () => {
      const dataError = await getSignatureStatus({});
      const desc = api.signing.describeSessionFromGetSignatureStatus(dataError);
      if (desc.dummyProvider === null) {
        leaveToProviderSelection();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [getSignatureStatus, leaveToProviderSelection]);

  if (dummyProvider === 'ProviderPending') {
    return <ProviderPendingScreen />;
  } else if (dummyProvider === 'Queued') {
    return <QueuedScreen />;
  } else if (dummyProvider === 'SignatureLocked') {
    return <SignatureLockedScreen />;
  } else {
    return <div></div>;
  }
};
