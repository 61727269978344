import { useState } from 'react';
import { DashboardLayoutHeader } from '../../DashboardLayout/DashboardLayoutHeader';
import { t } from '@lingui/macro';
import * as api from 'shared-fe-components/src/api/index';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { TextAreaWithCounter } from 'shared-fe-components/src/common/TextAreaWithCounter/TextAreaWithCounter';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { ErrorScreen } from 'shared-fe-components/src/common/StatusScreens';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';
import { useForm, Controller } from 'react-hook-form';
import { Button, Text } from '@fluentui/react-components';
import { DismissCircle48Filled } from '@fluentui/react-icons';
import './DenyVerification.scss';

interface DenyVerificationProps {
  sessionId: string;
  verificationId: string;
  onStepSelect?: DenySteps;
  onBack: () => void;
}

export enum DenySteps {
  Intro = 1,
  Loading = 2,
  Error = 3,
  Denied = 4,
}

export const DenyVerification = ({ sessionId, verificationId, onStepSelect, onBack }: DenyVerificationProps) => {
  const [currentScreen, setCurrentScreen] = useState<DenySteps>(onStepSelect || DenySteps.Intro);

  const formMethods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      textarea: '',
    },
    mode: 'onChange',
  });
  const { control, getValues, watch } = formMethods;

  const messageValue = watch('textarea', '');

  const onContinue = async () => {
    const message = getValues('textarea');
    setCurrentScreen(DenySteps.Loading);
    try {
      const deny = await api.verification.denyVerification(sessionId, verificationId, message);
      if (deny) setCurrentScreen(DenySteps.Denied);
    } catch (err) {
      setCurrentScreen(DenySteps.Error);
    }
  };

  return (
    <>
      {currentScreen === DenySteps.Intro && (
        <WizardBox className="dashboard-deny-verification">
          <div className="dashboard-verification-header__title">
            <DashboardLayoutHeader />
          </div>
          <div className="dashboard-deny-verification-wrapper">
            <div className="dashboard-deny-verification-wrapper__step">
              <div>
                <DismissCircle48Filled style={{ zoom: 1.5 }} />
              </div>
              <Text as="h1" weight="bold">
                {t({ id: 'Webportal.Verification.Deny.Title' })}
              </Text>
              <Text size={400} weight="medium">
                {t({ id: 'Webportal.Verification.Deny.Description' })}
              </Text>
              <Controller
                control={control}
                name="textarea"
                rules={{ maxLength: 200 }}
                render={(formData) => (
                  <TextAreaWithCounter
                    className="deny-form__textarea"
                    isAlertVisible
                    placeholder={t({ id: 'Webportal.Verification.Deny.Textarea.Placeholder' })}
                    alertText={t({ id: 'Webportal.Verification.Deny.Textarea.Alert' })}
                    fluid
                    characterLimit={200}
                    formData={formData}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <div>{onBack && <ButtonBack onClick={onBack} />}</div>
            <div>
              {onContinue && (
                <Button disabled={messageValue.length > 200} appearance="primary" onClick={onContinue}>
                  {t({ id: 'Common.Refuse' })}
                </Button>
              )}
            </div>
          </div>
        </WizardBox>
      )}
      {currentScreen === DenySteps.Loading && <LoadingScreen />}
      {currentScreen === DenySteps.Error && <ErrorScreen processType="RefuseVerification" />}
      {currentScreen === DenySteps.Denied && (
        <div className="reject-verification-completed">
          <DismissCircle48Filled style={{ zoom: 1.5 }} />
          <Text as="h1" weight="bold">
            {t({ id: 'Webportal.Verification.Deny.Complete.Header' })}
          </Text>
          <Text>{t({ id: 'Webportal.Verification.Deny.Complete.Description' })}</Text>
        </div>
      )}
    </>
  );
};
