import { useCallback, useEffect, useMemo } from 'react';
import { useVerifyTaskContext } from './VerifyTaskContext';
import { ProviderPendingScreen } from 'shared-fe-components/src/common/StatusScreens';

export const DummyProvider = () => {
  const { selectedProvider, setSelectedProvider, overlay, getVerificationStatus } = useVerifyTaskContext();
  const dummyProvider = useMemo(() => selectedProvider?.split(':')?.[1], [selectedProvider]);

  const leaveToProviderSelection = useCallback(() => {
    setSelectedProvider(null);
  }, [setSelectedProvider]);

  // the idea behind this effect is different to other providers
  // triggering an error or timeout screen in context
  // will cause leaving to provider selection – user might try to sign again
  useEffect(() => {
    if (overlay !== null) {
      leaveToProviderSelection();
    }
  }, [overlay, leaveToProviderSelection]);

  // status refetching effect
  useEffect(() => {
    const interval = setInterval(async () => {
      const { data } = await getVerificationStatus({});
      if (data?.status !== 'ProviderPending') {
        leaveToProviderSelection();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [getVerificationStatus, leaveToProviderSelection]);

  if (dummyProvider === 'ProviderPending') {
    return <ProviderPendingScreen processType="Verify" />;
  } else {
    return <div></div>;
  }
};
