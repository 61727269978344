import { useEffect } from 'react';
import { Text } from '@fluentui/react-components';
import { CheckIcon, InperlyLogoSloganColor } from 'shared-fe-components/src/common/Icons';
import { t } from '@lingui/macro';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { ThemeSwitch } from 'components/Common/ThemeSwitch';
import { useLanguage } from 'contexts';
import './OperationCompletedCallback.scss';

interface Props {
  type: 'signing' | 'verification';
  provider: String;
}

export const OperationCompletedCallback = ({ type, provider }: Props) => {
  const { setCanChangeLanguage } = useLanguage();

  useEffect(() => {
    setCanChangeLanguage(true);
  }, [setCanChangeLanguage]);

  return (
    <div className="operation-completed-callback">
      <div className="operation-completed-callback__header">
        <InperlyLogoSloganColor alt="Inperly Logo" className="operation-completed-callback__header-logo" />
        <div className="operation-completed-callback__header-middle" />
        <LanguageDropdown />
        <ThemeSwitch />
      </div>
      <div className="operation-completed-callback__content">
        {type === 'signing' && <SigningSuccess />}
        {type === 'verification' && <VerificationSuccess />}
      </div>
    </div>
  );
};

const SigningSuccess = () => (
  <>
    <CheckIcon className="operation-completed-callback__check-icon" />
    <h1>{t({ id: 'Signing.Callback.Header' })}</h1>
    <Text align="center">{t({ id: 'Signing.Callback.Text' })}</Text>
  </>
);

const VerificationSuccess = () => (
  <>
    <CheckIcon className="operation-completed-callback__check-icon" />
    <h1>{t({ id: 'Verification.Callback.Header' })}</h1>
    <Text align="center">{t({ id: 'Verification.Callback.Text' })}</Text>
  </>
);
