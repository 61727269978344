import { useCallback, useState } from 'react';

const load = (key: any) => {
  const stringValue = localStorage.getItem(key);

  if (!stringValue) {
    return undefined;
  }

  try {
    return JSON.parse(stringValue);
  } catch (error) {
    console.error('Failed to load data', { key, stringValue }, error);
    return undefined;
  }
};

const store = (key: any, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Failed to store data', { key, value }, error);
  }

  return value;
};

const makeCallable = (valueOrCallback: any) => (typeof valueOrCallback === 'function' ? valueOrCallback : () => valueOrCallback);

export const usePersistentStorage = (key: any) => {
  const [value, setValue] = useState(() => load(key));

  const setAndStoreValue = useCallback(
    (newValue: any) => {
      const setter = makeCallable(newValue);

      setValue((currentValue: any) => store(key, setter(currentValue)));
    },
    [key]
  );

  return [value, setAndStoreValue];
};
