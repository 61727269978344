import { useCallback } from "react";

interface CountryFlagProps {
  iso2Code: string,
  className?: string,
  props?: any
}
export const CountryFlag = ({ iso2Code, className: additionalClassName, ...props } : CountryFlagProps) => {
  const iso2CodeLower = iso2Code.toLowerCase();

  const updateDimensions = useCallback((node: any) => {
    if (!node) return;
    const width = parseFloat(window.getComputedStyle(node).width);
    const height = parseFloat(window.getComputedStyle(node).height);
    node.parentElement.setAttribute("width", width);
    node.parentElement.setAttribute("height", height);
    node.parentElement.parentElement.setAttribute("width", width);
    node.parentElement.parentElement.setAttribute("height", height);
    node.parentElement.parentElement.setAttribute("viewBox", `0 0 ${width} ${height}`);
  }, []);

  return (
    <svg className={`flagicons-container ${additionalClassName ?? ''}`} {...props}>
      <foreignObject>
        <div ref={updateDimensions} className={`flagicons-${iso2CodeLower} flagicons-${iso2CodeLower}-dims`} />
      </foreignObject>
    </svg>
  );
};
