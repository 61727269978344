import { cloneElement } from 'react';
import { PdfIcon, XmlIcon } from './utils';
import { FilesIconProps } from './models';

type SupportedIconFileType = 'pdf' | 'xml';

const ICON_COMPONENTS: { [key in SupportedIconFileType]: JSX.Element } = {
  pdf: <PdfIcon />,
  xml: <XmlIcon />,
} as const;

const isSupportedIconFileType = (value: string): value is SupportedIconFileType => {
  return value in ICON_COMPONENTS;
};

export const FilesIcon = ({ fileType, className, ...rest }: FilesIconProps) => {
  return cloneElement(fileType && isSupportedIconFileType(fileType) ? ICON_COMPONENTS[fileType] : <PdfIcon />, {
    className,
    ...rest,
  });
};
