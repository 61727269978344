import { useCallback, useEffect, useMemo, useState } from 'react';
import { useVerifyTaskContext } from '../VerifyTaskContext';
import { EidEasySign, EidEasyIntro } from 'shared-fe-components/src/providers/EidEasy';
import { LoadingScreen } from 'shared-fe-components/src/common/LoadingScreen';

export const EidEasyFlow = () => {
  const [step, setStep] = useState<'intro' | 'loading' | 'verify'>('intro');
  const [signingUrl, setSigningUrl] = useState<string>('');
  const {
    onCancel,
    goToProviderSelection,
    overlay,
    showPrivacyPolicy,
    selectedProvider,
    verify,
    getVerificationStatus,
  } = useVerifyTaskContext();
  const subprovider = useMemo(() => selectedProvider.split(':')?.[1], [selectedProvider]);

  const goToIntro = () => setStep('intro');
  const goToSign = useCallback(async () => {
    setStep('loading');
    const response = await verify({});
    setSigningUrl(response.providerResponse.startPageUrl);
    setStep('verify');
  }, [verify, subprovider]);

  useEffect(() => {
    if (overlay !== null) {
      goToIntro();
    }
  }, [overlay]);

  // status refetching effect
  useEffect(() => {
    if (step === 'verify') {
      const interval = setInterval(async () => {
        await getVerificationStatus({});
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [step, getVerificationStatus]);

  return (
    <>
      {step === 'intro' && (
        <EidEasyIntro
          onBack={goToProviderSelection}
          onCancel={onCancel}
          onContinue={goToSign}
          onShowPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {step === 'loading' && <LoadingScreen />}
      {step === 'verify' && <EidEasySign onBack={goToIntro} onCancel={onCancel} signingUrl={signingUrl} />}
    </>
  );
};
