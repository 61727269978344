import { Button, Text } from '@fluentui/react-components';
import { t } from '@lingui/macro';
import { ActionableSection } from '../../common/ActionableSection';
import { IdDocumentIcon } from '../../common/Icons';
import { WizardBox } from '../../common/WizardBox/WizardBox';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { LinkToPrivacyPolicy } from '../../common/PrivacyPolicy';
import { Header } from 'shared-fe-components/src/common/Header';

export const FaceTecIntro = ({ onBack, onCancel, onContinue, onShowPrivacyPolicy }: any) => {
  return (
    <WizardBox className="facetec-verify-intro">
      <div>
        <Header as="h1">{t({ id: 'Common.GetReady' })}</Header>
      </div>

      <div>
        <div>
          <ActionableSection icon={<IdDocumentIcon />}>
            <Text block weight="semibold">
              {t({ id: 'Providers.FaceTec.Intro.Document' })}
            </Text>
          </ActionableSection>
        </div>
        <div>
          <LinkToPrivacyPolicy onShowPrivacyPolicy={onShowPrivacyPolicy} />
        </div>
      </div>

      <div>
        <div>
          <ButtonBack onClick={onBack} />
        </div>
        <div>
          <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
          <Button appearance="primary" onClick={onContinue}>
            {t({ id: 'Common.Proceed' })}
          </Button>
        </div>
      </div>
    </WizardBox>
  );
};
