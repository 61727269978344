import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Text, Input, Link, Spinner } from '@fluentui/react-components';
import { Checkmark24Regular } from '@fluentui/react-icons';
import { WizardBox } from '../../common/WizardBox';
import { ButtonBack } from '../../common/Buttons';
import { InputFormWithError } from 'shared-fe-components/src/common/WithError';
import * as verificationApi from 'shared-fe-components/src/api/userDataVerification';
import { t } from '@lingui/macro';
import { HTTPError } from 'ky';

enum OtpErrors {
  InvalidCode = 403,
  TooManyRequests = 429,
}

const RESEND_COOLDOWN_MINUTES = 15;

const VERIFY_COOLDOWN_MINUTES = 15;
const VERIFY_ATTEMPTS_LIMIT = 5;

function CodeVerificationForm({ phoneNumber, onBack, onCancel, onPhoneNumberValidated }) {
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const [codeSent, setCodeSent] = useState(false);
  const [resendUnlockMinutesLeft, setResendUnlockMinutesLeft] = useState(0);
  const [isVerificationLocked, setIsVerificationLocked] = useState(false);
  const [hasUsedResend, setHasUsedResend] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [attemptsCounter, setAttemptsCounter] = useState(0);
  const [loadingState, setLoadingState] = useState({ isLoading: true, label: null });
  const setIsLoading = (isLoading, label = null) => {
    setLoadingState({ isLoading, label });
  }

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      'sms-code': ''
    }
  });

  const {
    control,
    trigger,
    getValues,
    formState: { errors }
  } = formMethods;

  const codeInputProps = {
    control,
    fieldName: 'sms-code',
    appearance: 'filled-darker',
    rules: {
      required: { value: true, message: t({ id: 'Otp.Errors.IncorrectCode' }) },
      minLength: { value: 6, message: t({ id: 'Otp.Errors.MinLength', values: { length: 6 } }) },
    }
  };

  useEffect(() => {
    let interval = null;
    if (!canResend && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, canResend]);

  useEffect(() => {
    if (attemptsCounter >= VERIFY_ATTEMPTS_LIMIT) {
      handleTooManyCodeVerifications();
    } else {
      setIsVerificationLocked(false);
    }
  }, [attemptsCounter]);

  useEffect(() => {
    const func = async () => {
      if (phoneNumber) {
        await onSendCode();
      };
    };

    func();
  }, []);

  const onSendCode = async () => {
    setIsLoading(true, t({ id: 'Providers.Native.Spinner.SendingCode' }));

    try {
      const response = await verificationApi.requestPhoneNumberVerification({
        phoneNumber
      });

      await verificationApi.requestPhoneNumberVerificationOtp(response.verificationId);

      setVerificationId(response.verificationId);
      setCodeSent(true);
    }
    catch { }
    finally {
      setIsLoading(false);
    }
  };

  const onResendCode = async () => {
    setIsLoading(true, t({ id: 'Providers.Native.Spinner.SendingCode' }));
    setErrorMessage(null);

    try {
      await verificationApi.requestPhoneNumberVerificationOtp(verificationId);
      setHasUsedResend(true);
      setAttemptsCounter(0);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  const onVerifyCode = async () => {
    const isFormValid = await trigger();
    if (!isFormValid) {
      return;
    }

    setIsLoading(true, t({ id: 'Providers.Native.Spinner.Verification' }));
    setErrorMessage(null);

    const code = getValues('sms-code');

    try {
      const response = await verificationApi.verifyPhoneNumber({
        verificationId,
        verificationCode: code,
      });

      onPhoneNumberValidated(response.phoneNumberToken);
    }
    catch (error) {
      setAttemptsCounter(attemptsCounter + 1);
      const responseStatus = (error as HTTPError).response.status;
      if (responseStatus === OtpErrors.InvalidCode) {
        setErrorMessage(t({ id: 'Otp.Errors.IncorrectCode' }));
      } else if (responseStatus === OtpErrors.TooManyRequests) {
        handleTooManyCodeVerifications();
      } else {
        console.error(error);
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleTooManyCodeVerifications = () => {
    setErrorMessage(t({ id: 'Otp.Errors.IncorrectCodeLocked' }));
    setIsVerificationLocked(true);

    setTimeout(() => {
      setAttemptsCounter(0);
      setErrorMessage(null);
    }, VERIFY_COOLDOWN_MINUTES * 60 * 1000);
  };

  const maskString = (value: string, visibleCharacterCount: number = 4) => {
    if (value.length <= visibleCharacterCount) {
      return value;
    }
    const lastFourChars = value.slice(-visibleCharacterCount);
    const maskedPart = '*'.repeat(value.length - visibleCharacterCount);
    return maskedPart + lastFourChars;
  };

  return (
    <FormProvider {...formMethods}>
      <WizardBox className='native-sign'>
        <div>
          <Text as="h1" weight="bold">
            {t({ id: 'Providers.Native.PhoneNumberVerification.Header' })}
          </Text>
        </div>
        {!loadingState.isLoading ? (
          <div className="native-sign-form">
            <div className="native-sign-form__container">
              <Text className="native-sign-form__label">
                {t({ id: 'Otp.InsertCodeDescription', values: { phoneNumber: phoneNumber } })}
              </Text>
              <div className="native-sign-form__input">
                <InputFormWithError flex disabled={!codeSent} {...codeInputProps} placeholder={t({ id: 'Otp.CodePlaceholder' })} errorMessage={errors?.['sms-code']?.message} />
              </div>
            </div>
            {errorMessage && <Text className='validation-message validation-message__error'>{errorMessage}</Text>}
            <div>
              {codeSent && (<div className="otp__request-again-container">
                {resendUnlockMinutesLeft === 0 && !hasUsedResend && (
                  <>
                    <Text className="otp__label">{t({ id: 'Otp.RequestCodeDescrpition' })}</Text>{' '}
                    <Link inline as="button" onClick={onResendCode}>
                      <Text>{t({ id: 'Otp.Buttons.RequestAgain' })}</Text>
                    </Link>
                  </>
                )}
                {hasUsedResend && (
                  <div className="otp__icon">
                    <Checkmark24Regular /> <Text className="otp__label">{t({ id: 'Otp.NewCodeSent' })}</Text>
                  </div>
                )}
                {resendUnlockMinutesLeft !== 0 && (
                  <>
                    <Text className="otp__label">{t({ id: 'Otp.RequestCodeDescrpition' })}</Text>{' '}
                    <Text className="otp__label">
                      {t({ id: 'Otp.ResendLocked' })}
                      {/* {t({ id: 'Otp.ResendLocked', values: { minutes: formatTimeLeft(resendUnlockMinutesLeft) } })} */}
                    </Text>
                  </>
                )}
              </div>)}
            </div>
          </div>
        ) : (
          <Spinner label={loadingState.label} />
        )}
        <div>
          <div>{onBack && <ButtonBack onClick={onBack} />}</div>
          <div>
            {onCancel && <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>}
            <Button onClick={onVerifyCode} disabled={loadingState.isLoading || isVerificationLocked}>{t({ id: 'Common.Proceed' })}</Button>
          </div>
        </div>
      </WizardBox>
    </FormProvider>
  );
}

export default CodeVerificationForm;
