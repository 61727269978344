import ky from 'ky';

const apiUrl = process.env.REACT_APP_INPERLY_API_ENDPOINT + '/providers/eideasy';
const client = ky.create({
  prefixUrl: apiUrl,
  timeout: 60000,
});

interface AcceptVerificationProps {
  code: string;
  challenge: {
    VerificationId: string;
    OperationId: string;
    ClientId: string;
    SessionId: string;
    BackendId: string;
  };
}

export const acceptVerification = async ({ code, challenge }: AcceptVerificationProps) =>
  await client.post('accept-verification', {
    json: {
      code,
      challenge,
    },
  });
