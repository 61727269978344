export const PdfIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={24} fill="none">
    <path
      fill="#fff"
      d="M1.429 23.429H18a.287.287 0 0 0 .286-.286V6.286h-4.857a.858.858 0 0 1-.857-.857V.57H1.428a.287.287 0 0 0-.286.286v22.286c0 .157.128.286.286.286Z"
    />
    <path fill="#fff" d="M17.882 5.714 13.142.975V5.43c0 .157.13.285.287.285h4.453Z" />
    <path
      fill="#605E5C"
      d="M18.606 5.63 13.226.25a.863.863 0 0 0-.605-.25H1.429A.858.858 0 0 0 .57.857v22.286c0 .472.385.857.858.857H18a.858.858 0 0 0 .857-.857V6.237a.85.85 0 0 0-.251-.606ZM13.143.976l4.739 4.74h-4.453a.286.286 0 0 1-.286-.286V.975ZM18 23.43H1.429a.286.286 0 0 1-.286-.286V.857c0-.157.128-.286.286-.286H12.57V5.43c0 .472.385.857.858.857h4.857v16.857a.287.287 0 0 1-.286.286Z"
      opacity={0.64}
    />
    <path
      fill="#C8C6C4"
      d="M16.286 8.571H3.143a.286.286 0 0 1 0-.571h13.143a.286.286 0 1 1 0 .571Zm0 1.715H3.143a.286.286 0 0 1 0-.572h13.143a.286.286 0 0 1 0 .572Zm0 1.714H3.143a.286.286 0 0 1 0-.571h13.143a.286.286 0 0 1 0 .571Zm0 1.714H3.143a.286.286 0 0 1 0-.571h13.143a.286.286 0 0 1 0 .571Z"
    />
    <path
      stroke="#D65532"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M12 21.714H7.429a1.143 1.143 0 0 1-1.143-1.143v-3.428A1.143 1.143 0 0 1 7.429 16H12a1.143 1.143 0 0 1 1.143 1.143v3.428A1.143 1.143 0 0 1 12 21.714Z"
    />
    <path
      fill="#D65532"
      d="M.571 21.143H4.43A.142.142 0 0 0 4.57 21v-3.714a.143.143 0 0 0-.142-.143H.57a.571.571 0 0 0-.571.571v2.857a.571.571 0 0 0 .571.572Zm18.286-4H15a.143.143 0 0 0-.143.143V21c0 .079.064.143.143.143h3.857a.572.572 0 0 0 .572-.572v-2.857a.571.571 0 0 0-.572-.571Z"
    />
  </svg>
);
