import { Text } from '@fluentui/react-components';
import { WizardBox } from 'shared-fe-components/src/common/WizardBox';
import { t } from '@lingui/macro';
import { AppDownloads, MarketplaceURL } from 'shared-fe-components/src/common/AppDownloads';
import { Button } from 'shared-fe-components/src/common/FluentMigration';
import { ButtonBack } from 'shared-fe-components/src/common/Buttons';
import { IdNowIcon } from 'shared-fe-components/src/common/Icons';
import './IdNowDownload.scss';

export const IdNowDownload = ({ onCancel, onBack }: { onCancel: () => void; onBack: () => void }) => {
  return (
    <WizardBox className="idnow-download">
      <div>
        <Text as="h1" weight="bold">
          {t({ id: 'Common.DownloadTheApp' })}
        </Text>
      </div>
      <div className="idnow-download__logo-container">
        <IdNowIcon className="idnow-logo" />
      </div>
      <div className="idnow-download__downloads-container">
        <AppDownloads {...MarketplaceURL.IdNow} />
      </div>
      <div>
        <ButtonBack onClick={onBack} />
        <Button onClick={onCancel}>{t({ id: 'Common.Cancel' })}</Button>
      </div>
    </WizardBox>
  );
};
