var FaceTecOcrStrings = {
  "groups": [
    {
      "groupFriendlyName": "Twoje dane",
      "fields": [
        {
          "fieldKey": "lastName",
          "fieldFriendlyName": "Nazwisko",
          "uiFieldDescriptionText": "Last Name"
        },
        {
          "fieldKey": "firstName",
          "fieldFriendlyName": "Imię",
          "uiFieldDescriptionText": "First Name"
        },
        {
          "fieldKey": "placeOfBirth",
          "fieldFriendlyName": "Miejsce urodzenia",
          "uiFieldDescriptionText": "Place of Birth"
        },
        {
          "fieldKey": "middleInitial",
          "fieldFriendlyName": "Środkowy inicjał",
          "uiFieldDescriptionText": "Middle Initial"
        },
        {
          "fieldKey": "nationality",
          "fieldFriendlyName": "Narodowość",
          "uiFieldDescriptionText": "Nationality"
        },
        {
          "fieldKey": "givenName",
          "fieldFriendlyName": "Imię",
          "uiFieldDescriptionText": "Given Name"
        },
        {
          "fieldKey": "fullName",
          "fieldFriendlyName": "Imię i nazwisko",
          "uiFieldDescriptionText": "Full Name"
        },
        {
          "fieldKey": "dateOfBirth",
          "fieldFriendlyName": "Data urodzenia",
          "uiFieldDescriptionText": "Date of Birth"
        },
        {
          "fieldKey": "middleName",
          "fieldFriendlyName": "Drugie imię",
          "uiFieldDescriptionText": "Middle Name"
        }
      ],
      "groupKey": "userInfo"
    },
    {
      "groupFriendlyName": "Twój adres",
      "fields": [
        {
          "fieldKey": "zipCode",
          "fieldFriendlyName": "Kod pocztowy",
          "uiFieldDescriptionText": "Zip Code"
        },
        {
          "fieldKey": "country",
          "fieldFriendlyName": "Kraj",
          "uiFieldDescriptionText": "Country"
        },
        {
          "fieldKey": "address3",
          "fieldFriendlyName": "Adres (linia 3)",
          "uiFieldDescriptionText": "Address Line 3"
        },
        {
          "fieldKey": "province",
          "fieldFriendlyName": "Prowincja",
          "uiFieldDescriptionText": "Province"
        },
        {
          "fieldKey": "address2",
          "fieldFriendlyName": "Adres (linia 2)",
          "uiFieldDescriptionText": "Address Line 2"
        },
        {
          "fieldKey": "city",
          "fieldFriendlyName": "Miasto",
          "uiFieldDescriptionText": "City"
        },
        {
          "fieldKey": "address1",
          "fieldFriendlyName": "Adres (linia 1)",
          "uiFieldDescriptionText": "Address Line 1"
        },
        {
          "fieldKey": "county",
          "fieldFriendlyName": "Hrabstwo",
          "uiFieldDescriptionText": "County"
        },
        {
          "fieldKey": "state",
          "fieldFriendlyName": "Stan",
          "uiFieldDescriptionText": "State"
        },
        {
          "fieldKey": "territory",
          "fieldFriendlyName": "Terytorium",
          "uiFieldDescriptionText": "Territory"
        }
      ],
      "groupKey": "addressInfo"
    },
    {
      "groupFriendlyName": "Cechy fizyczne i inne",
      "fields": [
        {
          "fieldKey": "hair",
          "fieldFriendlyName": "Kolor włosów",
          "uiFieldDescriptionText": "Hair"
        },
        {
          "fieldKey": "sex",
          "fieldFriendlyName": "Płeć",
          "uiFieldDescriptionText": "Gender"
        },
        {
          "fieldKey": "weight",
          "fieldFriendlyName": "Waga",
          "uiFieldDescriptionText": "Weight"
        },
        {
          "fieldKey": "eyes",
          "fieldFriendlyName": "Kolor oczu",
          "uiFieldDescriptionText": "Eyes"
        },
        {
          "fieldKey": "height",
          "fieldFriendlyName": "Wzrost",
          "uiFieldDescriptionText": "Height"
        }
      ],
      "groupKey": "secondaryUserInfo"
    },
    {
      "groupFriendlyName": "Dodatkowe informacje",
      "fields": [
        {
          "fieldKey": "customField5",
          "fieldFriendlyName": "Dodatkowe pole 5",
          "uiFieldDescriptionText": "Custom Field 5"
        },
        {
          "fieldKey": "customField1",
          "fieldFriendlyName": "Dodatkowe pole 1",
          "uiFieldDescriptionText": "Custom Field 1"
        },
        {
          "fieldKey": "customField2",
          "fieldFriendlyName": "Dodatkowe pole 2",
          "uiFieldDescriptionText": "Custom Field 2"
        },
        {
          "fieldKey": "customField3",
          "fieldFriendlyName": "Dodatkowe pole 3",
          "uiFieldDescriptionText": "Custom Field 3"
        },
        {
          "fieldKey": "customField4",
          "fieldFriendlyName": "Dodatkowe pole 4",
          "uiFieldDescriptionText": "Custom Field 4"
        }
      ],
      "groupKey": "customFields"
    },
    {
      "groupFriendlyName": "Szczegóły dokumentu",
      "fields": [
        {
          "fieldKey": "restrictions",
          "fieldFriendlyName": "Ograniczenia",
          "uiFieldDescriptionText": "Restrictions"
        },
        {
          "fieldKey": "passportType",
          "fieldFriendlyName": "Rodzaj paszportu",
          "uiFieldDescriptionText": "Passport Type"
        },
        {
          "fieldKey": "idNumber",
          "fieldFriendlyName": "Numer dokumentu",
          "uiFieldDescriptionText": "Photo ID #"
        },
        {
          "fieldKey": "dateOfIssue",
          "fieldFriendlyName": "Data wydania",
          "uiFieldDescriptionText": "Issued Date"
        },
        {
          "fieldKey": "idNumber2",
          "fieldFriendlyName": "Drugi nr dokumentu",
          "uiFieldDescriptionText": "Secondary ID #"
        },
        {
          "fieldKey": "issuingAuthority",
          "fieldFriendlyName": "Organ wydający",
          "uiFieldDescriptionText": "Issuing Authority"
        },
        {
          "fieldKey": "dateOfExpiration",
          "fieldFriendlyName": "Termin ważności",
          "uiFieldDescriptionText": "Expiration Date"
        },
        {
          "fieldKey": "countryCode",
          "fieldFriendlyName": "Kod kraju",
          "uiFieldDescriptionText": "Country Code"
        },
        {
          "fieldKey": "end",
          "fieldFriendlyName": "Koniec",
          "uiFieldDescriptionText": "End"
        },
        {
          "fieldKey": "mrzLine3",
          "fieldFriendlyName": "MRZ 3",
          "uiFieldDescriptionText": "MRZ 3"
        },
        {
          "fieldKey": "barcode",
          "fieldFriendlyName": "Kod kreskowy",
          "uiFieldDescriptionText": "Barcode"
        },
        {
          "fieldKey": "class",
          "fieldFriendlyName": "Klasa",
          "uiFieldDescriptionText": "Class"
        },
        {
          "fieldKey": "mrzLine1",
          "fieldFriendlyName": "MRZ 1",
          "uiFieldDescriptionText": "MRZ 1"
        },
        {
          "fieldKey": "mrzLine2",
          "fieldFriendlyName": "MRZ 2",
          "uiFieldDescriptionText": "MRZ 2"
        }
      ],
      "groupKey": "idInfo"
    }
  ]
};

export default FaceTecOcrStrings;
